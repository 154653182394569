import {Injectable} from '@angular/core';
import {AnomalyParameterType, AnomalySettingsDTO} from 'src/app/shared/models/anomalies.model';
import {
  DynamicSentence,
  DynamicSentenceValues
} from 'src/app/shared/components/dynamic-wordings/models/dynamic-sentence.model';
import {
  DynamicWordingRule,
  DynamicWordingRuleType
} from 'src/app/shared/components/dynamic-wordings/models/rules.model';
import {generateDigitsAndTicksNumbersArray, generateNumbersArray,} from 'src/app/shared/operators/array.operator';
import {ActionSeverity} from 'src/app/shared/models/action-severity.model';
import {TranslateService} from '@ngx-translate/core';
import {QoESeverity} from "../../../../models/severity.model";


@Injectable({
  providedIn: 'root'
})
export class GenerateDynamicSentenceService {

  constructor(private translateService: TranslateService) {
  }

  /**
   * Create DynamicSentence out of AnomalySettingsDTO
   */
  createDynamicSentence(setting: AnomalySettingsDTO): DynamicSentence {
    if (setting) {
      let dynamicSentence: DynamicSentence = {
        sentence: setting.wording,
        words: {
          ["actionSeverity"]: {
            id: 1,
            value: setting.actionSeverity.toString(),
            options: ActionSeverity.toArray(),
            rule: {
              type: DynamicWordingRuleType.Dropdown,
              startChar: "{",
              endChar: "}"
            },
            pattern: null,
            errorMessage: null
          },
          ["anomalyType.desc"]: {
            value: setting.anomalyType.desc,
            options: null,
            rule: {
              type: DynamicWordingRuleType.Bold,
              startChar: "@",
              endChar: "@"
            },
            pattern: null,
            errorMessage: null
          }
        }
      };
      let highestId = this.findHighestID(dynamicSentence.words);
      /**
       * Event though that setting.properties are an array -
       * here they will become Dictionary, because the Dynamic word builder looks on setting.wording which
       * refers to the properties array as dictionary
       */
      setting.properties.forEach((prop, index) => {
        dynamicSentence.words[`properties.${prop.name}.value`] = {
          //Add Add 1 to the index (because ids indexes start with 1)
          // Add highsetId so the properties id will follow the pre-defined properties
          id: index + highestId + 1,
          value: prop.value,
          options: this.getPropOptions(prop.type),
          pattern: this.getPattern(prop.type),
          rule: this.getRule(prop.type),
          errorMessage: this.getErrorMessage(prop.type)
        };

        dynamicSentence.words[`properties.${prop.name}.type`] = {
          value: prop.type,
          options: null,
          pattern: null,
          rule: {
            type: DynamicWordingRuleType.Normal,
            startChar: "{",
            endChar: "}"
          },
          errorMessage: null
        }
      });
      return dynamicSentence;
    }
  }

  /**
   * Utils method that helps to index the DynamicSentence correctly
   */
  findHighestID(words: DynamicSentenceValues<any>): number {
    let highestId: number = 0;
    let values = Object.values(words);
    values.forEach(word => {
      if (word && word.id && word.id > highestId)
        highestId = word.id;
    })
    return highestId;
  }

  /**
   * Return the word Rule
   */
  getRule(type: AnomalyParameterType): DynamicWordingRule {
    switch (type.toLowerCase()) {
      case AnomalyParameterType.DAYS:
        return {
          type: DynamicWordingRuleType.Dropdown,
          startChar: "{",
          endChar: "}"
        };
      case AnomalyParameterType.HOURS:
        return {
          type: DynamicWordingRuleType.Dropdown,
          startChar: "{",
          endChar: "}"
        };
      case AnomalyParameterType.MINUTES:
        return {
          type: DynamicWordingRuleType.Dropdown,
          startChar: "{",
          endChar: "}"
        };
      case AnomalyParameterType.PERCENTS:
        return {
          type: DynamicWordingRuleType.Dropdown,
          startChar: "{",
          endChar: "}"
        };
      case AnomalyParameterType.QUALITY_LEVEL:
        return {
          type: DynamicWordingRuleType.Dropdown,
          startChar: '{',
          endChar: '}'
        };
      case AnomalyParameterType.MILLISECONDS:
        return {
          type: DynamicWordingRuleType.Input,
          startChar: "{",
          endChar: "}"
        };
      case AnomalyParameterType.NUMBER:
        return {
          type: DynamicWordingRuleType.Input,
          startChar: "{",
          endChar: "}"
        };
      case AnomalyParameterType.TIMES:
        return {
          type: DynamicWordingRuleType.Input,
          startChar: "{",
          endChar: "}"
        };
      default:
        break;
    }
  }

  /**
   * If the word will be displayed as Input value - return the pattern
   */
  getPattern(type: AnomalyParameterType): string {
    switch (type.toLowerCase()) {
      case AnomalyParameterType.MILLISECONDS:
        return '[0-9]*';
      case AnomalyParameterType.TIMES:
        return '^([0-9]*\.)?[0-9]+$';
      case AnomalyParameterType.NUMBER:
        return '[0-9]*';
      case AnomalyParameterType.PERCENTS:
        return null;
      case AnomalyParameterType.HOURS:
        return null;
      case AnomalyParameterType.DAYS:
        return null;
      case AnomalyParameterType.MINUTES:
        return null;
      default:
        break;
    }
  }

  getErrorMessage(type: AnomalyParameterType): string {
    switch (type.toLowerCase()) {
      case AnomalyParameterType.MILLISECONDS:
        return this.translateService.instant('data.VALIDATORS.ERRORS.ONLY_NUMBERS');
      case AnomalyParameterType.TIMES:
        return this.translateService.instant('data.VALIDATORS.ERRORS.ONLY_NUMBERS_FLOATS');
      case AnomalyParameterType.NUMBER:
        return this.translateService.instant('data.VALIDATORS.ERRORS.ONLY_NUMBERS');
      case AnomalyParameterType.PERCENTS:
        return null;
      case AnomalyParameterType.HOURS:
        return null;
      case AnomalyParameterType.DAYS:
        return null;
      case AnomalyParameterType.MINUTES:
        return null;
      default:
        break;
    }
  }

  /**
   * Return the options array for dropdown words
   */
  getPropOptions(type: AnomalyParameterType): any[] {
    switch (type.toLowerCase()) {
      case AnomalyParameterType.DAYS:
        return generateNumbersArray(30);
      case AnomalyParameterType.HOURS:
        return generateNumbersArray(24);
      case AnomalyParameterType.MINUTES:
        return generateNumbersArray(60);
      case AnomalyParameterType.PERCENTS:
        return generateDigitsAndTicksNumbersArray(5, 20, 100);
      case AnomalyParameterType.QUALITY_LEVEL:
        return QoESeverity.toArray();
      case AnomalyParameterType.MILLISECONDS:
        return null;
      case AnomalyParameterType.NUMBER:
        return null;
      case AnomalyParameterType.TIMES:
        return null;
      default:
        break;
    }
  }

  /**
   * Find the value field at the AnomalySettingsDTO, and for each value, update the current selected value
   */
  serializeSettingsFromDynamicSentence(setting: AnomalySettingsDTO, dynamicSentence: DynamicSentence): AnomalySettingsDTO {
    let editableWordsObj = Object.entries(dynamicSentence.words).filter(wordObj => wordObj[1].id);
    if (editableWordsObj && editableWordsObj.length > 0) {
      let keys = Object.keys(setting);
      editableWordsObj.forEach(wordObj => {
        let settingKey = keys.find(key => key === wordObj[0]);
        if (settingKey != undefined) {
          setting[settingKey] = wordObj[1].value;
        }
      });

      if (setting.properties && setting.properties.length > 0) {
        setting.properties.forEach(prop => {
          let dynamicWord = editableWordsObj.find(wordObj => wordObj[0].includes(prop.name));
          if (dynamicWord != undefined) {
            prop.value = dynamicWord[1].value;
          }
        })
      }
      return setting;
    }
  }

}
