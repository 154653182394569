import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FromToDates, TimeUnit} from "../../models/time.model";
import {
  DateTimePayload,
  DEFAULT_TIME_BACK_BOUNDS,
  TimePeriodInputPayload,
  TimeRangePayload,
  TimeUnitBounds
} from "./time-range-picker.model";
import {TimeManagerService} from "../../services/time-manager.service";
import {TimeUtils} from "../../global-utils/time-utils";

@Component({
  selector: 'app-time-range-picker',
  templateUrl: './time-range-picker.component.html',
  styleUrls: ['./time-range-picker.component.scss']
})
export class TimeRangePickerComponent implements OnInit {
  @Input() interval: number = 4;
  @Input() timeUnit: TimeUnit = TimeUnit.HOURS;
  @Input() date: Date = new Date();
  @Input() timeUnitOptions: TimeUnit[] = [TimeUnit.HOURS, TimeUnit.DAYS, TimeUnit.MONTHS];
  @Input() timeUnitBounds: TimeUnitBounds = DEFAULT_TIME_BACK_BOUNDS;
  @Input() periodPrefix = 'Last';
  @Output() dateChange = new EventEmitter<Date>();
  @Output() initialized = new EventEmitter<TimeRangePayload>();
  @Output() timeRangeSelected = new EventEmitter<TimeRangePayload>();

  range: FromToDates;
  hour: number;
  minute: number;
  isInitialized: boolean = false;
  refresh: boolean = false;
  emit: boolean = true;
  apply: boolean = false;

  capturedCloseEvent: Event;

  constructor(private timeManager: TimeManagerService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  onTimePeriodChanged(payload: TimePeriodInputPayload) {
    this.apply = true;
    this.setTimePeriod(payload);
    this.dateChange.emit(this.range.end);
    this.timeRangeSelected.emit(this.createPayload());
  }

  onDateTimeSelected(payload: DateTimePayload) {
    if (payload) {
      this.apply = true;
      this.date = payload.date;
      this.hour = payload.hour;
      this.minute = payload.minute;
    }
  }

  onNow() {
    this.apply = true;
    this.date = new Date();
    this.refresh = true;
    this.dateChange.emit(this.date);
    this.timeRangeSelected.emit(this.createPayload());
  }

  onClose() {
    if (this.apply) {
      this.apply = false;
      this.timeRangeSelected.emit(this.createPayload());
    }
  }

  onApply() {
    this.apply = false;
    this.emit = true;
    this.timeRangeSelected.emit(this.createPayload());
    this.dateChange.emit(this.range.end);
  }

  setTimePeriod(payload: TimePeriodInputPayload) {
    if (payload) {
      this.interval = payload.interval;
      this.timeUnit = payload.timeUnit;
      this.range = TimeUtils.getDates(payload.interval, payload.timeUnit, this.date);
    }
  }

  createPayload(): TimeRangePayload {
    return {
      interval: this.interval,
      timeUnit: this.timeUnit,
      date: this.date,
      range: TimeUtils.getDates(this.interval, this.timeUnit, this.date),
      hour: this.hour,
      minute: this.minute
    };
  }

  get display(): string {
    if (this.date) {
      return this.timeManager.dateByFormat(this.date, 'DD/MM/YYYY HH:mm');
    }
  }
}
