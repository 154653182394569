import { Component, ChangeDetectorRef } from '@angular/core';
import { LoggerService, Logger } from 'src/app/shared/services/logger.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalEntitiesService } from 'src/app/shared/services/rest-services/global-entities.service';
import { Period } from 'src/app/shared/services/period.enum';
import { Subscription } from 'rxjs';
import { EntityType } from 'src/app/shared/models/entity-type.enum';
import { take } from 'rxjs/operators';
import { HealthDashboardConfigService } from 'src/app/shared/components/entity-dashboard/health-dashboard-config-service.service';
import { BaseEntityDashboard } from '../../../entity-dashboard/base-entity-dashboard';
import { KpiMultiDataService } from '../../../chartjs-components/services/kpi-stacked-split-data.service';
import { enitityTypeToGraphType, GraphType } from '../../../graph-type-selector/graph-type.enum';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-tenants-health-chart',
  templateUrl: './tenants-health-chart.component.html',
  styleUrls: ['./tenants-health-chart.component.scss']
})
export class TenantsHealthChartComponent extends BaseEntityDashboard {
  title: string;
  totalTitle: string;
  searchTerm: string;
  graphPeriods = Object.keys(Period.getPeriods())
  initialPeriod = Period.WEEK
  Period = Period
  color: ThemePalette = "primary";
  subscr: Subscription[];
  private logger: Logger;


  constructor(protected loggerFactory: LoggerService,
    private cdr: ChangeDetectorRef,
    themeService: ThemeService,
    public healthDashboardConfigService: HealthDashboardConfigService,
    protected translate: TranslateService,
    protected kpiStackedAndSplitDataService: KpiMultiDataService,
    protected globalEntitiesService: GlobalEntitiesService) {
    super(translate, loggerFactory, themeService);
    this.logger = this.loggerFactory.getLogger("HealthChartComponent");
    this.subscr = []
  }

  ngOnInit() {
    this.title = this.translate.instant('data.DASHBOARD.TENANTS_HEALTH');
    this.totalTitle = this.translate.instant('data.DASHBOARD.TOTAL_TENANTS');
    let subscr: Subscription = this.globalEntitiesService.currentEntity$.subscribe((entity) => {
      this.healthDashboardConfigService.getTrendGraphConfig$(enitityTypeToGraphType(EntityType.TENANT), null).pipe(take(1)).subscribe((trendGraph) => {
        this.trendGraphTitle = this.translate.instant(this.titleByGraphType[GraphType.TENANT])
        this.trendLineConfig = trendGraph;
        this.cdr.markForCheck();
      })
    })
    this.subscr.push(subscr)
  }

  /**
 * Set trend the portlet title according to the graph type selected
 * @param graphType the graph type selected
 */
  setGraphTrendTitle(graphType: GraphType) {
    this.trendGraphTitle = this.translate.instant(this.titleByGraphType[graphType])
  }
  ngOnDestroy() {
    this.subscr.forEach((subscr) => subscr.unsubscribe())
  }
}
