import {EntityTreeNode} from './hierarchy.model';
import {AngularFireAction, DatabaseSnapshot} from '@angular/fire/database';
import {ActionsFilterState} from "./actions-filter-state.model";
import {GraphType} from "../components/graph-type-selector/graph-type.enum";


export enum ClientStorageDatasource {
  FIREBASE,
  LOCAL_STORAGE
}

export enum ClientStorageEntities {
  DYNAMIC_DASHBOARD_PREFERENCES = "dynamic-dashboard-preferences",
  USER_PREFERENCES = "users-preferences",
  ORG_PREFERENCES = "organizations-preferences"
}

export class ClientsPreferences<T> {
  preferences: ClientOrgPreferencesData<T>;
}

export class ClientOrgPreferencesData<T> {
  data: T;
  isOwnPreferences: boolean;

}

export class ClientUserPreferencesData {
  userEmail: string;
  preferences?: {};
}

export type HierarchyWithSnapshot = { entityTreeNode: EntityTreeNode, firebaseSnapshot: AngularFireAction<DatabaseSnapshot<ClientsPreferences<any>>> };

export enum GridTypes {
  EVENTS = "Events",
  ALERTS = "Alerts",
  CLIENTS = "Clients",
  MAIN_ACTIONS_LIST = "main-actions-list",
  ORG_HEALTH = "Org health",
  ORG_ACTION = "Org actions",
  TENANT_VENUES_HEALTH = "Tenant venue health",
  TENANT_VENUES_ACTIONS = "Tenant venue actions",
  FABRICS_HEALTH = "Fabrics Health",
  FABRICS_CONFIGURATION_VSERION = "Fabrics Configuraion Version",
  FABRICS_ACTIONS = "Fabrics Actions",
  DEVICES_HEALTH = "Devices Health",
  DEVICES_ACTIONS = "Devices Actions",
  SINGLE_VENUE_ACTIONS = "Single venue actions",
  SINGLE_DEVICE_ACTIONS = "Single device actions",
  SINGLE_FABRIC_ACTIONS = "Single fabric actions",
  CHANGE_LOG = "Change Log",
  MAINTENANCE = "Maintenance",
  ORG_CHILDREN = "Org Children",
  DEVICE_ISSUES = "Device Issues",
  TRAFFIC_ANOMALIES = "Traffic Anomalies",
  LOSS_LATENCY_ANOMALY = "Loss Latency Anomaly",
  VPN_TRAFFIC_ANOMALY = "VPN Traffic Anomaly",
  VPN_ISSUE_ANOMALY = "VPN Issue Anomaly",
  USERS_NUMBER = "Users Number",
  ANOMALY_LEAN_GRID = "Anomaly Lean Grid",
  TRACING_GRID = "Tracing Grid",
  VENUE_OPEN_PORTS = "Venue Open Ports",
  THROUGHPUT_ANOMALY = "Throughput Anomaly",
  CONNECTION_FAILURE_GRID = "Connection Failure grid",
  INCOMPLETE_FABRICS_GRID = "Incomplete Fabrics grid",
  TENANT_VENUES_RULES = "Tenant Venues Rules"

}

export namespace GridTypes {
  export function getGridTypes() {
    return {
      [GridTypes.EVENTS]: "Events",
      [GridTypes.ALERTS]: "Alerts",
      [GridTypes.CLIENTS]: "Clients",
      [GridTypes.ORG_HEALTH]: "Org health",
      [GridTypes.ORG_ACTION]: "Org actions",
      [GridTypes.TENANT_VENUES_HEALTH]: "Tenant venue health",
      [GridTypes.TENANT_VENUES_ACTIONS]: "Tenant venue actions",
      [GridTypes.FABRICS_HEALTH]: "Fabrics Health",
      [GridTypes.FABRICS_CONFIGURATION_VSERION]: "Fabrics Configuration Version",
      [GridTypes.FABRICS_ACTIONS]: "Fabrics Actions",
      [GridTypes.DEVICES_HEALTH]: "Devices Health",
      [GridTypes.DEVICES_ACTIONS]: "Devices Actions",
      [GridTypes.SINGLE_VENUE_ACTIONS]: "Single venue actions",
      [GridTypes.SINGLE_DEVICE_ACTIONS]: "Single device actions",
      [GridTypes.SINGLE_FABRIC_ACTIONS]: "Single fabric actions",
      [GridTypes.MAINTENANCE]: "Maintenance",
      [GridTypes.ORG_CHILDREN]: "Org Children",
      [GridTypes.DEVICE_ISSUES]: "Device Issues",
      [GridTypes.TRAFFIC_ANOMALIES]: "Traffic Anomalies",
      [GridTypes.LOSS_LATENCY_ANOMALY]: "Loss Latency Anomaly",
      [GridTypes.VPN_TRAFFIC_ANOMALY]: "VPN Traffic Anomaly",
      [GridTypes.VPN_ISSUE_ANOMALY]: "VPN Issue Anomaly",
      [GridTypes.TRACING_GRID]: "Tracing Grid",
      [GridTypes.USERS_NUMBER]: "Users Anomaly Grid",
      [GridTypes.VENUE_OPEN_PORTS]: "Venue Open Ports Grid",
      [GridTypes.TENANT_VENUES_RULES]: "Tenant Venues Rules",
    }
  }
}

export class GridUserPreferences {
  gridType: GridTypes;
  preferences?: {
    actionFilter?: ActionsFilterState;
    searchTerm?: string;
    columnsState?: any[];
    colSorting?: {
      colId: string;
      sort: string;
    }[];
    gridStartRow?: number;
    graphTypeSelector?: GraphType;
  }
}

export enum UserPreferences {
  isAnomalyLeanMode = 'isAnomalyLeanMode'
}


export enum GridUserPreferencesOptions {
  // gridType = "gridType",
  actionFilter = "actionFilter",
  searchTerm = "searchTerm",
  columnsState = "columnsState",
  colSorting = "colSorting",
  gridStartRow = "gridStartRow",
  graphTypeSelector = "graphTypeSelector"
}
