import {Injectable} from '@angular/core';
import {AngularFireDatabase, AngularFireObject} from '@angular/fire/database';
import {IAppState} from 'src/app/store/state/app.state';
import {Store} from '@ngrx/store';
import {OrgBranding, FirebaseOrgBranding, EntityWithBranding} from '../../../models/branding.model';
import {ClientStorageEntities} from '../../../models/client-storage.model';
import {LoggerService} from '../../logger.service';

import {Hierarchy, OrganizationHierarchyTree} from '../../../models/hierarchy.model';
import {HierarchyService} from '../../rest-services/hierarchy.service';

import {UpdateEntitySiblingsPreferencesService} from './update-entity-siblings-preferences.service';
import {SetEntityPreferencesByParentService} from './set-entity-preferences-by-parent.service';

import {BaseFirebaseByOrganizations} from './base-firebase-by-organization';
import {WidgetTemplatesService} from '../../../components/dynamic-dashboard/services/widget-templates.service';
import {LoadClientStorageService} from "../load-client-storage.service";

@Injectable({
  providedIn: 'root'
})
export class FirebaseDynamicDashboardService extends BaseFirebaseByOrganizations {
  /**
   * Angular firebase database instance
   */
  firebaseDB: AngularFireDatabase;
  /**
   * Current Org entity as stored on Ngrex
   */
  currentOrgHierarchy: Hierarchy;
  /**
   * Curren Firebase Org Object
   */
  currentOrg: AngularFireObject<FirebaseOrgBranding>;
  currentOrgValue: OrgBranding;

  firebaseLocationString: string;

  hierarchyWithBranding: EntityWithBranding[] = [];
  entireOrgTree: OrganizationHierarchyTree;

  constructor(db: AngularFireDatabase,
              protected store: Store<IAppState>,
              protected hierarchyService: HierarchyService,
              protected updateEntitySiblingsPreferencesService: UpdateEntitySiblingsPreferencesService,
              protected setEntityPreferencesByParentService: SetEntityPreferencesByParentService,
              protected widgetTemplatesService: WidgetTemplatesService,
              protected loggerFactory: LoggerService,
              protected loadClientStorageService: LoadClientStorageService
  ) {
    super(store, hierarchyService, updateEntitySiblingsPreferencesService, setEntityPreferencesByParentService, loggerFactory, loadClientStorageService)
    this.setDefaultServiceValues(ClientStorageEntities.DYNAMIC_DASHBOARD_PREFERENCES, "FirebaseDynamicDashboardService", {
      preferences: {
        isOwnPreferences: false,
        data: {}
      }
    }, db);
  }

  applyDefaultAndSelectedPreferences(preferences: any) {
    this.loadClientStorageService.storeDynamicDashboardPreferences(preferences || false);
  }
}
