import {Component, OnInit, Inject} from '@angular/core';
import {Logger} from 'loglevel';
import {IDatasource, IGetRowsParams} from 'ag-grid-community';
import {of, Subscription} from 'rxjs';
import {Router, ActivatedRoute} from '@angular/router';
import {LoggerService} from 'src/app/shared/services/logger.service';
import {StoreUserPreferencesService} from 'src/app/shared/services/client-storage-services/internal-storage-services/store-user-preferences.service';
import {StoreWidgetPreferencesService} from 'src/app/shared/services/client-storage-services/internal-storage-services/store-widget-preferences.service';
import {TranslateService} from '@ngx-translate/core';
import {GlobalEntitiesService} from 'src/app/shared/services/rest-services/global-entities.service';
import {SeverityCellRendererComponent} from 'src/app/shared/ag-grid/cell-renderers/severity-cell-renderer/severity-cell-renderer.component';
import {catchError, take} from 'rxjs/operators';
import {dashboardRowClassRules} from 'src/app/shared/ag-grid/cell-renderers/dashboard-cell-renderers';
import {Anomaly, DeviceIssuesAnomaly} from 'src/app/shared/models/anomalies.model';
import {AnalyzeCellRendererComponent} from 'src/app/shared/ag-grid/cell-renderers/analyze-cell-renderer/analyze-cell-renderer.component';
import {InsightsService} from 'src/app/shared/services/rest-services/insights.service';
import {TimeBarRendererComponent} from 'src/app/shared/ag-grid/cell-renderers/time-bar-renderer/time-bar-renderer.component';
import {GridSortFilter} from 'src/app/shared/models/sort-filter/grid-sort-filter.model';
import {EntityGridActionsMenuService} from 'src/app/shared/ag-grid/cell-renderers/actions-menu-renderer/entity-grid-actions-menu.service';
import {DashboardService} from 'src/app/shared/services/rest-services/dashboard.service';
import {DeviceIssuesGridService} from './device-issues-grid.service';
import {TooltipAsCellRendererComponent} from 'src/app/shared/ag-grid/cell-renderers/tooltip-as-cell-renderer/tooltip-as-cell-renderer.component';
import {LegacySeverity} from 'src/app/shared/models/severity.model';
import {ActionStatus, EntityAction} from 'src/app/shared/models/actions.model';
import {IconWithTextRendererComponent} from 'src/app/shared/ag-grid/cell-renderers/icon-with-text-renderer/icon-with-text-renderer.component';
import {BaseAnomalyGrid} from '../../models/IBaseAnomalyGrid';
import {TimeManagerService} from 'src/app/shared/services/time-manager.service';
import {AnomalyTrendService} from '../../services/anomaly-trend.service';
import {AnomaliesStoreService} from '../../../anomalies-display/services/anomalies-store.service';
import {StoreDashboardService} from "../../../../../services/store-dashboard.service";
import {AnalyzePopOverComponent} from "../../../../../../../ag-grid/cell-renderers/analyze-pop-over/analyze-pop-over.component";
import {ANOMALY_GRID_SIZES} from "../../../../../../../global-utils/sizes";
import {GridTypes} from "../../../../../../../models/client-storage.model";
import {EntityType} from "../../../../../../../models/entity-type.enum";

@Component({
  selector: 'app-device-issues-grid',
  templateUrl: './device-issues-grid.component.html',
  styleUrls: ['./device-issues-grid.component.scss']
})
export class DeviceIssuesGridComponent extends BaseAnomalyGrid implements OnInit {
  readonly logger: Logger;
  // timeBack: Date = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
  deviceIssuesData: DeviceIssuesAnomaly[];
  dataSource: IDatasource = {
    //rowCount: null,
    getRows: (params: IGetRowsParams) => {
      // TODO;
      // Use startRow and endRow for sending pagination to Backend
      // params.startRow : Start Page
      // params.endRow : End Page
      // use this.searchText to filter the data source
      const anomaliesByCategory$ = this.insightsService.getAnomaliesListByCategory(this.getEntityForGridApi(), this.data ? this.data.category : null, new GridSortFilter(params, '', this.numberOfRawsInPage), this.data ? this.data.dates : null);
      if (anomaliesByCategory$ !== undefined) {
        anomaliesByCategory$.pipe(
          take(1),
          catchError(error => {
            this.isLoading = false;
            return of(null);
          })
        ).subscribe(pageData => {
          this.isLoading = false;
          if (pageData) {
            this.deviceIssuesData = pageData.data as DeviceIssuesAnomaly[];
            // this.updateTimeHeaderName();
          }
          this.loadData(this.logger, params, pageData);
        }, () => params.failCallback());
      }
    }
  }
  tenantIdSubscr: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(ANOMALY_GRID_SIZES) readonly anomaliesSizes: string,
    protected insightsService: InsightsService,
    private loggerFactory: LoggerService,
    protected dashboardService: DashboardService,
    private deviceIssuesGridService: DeviceIssuesGridService,
    protected storeUserPreferencesService: StoreUserPreferencesService,
    protected storeWidgetPreferencesService: StoreWidgetPreferencesService,
    protected anomaliesStoreService: AnomaliesStoreService,
    protected anomalyTrendService: AnomalyTrendService,
    protected dateConvertor: TimeManagerService,
    protected storeDashboardService: StoreDashboardService,
    private translate: TranslateService,
    entityGridActionsMenuService: EntityGridActionsMenuService,
    private globalEntitiesService: GlobalEntitiesService) {
    super(GridTypes.DEVICE_ISSUES, anomalyTrendService, anomaliesSizes, insightsService, storeUserPreferencesService, storeWidgetPreferencesService, entityGridActionsMenuService, dashboardService, dateConvertor, anomaliesStoreService, storeDashboardService);
    this.logger = this.loggerFactory.getLogger("DeviceIssuesGridComponent");
    // Query server on search changed
    this.searchQuery$.subscribe(searchText => {
      this.logger.debug(`setting searchText  to ${searchText}`)
      this.searchText = searchText;
      // Call your function which calls API or do anything you would like do after a lag of 1 sec
      this.gridApi.setDatasource(this.dataSource)
    });
    this.tenantIdSubscr = this.globalEntitiesService.tenantId$.subscribe((entity) => {
      this.logger.debug("DeviceIssuesGridComponent current entity changed")
      if (this.gridApi) {
        this.gridApi.setDatasource(this.dataSource)
      }
    })

    this.context = {componentParent: this, enableCellHover: true};
    this.frameworkComponents = {
      severityCellRenderer: SeverityCellRendererComponent,
      timeBarRenderer: TimeBarRendererComponent,
      analyzeCellRenderer: AnalyzeCellRendererComponent,
      tooltipAsCellRenderer: TooltipAsCellRendererComponent,
      iconWithTextRenderer: IconWithTextRendererComponent,
      analyzePopOverComponent: AnalyzePopOverComponent
    };
  }

  /**
   * Click on tenant will take us to the tenant page
   */
  gotToTenant(event: any) {
    if (event && event.type === 'rowClicked') {
      this.globalEntitiesService.tenantId$.pipe(take(1)).subscribe(
        (tenantId) => {
          if (event && event.data)
            this.router.navigate(['/tenant', tenantId, 'venue', event.data.id], {relativeTo: this.activatedRoute}).catch();
        }
      )
    }
  }

  ngOnInit() {
    this.initateGridColumns();

  }

  initateGridColumns() {
    this.columnDefs = this.generateColumns();
    this.rowClassRules = dashboardRowClassRules;
    this.rowData = [];
    this.logger.debug("in ngOnInit of DeviceIssuesGridComponent");
  }

  generateColumns() {
    let columnDefs = [
      {
        /**
         * Severity column
         * The severity column header is needed to show the sort arrow
         * the text is hidden by transparent color.
         */
        headerName: '!',
        //headerClass: 'ag-grid-hidden-header',
        width: 20,
        // Show severity icon using renderer
        cellRenderer: "severityCellRenderer",
        // Keep the field name as it is usefull in sorting i.e. it sets
        // in the datasource -> sortModel object the colId property which
        // usefull to be passed to the server side.
        field: "severity",
        sortable: true,
        sort: "asc",
        cellRendererParams: {
          severityStrategy: (rowData: DeviceIssuesAnomaly) => {
            if (!rowData) {
              return LegacySeverity.Minor;
            }
            return LegacySeverity.getSeverityByNumber(LegacySeverity.fromString(rowData.action.severity));
          }
        },
      },
      {
        headerName: this.translate.instant('data.COMMON.TENANT'),
        field: 'tenantName',
        width: 30,
        sortable: true,
        cellRenderer: "tooltipAsCellRenderer",
      },
      {
        headerName: this.translate.instant('data.COMMON.VENUE'),
        field: 'venueName',
        width: 29,
        sortable: true,
        cellRenderer: "tooltipAsCellRenderer",
      },
      {
        headerName: this.translate.instant('data.COMMON.DEVICE'),
        field: 'action.name',
        width: 29,
        sortable: true,
        cellRenderer: "tooltipAsCellRenderer",
      },
      {
        headerName: this.translate.instant('data.GRIDS_HEADERS.FAILURE'),
        field: 'action.description',
        width: 105,
        sortable: true,
        cellClass: 'override-ag-grid-padding',
        cellRenderer: "analyzePopOverComponent"
      },
      {
        headerName: 'Device Status',
        field: 'action.suggestedOperation',
        width: 65,
        sortable: false,
        cellRenderer: "timeBarRenderer",
        valueGetter: params => {
          if (params && params.data)
            return {
              timeSpans: this.deviceIssuesGridService.getTimeSpans(params.data.deviceStatusHistory),
              markerData: this.deviceIssuesGridService.getMarkerData((params.data.action as EntityAction))
            }
        }
      },
      {
        headerName: this.translate.instant('data.ANOMALIES.STATUS'),
        field: 'action.status',
        width: 30,
        sortable: true,
        valueGetter: params => {
          if (params && params.data && params.data.action.status) {
            return params.data.action.status === ActionStatus.Complete ?
              this.translate.instant('data.DASHBOARD.RESOLVED') : params.data.action.status;
          }
        }
      },
      {
        headerName: '',
        field: 'action',
        width: 15,
        sortable: false,
        cellRenderer: "analyzeCellRenderer",
        cellRendererParams: {
          entityType: EntityType.TENANT,
          entityId: (row: Anomaly) => row.action.tenantId,
          actionId: (row: Anomaly) => row.action.id
        }
      }
    ];
    return this.removeColumnsByEntity(columnDefs);
  }

  get gridSizes() {
    return this.data && this.data.sizes ? this.data.sizes : this.anomaliesSizes;
  }


  ngOnDestroy(): void {
    this.tenantIdSubscr.unsubscribe()
    this.cancelSubscription();
  }
}
