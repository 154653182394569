import {AuthMethod, DashboardEnvironment, EnvironmentModel, EnvironmentType} from './environment.model';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment : EnvironmentModel = {
  environmentType: EnvironmentType.STAGE,
  dashboardEnvironment: DashboardEnvironment.Remote,
  disabledAuth: false,
  disableFirebase: false,
  authMethod: AuthMethod.AUTH_0,
  domain: 'netop-stage.us.auth0.com',
  client_id: 'GnEDYD9fvpYasLY2BSVAJizLXELJA7mX',
  redirect_uri: 'https://staging.opscenter.cloud/auth/auth0callback', // `${window.location.origin}/auth/auth0callback`
  production: false,
  disableInMemory: true,
  partialInMemory: false,
  disableSLAs: false,
  apiBaseUrl: 'https://api.stage.opscenter.cloud/1.0',
  searchUrl: 'https://api.stage.opscenter.cloud/1.0/search',
  versionCheckURL: "version.json",
  fakeData: true,
  firebase: {
    apiKey: "AIzaSyBgs65zrgXMQpBT2Ntl5I-1m1QiljRdE7A",
    authDomain: "netop-engineering.firebaseapp.com",
    databaseURL: "https://netop-engineering.firebaseio.com",
    projectId: "netop-engineering",
    region: "us-central1",
    storageBucket: "netop-engineering.appspot.com",
    messagingSenderId: "1085974882677",
    measurementId: "G-XL0CPSR79X",
    appId: "1:1085974882677:web:e9ec502bacee0ad5",
    localInvocation: false
  },
  enableHotjar: true,
  hotjar: {
    id: '3359565'
  },
  useServerless: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
