import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FabricKpiData, FabricStatus} from "../../../../../shared/models/fabrics.model";
import {animate, sequence, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-fabric-status-display',
  templateUrl: './fabric-status-display.component.html',
  styleUrls: ['./fabric-status-display.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':leave',
          sequence([
            // style({opacity: 1}),
            // style({height: 40}),
            animate('1ms ease-in',
              style({opacity: 0})),
            animate('250ms ease-in',
              style({height: 0}))
          ])
        )
      ])]
})
export class FabricStatusDisplayComponent implements OnInit {
  @Input() selectedFabricData: FabricKpiData;
  iconsName: string[] = ['fix'];
  public isCollapse: boolean = false;

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  changeCollapseMode() {
    this.isCollapse = !this.isCollapse;
    this.cdr.detectChanges();
  }

  get colorClass() {
    if (this.selectedFabricData && this.selectedFabricData.fabric && this.selectedFabricData.fabric.fabricStatus.status) {
      return `venue-fabric-${this.selectedFabricData.fabric.fabricStatus.status.toLowerCase()}`;
    }
    return null;
  }

  get fabricStatusTitle() {
    if (this.selectedFabricData && this.selectedFabricData.fabric && this.selectedFabricData.fabric.fabricStatus.status) {
      return `${this.selectedFabricData.fabric.name}:  ${this.selectedFabricData.fabric.fabricStatus.status}`;
    }
    return null;
  }

  get fabricStatusReason() {
    if (this.selectedFabricData && this.selectedFabricData.fabric && this.selectedFabricData.fabric.fabricStatus.reason) {
      return ` ${this.selectedFabricData.fabric.fabricStatus.reason}`;
    }
    return null;
  }

  get fabricStatusSolution() {
    if (this.selectedFabricData && this.selectedFabricData.fabric && this.selectedFabricData.fabric.fabricStatus.solution) {
      return `${this.selectedFabricData.fabric.fabricStatus.solution}`;
    }
    return null;
  }

  get isFabricComplete() {
    if (this.selectedFabricData && this.selectedFabricData.fabric && this.selectedFabricData.fabric.fabricStatus.status) {
      return this.selectedFabricData.fabric.fabricStatus.status == FabricStatus.COMPLETE;
    }
  }
}
