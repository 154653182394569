import {Component, Inject, OnInit} from '@angular/core';
import {BaseAnomalyGrid} from "../../models/IBaseAnomalyGrid";
import {Logger} from "loglevel";
import {IDatasource, IGetRowsParams} from "ag-grid-community";
import {GridSortFilter} from "../../../../../../../models/sort-filter/grid-sort-filter.model";
import {catchError, take} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import {Anomaly, DeviceIssuesAnomaly, TrendAnomaly} from "../../../../../../../models/anomalies.model";
import {ActivatedRoute, Router} from "@angular/router";
import {ANOMALY_GRID_SIZES} from "../../../../../../../global-utils/sizes";
import {InsightsService} from "../../../../../../../services/rest-services/insights.service";
import {LoggerService} from "../../../../../../../services/logger.service";
import {DashboardService} from "../../../../../../../services/rest-services/dashboard.service";
import {AnomalyTrendService} from "../../services/anomaly-trend.service";
import {
  StoreUserPreferencesService
} from "../../../../../../../services/client-storage-services/internal-storage-services/store-user-preferences.service";
import {
  StoreWidgetPreferencesService
} from "../../../../../../../services/client-storage-services/internal-storage-services/store-widget-preferences.service";
import {TimeManagerService} from "../../../../../../../services/time-manager.service";
import {AnomaliesStoreService} from "../../../anomalies-display/services/anomalies-store.service";
import {StoreDashboardService} from "../../../../../services/store-dashboard.service";
import {TranslateService} from "@ngx-translate/core";
import {
  EntityGridActionsMenuService
} from "../../../../../../../ag-grid/cell-renderers/actions-menu-renderer/entity-grid-actions-menu.service";
import {GlobalEntitiesService} from "../../../../../../../services/rest-services/global-entities.service";
import {GridTypes} from "../../../../../../../models/client-storage.model";
import {
  SeverityCellRendererComponent
} from "../../../../../../../ag-grid/cell-renderers/severity-cell-renderer/severity-cell-renderer.component";
import {
  SparklineRendererComponent
} from "../../../../../../../ag-grid/cell-renderers/sparkline-renderer/sparkline-renderer.component";
import {
  AnalyzeCellRendererComponent
} from "../../../../../../../ag-grid/cell-renderers/analyze-cell-renderer/analyze-cell-renderer.component";
import {
  TooltipAsCellRendererComponent
} from "../../../../../../../ag-grid/cell-renderers/tooltip-as-cell-renderer/tooltip-as-cell-renderer.component";
import {
  AnalyzePopOverComponent
} from "../../../../../../../ag-grid/cell-renderers/analyze-pop-over/analyze-pop-over.component";
import {dashboardRowClassRules} from "../../../../../../../ag-grid/cell-renderers/dashboard-cell-renderers";
import {LegacySeverity} from "../../../../../../../models/severity.model";
import {ActionStatus} from "../../../../../../../models/actions.model";
import {EntityType} from "../../../../../../../models/entity-type.enum";

@Component({
  selector: 'app-vpn-mos-grid',
  templateUrl: './vpn-mos-grid.component.html',
  styleUrls: ['./vpn-mos-grid.component.scss']
})
export class VpnMosGridComponent extends BaseAnomalyGrid implements OnInit {
  readonly logger: Logger;
  dataSource: IDatasource = {
    getRows: (params: IGetRowsParams) => {
      const anomaliesByCategory$ = this.insightsService.getAnomaliesListByCategory(this.getEntityForGridApi(), this.data ? this.data.category : null, new GridSortFilter(params, '', this.numberOfRawsInPage), this.data ? this.data.dates : null);
      if (anomaliesByCategory$ !== undefined) {
        anomaliesByCategory$.pipe(
          take(1),
          catchError(error => {
            this.isLoading = false;
            return of(null);
          })
        ).subscribe(pageData => {
          this.isLoading = false;
          if (pageData) {
            this.trendAnomaly = pageData.data as TrendAnomaly[];
          }
          this.loadData(this.logger, params, pageData);
        }, () => params.failCallback());
      }
    }
  };
  tenantIdSubscr: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(ANOMALY_GRID_SIZES) readonly anomaliesSizes: string,
    protected insightsService: InsightsService,
    private loggerFactory: LoggerService,
    protected dashboardService: DashboardService,
    protected anomalyTrendService: AnomalyTrendService,
    protected storeUserPreferencesService: StoreUserPreferencesService,
    protected storeWidgetPreferencesService: StoreWidgetPreferencesService,
    protected dateConversionService: TimeManagerService,
    protected anomaliesStoreService: AnomaliesStoreService,
    protected storeDashboardService: StoreDashboardService,
    private translate: TranslateService,
    entityGridActionsMenuService: EntityGridActionsMenuService,
    private globalEntitiesService: GlobalEntitiesService) {
    super(GridTypes.VPN_TRAFFIC_ANOMALY, anomalyTrendService, anomaliesSizes, insightsService, storeUserPreferencesService, storeWidgetPreferencesService, entityGridActionsMenuService, dashboardService, dateConversionService, anomaliesStoreService, storeDashboardService);
    this.logger = this.loggerFactory.getLogger("VpnMosGridComponent");
    this.searchQuery$.subscribe(searchText => {
      this.searchText = searchText;
      this.gridApi.setDatasource(this.dataSource)
    });
    this.tenantIdSubscr = this.globalEntitiesService.tenantId$.subscribe((entity) => {
      if (this.gridApi) {
        this.gridApi.setDatasource(this.dataSource)
      }
    })

    this.context = {componentParent: this, enableCellHover: true};
    this.frameworkComponents = {
      severityCellRenderer: SeverityCellRendererComponent,
      sparklineRenderer: SparklineRendererComponent,
      analyzeCellRenderer: AnalyzeCellRendererComponent,
      tooltipAsCellRenderer: TooltipAsCellRendererComponent,
      analyzePopOverComponent: AnalyzePopOverComponent
    };
  }

  gotToTenant(event: any) {
    if (event.type === 'rowClicked') {
      this.globalEntitiesService.tenantId$.pipe(take(1)).subscribe(
        (tenantId) => {
          if (event)
            this.router.navigate(['/tenant', tenantId, 'venue', event.data.id], {relativeTo: this.activatedRoute}).catch();
        }
      )
    }
  }

  initateGridColumns() {
    this.columnDefs = this.generateColumns();
    this.rowClassRules = dashboardRowClassRules;
    this.rowData = [];
  }

  generateColumns() {
    let columnDefs = [
      {
        headerName: '!',
        width: 20,
        cellRenderer: "severityCellRenderer",
        field: "severity",
        sortable: true,
        sort: "asc",
        cellRendererParams: {
          severityStrategy: (rowData: DeviceIssuesAnomaly) => {
            if (!rowData) {
              return LegacySeverity.Minor;
            }
            return LegacySeverity.getSeverityByNumber(LegacySeverity.fromString(rowData.action.severity));
          }
        },
      },
      {
        headerName: this.translate.instant('data.COMMON.TENANT'),
        field: 'tenantName',
        width: 30,
        sortable: true,
        cellRenderer: "tooltipAsCellRenderer",
      },
      {
        headerName: this.translate.instant('data.COMMON.VENUE'),
        field: 'venueName',
        width: 29,
        sortable: true,
        cellRenderer: "tooltipAsCellRenderer",
      },
      {
        headerName: this.translate.instant('data.COMMON.DEVICE'),
        field: 'action.name',
        width: 29,
        sortable: true,
        cellRenderer: "tooltipAsCellRenderer",
      },
      {
        headerName: this.translate.instant('data.GRIDS_HEADERS.FAILURE'),
        field: 'action.description',
        width: 90,
        sortable: true,
        valueGetter: params => {
          if (params && params.data && params.data.action) {
            return `On ${this.dateConvertor.dateByFormat(params.data.action.updatedAt, 'DD-MMM-YYYY HH:mm')}: ${params.data.action.description}`;
          }
        },
        cellClass: 'override-ag-grid-padding',
        cellRenderer: "analyzePopOverComponent"
      },
      {
        headerName: 'VPN Mos',
        field: 'action.suggestedOperation',
        width: 70,
        sortable: false,
        cellRenderer: "sparklineRenderer",
        cellClass: ['disable-hover'],
      }, {
        headerName: this.translate.instant('data.ANOMALIES.STATUS'),
        field: 'action.status',
        width: 30,
        sortable: true,
        valueGetter: params => {
          if (params && params.data && params.data.action.status) {
            return params.data.action.status === ActionStatus.Complete ?
              this.translate.instant('data.DASHBOARD.RESOLVED') : params.data.action.status;
          }
        }
      },
      {
        headerName: '',
        field: 'action',
        width: 15,
        sortable: false,
        cellRenderer: "analyzeCellRenderer",
        cellRendererParams: {
          entityType: EntityType.TENANT,
          entityId: (row: Anomaly) => row.action.tenantId,
          actionId: (row: Anomaly) => row.action.id
        }
      }
    ];
    return this.removeColumnsByEntity(columnDefs);
  }

  get gridSizes() {
    return this.data && this.data.sizes ? this.data.sizes : this.anomaliesSizes;
  }
}
