import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {SelectionTreeComponent} from "./selection-tree.component";
import {MatTreeModule} from "@angular/material/tree";
import {SharedDirectivesModule} from "../../shared-directives.module";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {SharedPipesModule} from "../../shared-pipes.module";
import {MatButtonModule} from "@angular/material/button";
import {MatListModule} from "@angular/material/list";
import {CommonModule} from "@angular/common";

@NgModule({
  declarations: [SelectionTreeComponent],
  imports: [
    CommonModule,
    MatTreeModule,
    MatCheckboxModule,
    MatButtonModule,
    MatListModule,
    SharedDirectivesModule,
    SharedPipesModule
  ],
  exports: [SelectionTreeComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SelectionTreeModule {
}
