import { Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-cell-renderer-cell-with-details',
  templateUrl: './cell-renderer-cell-with-details.component.html',
  styleUrls: ['./cell-renderer-cell-with-details.component.scss']
})
export class CellRendererCellWithDetailsComponent implements INoRowsOverlayAngularComp {
  title: string;
  details: string;
  agInit({value}): void {
    this.title = value.title;
    this.details = value.details;
  }

}
