import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppVersionService } from './rest-services/app-version.service';
import { take } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { AppVersion } from '../models/app-version.model';
import { LoggerService, Logger } from './logger.service';
/**
 * Version check service 
 * from https://blog.nodeswat.com/automagic-reload-for-clients-after-deploy-with-angular-4-8440c9fdd96c
 */
@Injectable({
    providedIn: 'root'
})
export class VersionCheckService {
    // this will be replaced by actual hash post-build.js
    readonly EMPTY_HASH: string = "0";
    private currentHash: string = this.EMPTY_HASH;
    versionHash$: Observable<string>
    private versionHashSubject: Subject<string> = new Subject<string>()
    private logger: Logger;

    constructor(private appVersionService: AppVersionService,
        private loggerFactory: LoggerService,
    ) {
        this.logger = this.loggerFactory.getLogger("VersionCheckService");
        this.logger.debug("Created VersionCheckService")
        this.versionHash$ = this.versionHashSubject.asObservable()
    }

    /**
     * Checks in every set frequency the version of frontend application
     * @param url
     * @param {number} frequency - in milliseconds, defaults to 30 minutes
     */
    public initVersionCheck(frequency = 1000 * 60 * 30) {
        this.logger.debug("initVersionCheck called")
        if (this.currentHash) {
            this.checkVersion()
        }
        setInterval(() => {
            this.checkVersion();
        }, frequency);
    }

    /**
     * Will do the call and check if the hash has changed or not
     * @param url
     */
    private checkVersion() {
        this.logger.debug("checkVersion called")
        this.appVersionService.getAppVersion().pipe(take(1)).subscribe(
            (appVersion: AppVersion) => {
                this.logger.debug("got app version response %o", appVersion)

                const hash = appVersion.hash;
                const hashChanged = this.hasHashChanged(hash);
                // store the new hash so we wouldn't trigger versionChange again
                // only necessary in case you did not force refresh
                this.logger.debug(`Got new hash ${hash}, old one ${this.currentHash}`)
                this.currentHash = hash;
                // If new version, do something
                if (hashChanged) {
                    this.logger.debug(`New hash found reloading`)
                    this.versionHashSubject.next(hash)
                }
            },
            (err) => {
                this.logger.error(err, 'Could not app get version');
            }
        )
    }

    /**
     * Checks if hash has changed.
     * This file has the JS hash, if it is a different one than in the version.json
     * we are dealing with version change
     * @param currentHash
     * @param newHash
     * @returns {boolean}
     */
    private hasHashChanged(newHash) {
        if (this.currentHash == this.EMPTY_HASH) {
            return false;
        }

        return this.currentHash.toString() != newHash.toString();
    }
}