import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import createAuth0Client, {Auth0ClientOptions} from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {DisplayErrorService} from './display-error.service';
import {EnvironmentType} from "../../../environments/environment.model";
import {Auth0Profile} from "../../auth/IBaseAuthService";

@Injectable({
  providedIn: 'root'
})
export class Auth0Service {
  private profileSubject = new BehaviorSubject<any>(null);

  private readonly auth0Client: Promise<Auth0Client>;

  constructor(private readonly router: Router,
              private displayErrorService: DisplayErrorService,
  ) {
    const config: Auth0ClientOptions = {
      domain: environment.domain,
      client_id: environment.client_id,
      redirect_uri: environment.redirect_uri,
      cacheLocation: 'localstorage',
      useRefreshTokens: true,
      advancedOptions: {
        defaultScope: 'openid email'
      }
    };
    if (!EnvironmentType.isOnPrem(environment.environmentType)) {
      this.auth0Client = createAuth0Client(config);
      this.auth0Client.then(() => this.resetProfile());
    }
  }

  private async resetProfile() {
    const client = await this.auth0Client;
    const isAuthenticated = await client.isAuthenticated();
    const profile = isAuthenticated ? await client.getUser() : null;
    this.profileSubject.next(profile);
  }

  async handleLoginCallback(): Promise<any> {
    const client = await this.auth0Client;
    return client.handleRedirectCallback().then(result => {
      const targetRoute =
        result.appState && result.appState.target ? result.appState.target : '';

      this.resetProfile();

      return targetRoute;

    }).catch(result => {
      this.displayErrorService.openMessage(result);
      setTimeout(() => {
        this.logout();
      }, 2000);
    })
  }

  get profile$(): Observable<Auth0Profile> {
    return this.profileSubject.asObservable();
  }

  get currentProfile(): Auth0Profile {
    return this.profileSubject.value;
  }

  async isAuthenticated(): Promise<boolean> {
    const client = await this.auth0Client;
    return client.isAuthenticated();
  }

  async getToken(): Promise<string> {
    const client: any = await this.auth0Client;
    await client.getTokenSilently(); // ensure login, but ignore access_token result. we need openid;

    /**previous hack**/
    // todo: hack to get the id token. contact auth0 support or use another sass to get openid cleanly.
    // const cache = client.cache.cache;
    // const idToken = cache[Object.keys(cache)[0]].id_token;
    /** previous couple of ilines hack worked only on older auth0-spa.js versions**/

      // current auth0-spa.js version 1.14.0 requires following couple of lines to het long JWT token
      // todo: check an option sending short token and getting the long one on server side
    const idTokenClaims = client.getIdTokenClaims();
    if (idTokenClaims && idTokenClaims.__zone_symbol__value) {
      return idTokenClaims.__zone_symbol__value.__raw;
    }
    this.displayErrorService.openMessage("Could not extract JWT token from claims!");
    return "";
  }

  async showLogin(nextUrl = '/') {
    const client = await this.auth0Client;
    console.log('redirect url %s', environment.redirect_uri)
    await client.loginWithRedirect({
      redirect_uri: environment.redirect_uri
    });
    this.router.navigateByUrl(nextUrl);
  }

  async logout() {
    const client = await this.auth0Client;
    client.logout({
      client_id: environment.client_id,
      returnTo: window.location.origin
    });
  }
}
