import { Component, OnInit } from '@angular/core';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';

const svgBasePath = 'assets/media/icons/svg/';

@Component({
  selector: 'app-cell-renderer-svg-icon',
  template: `
    <span [inlineSVG]="svgIcon"></span>
  `,
  styleUrls: ['./cell-renderer-svg-icon.component.scss']
})
export class CellRendererSvgIconComponent implements INoRowsOverlayAngularComp {
  svgIcon: string;

  agInit({ value }): void {
    this.svgIcon = `${svgBasePath}${value}.svg`;
  }
}
