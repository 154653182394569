import { IAppState } from "../state/app.state";
import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromHierarchy from '../reducers/hierarchy.reducer';


export const selectHierarchyState = createFeatureSelector<IAppState, fromHierarchy.State>(
    fromHierarchy.hierarchyFeatureKey
);

export const selectHierarchy = createSelector(
    selectHierarchyState,
    (state: fromHierarchy.State) => state.hierarchy
);