import {LoggerConfig, LogLevel} from '../shared/services/logger-config';

export const logConfig: LoggerConfig = {
  enableColors: true,
  levels: {
    //"RouterEffects": LogLevel.DEBUG,
    "Core": LogLevel.DEBUG,
    // "OrgIssuesLinePortletComponent": LogLevel.DEBUG,
    "HeaderComponent": LogLevel.DEBUG,
    // "GraphTypeSelectorComponent": LogLevel.DEBUG,
    // "HierarchyEffects": LogLevel.DEBUG,
    // "DuplicateFabricsComponent": LogLevel.DEBUG,
    // "AdvancedFabricParameterDialogComponent": LogLevel.DEBUG,
    // "OrgGraphTypeSelectorsComponent": LogLevel.DEBUG,
    // "OrgIssuesListComponent": LogLevel.DEBUG,
    // "OrgActionsDashboardComponent": LogLevel.DEBUG,
    //"GraphTrendComponent": LogLevel.DEBUG,
    //"GraphDonutComponent": LogLevel.DEBUG,
    //"VerticalBarComponent": LogLevel.DEBUG,
    // "GraphTypeSelectorsComponent": LogLevel.DEBUG,
    //"OrgHealthDashboardComponent": LogLevel.DEBUG,
    //"OrgTenantsDashboardComponent": LogLevel.DEBUG,
    //"OrgChildrenGridComponent": LogLevel.DEBUG,
    //"HierarchyMapComponent": LogLevel.DEBUG,
    //"OrgHealthDashboardComponent": LogLevel.DEBUG,
    //"StoreLeftSideNavService": LogLevel.DEBUG,
    //"AnomaliesComponent": LogLevel.DEBUG,
    //"LossLatencyGridComponent": LogLevel.DEBUG,
    //"DeviceIssuesGridComponent": LogLevel.DEBUG,
    //"VpnIssuesGridComponent": LogLevel.DEBUG,
    //"VpnConnectivityComponent": LogLevel.DEBUG,
    //"TrafficAnomaliesComponent": LogLevel.DEBUG,
    //"VpnTrafficAnomaliesComponent": LogLevel.DEBUG,
    //"SparklineComponent": LogLevel.DEBUG,
    // "IssuesCategoryRendererComponent": LogLevel.DEBUG,
    //"VenuesHealthDashboardComponent": LogLevel.DEBUG,
    //"DevicesHealthDashboardComponent": LogLevel.DEBUG,
    //"DeviceHealthDashboardComponent": LogLevel.DEBUG,
    //"VenueHealthDashboardComponent": LogLevel.DEBUG,
    // "DashboardComponent": LogLevel.DEBUG,
    //"HealthDashboardComponent": LogLevel.DEBUG,
    // "IntegrationsComponent": LogLevel.DEBUG,
    //"JournalComponent": LogLevel.DEBUG,
    // "OrgHealthListComponent": LogLevel.DEBUG,
    // "ScheduleDialogComponent": LogLevel.DEBUG
    //"TrafficDeviationBarComponent": LogLevel.DEBUG,
    //"LossLatencyBarComponent": LogLevel.DEBUG,
    //"ReportsFiltersComponent": LogLevel.DEBUG,
    // "RouterEffects": LogLevel.DEBUG,
    //"FabricSingleHealthComponent": LogLevel.DEBUG,
    // "FabricSingleActionsComponent": LogLevel.DEBUG,
    // "FabricSingleActionsListComponent": LogLevel.DEBUG,
    //"VenueSingleHealthComponent": LogLevel.DEBUG,
    // "VenueSingleActionsComponent": LogLevel.DEBUG,
    //"VenueSingleActionListComponent": LogLevel.DEBUG,
    //"VenueSingleFabricsComponent": LogLevel.DEBUG,
    //"DeviceSingleHealthComponent": LogLevel.DEBUG,
    // "DeviceSingleActionsComponent": LogLevel.DEBUG,
    //"DevicesActionsListComponent": LogLevel.DEBUG,
    // "SingleDeviceActionsListComponent": LogLevel.DEBUG,
    //"ClientsHistoryListComponent": LogLevel.DEBUG,
    // "AlertsListComponent": LogLevel.DEBUG,
    // "EventsListComponent": LogLevel.DEBUG,
    // "AlertDataDialogComponent": LogLevel.DEBUG,
    // "GlobalEntitiesService": LogLevel.DEBUG,
    //"StoreBrandingService": LogLevel.DEBUG,
    //"FirebaseOrgPreferencesService": LogLevel.DEBUG,
    //"FirebaseUserPreferencesService": LogLevel.DEBUG,
    //"FirebaseDynamicDashboardService": LogLevel.DEBUG,
    // "HealthCellRendererComponent": LogLevel.DEBUG,
    // "EntitiesTreeComponent": LogLevel.DEBUG,
    // "OrgManageTenantComponent": LogLevel.DEBUG,
    //"OrgCrudComponent": LogLevel.DEBUG,
    // "TenantCrudComponent": LogLevel.DEBUG,
    // "UserRolesComponent": LogLevel.DEBUG,
    // "EditUserDialogComponent": LogLevel.DEBUG,
    // "UsersCrudComponent": LogLevel.DEBUG,
    // "TreeComponent": LogLevel.DEBUG,
    //"MapComponent": LogLevel.DEBUG,
    //"PropertiesContentComponent": LogLevel.DEBUG,
    //"ActionsMenuRendererComponent": LogLevel.DEBUG,
    //"HorizontalTimelineComponent": LogLevel.DEBUG,
    // "IssueActionsDialogComponent": LogLevel.DEBUG,
    // "OrgTrendGraphSelectorsConfigService": LogLevel.DEBUG,
    // "OrgHealthDashboardConfigService": LogLevel.DEBUG,
    //"GraphDonutComponent": LogLevel.DEBUG,
    // "TenantVenuesListComponent": LogLevel.DEBUG,
    // "FabricsHealthListComponent": LogLevel.DEBUG,
    // "DevicesHealthListComponent": LogLevel.DEBUG,
    //"TopologyComponent": LogLevel.DEBUG,
    //"DialogIntegrationComponent": LogLevel.DEBUG,
    // "VersionCheckService": LogLevel.DEBUG,
    "AppComponent": LogLevel.DEBUG,
    // "AnalyticsService": LogLevel.DEBUG,
    "GlobalErrorHandlerService": LogLevel.DEBUG,
    //"HealthChartComponent": LogLevel.DEBUG,
    //"BaseDashboardEntityList": LogLevel.DEBUG,
    //"PortForwardComponent": LogLevel.DEBUG,
    //"CreateFabricComponent": LogLevel.DEBUG,
    //"FlowComponent": LogLevel.DEBUG,
    // "VersionCheckService": LogLevel.DEBUG,
    // "AppComponent": LogLevel.DEBUG,
    // "AnalyticsService": LogLevel.DEBUG,
    //"BaseDashboardEntityList": LogLevel.DEBUG,
    //"StoreUserPreferencesService": LogLevel.DEBUG,
    //"TopologyInformationService": LogLevel.DEBUG,
    //"TopologyBuilderService": LogLevel.DEBUG,
    //"TreeByRootService": LogLevel.DEBUG,
    //"StoreWidgetPreferencesService": LogLevel.DEBUG,
    //"ClientsTopologyService": LogLevel.DEBUG,
    //"TenantVenuesActionsListComponent": LogLevel.DEBUG,
    //"FabricsVersionControlListComponent": LogLevel.DEBUG,
    //"StackedBarComponent": LogLevel.DEBUG,
    //"BrandingComponent": LogLevel.DEBUG,
    //"ChangeLogListComponent": LogLevel.DEBUG,
    //"DeviceReportsDashboardComponent": LogLevel.DEBUG,
    // "VenuesRoportsDashboardComponent": LogLevel.DEBUG
    //"MaintenanceGridComponent": LogLevel.DEBUG,
    //"CronGeneratorComponent": LogLevel.DEBUG,
    //"PeriodicBackupComponent": LogLevel.DEBUG,
    //"AnomalyThresholdComponent": LogLevel.DEBUG,
    //"InsightsAnomaliesComponent": LogLevel.DEBUG,
    //"BreadcrumbsComponent": LogLevel.DEBUG,
    //"ActionsListComponent": LogLevel.DEBUG,
    //"TracingComponent": LogLevel.DEBUG,
    //"AnomaliesLeanGridComponent": LogLevel.DEBUG,
    //"ReportsSenderComponent": LogLevel.DEBUG,
    //"OpenPortsGridComponent": LogLevel.DEBUG,
    //"UserAnomalyComponent": LogLevel.DEBUG,
    //"ConnectionFailuresGridComponent": LogLevel.DEBUG,
    //"ThroughputAnomalyGridComponent": LogLevel.DEBUG,
    //"IncompleteFabricsGridComponent": LogLevel.DEBUG,
    // "KpisMultiYChartComponent": LogLevel.DEBUG,
    // "VenueKpiSelectionListComponent": LogLevel.DEBUG,
    //"MultiAxisTrendComponent": LogLevel.DEBUG,
    //"TimeRangeManagerComponent": LogLevel.DEBUG,
    //"LocalStorageUserPreferencesService": LogLevel.DEBUG,
    //"DefineRuleComponent": LogLevel.DEBUG,
    "StatisticsStoreImpl": LogLevel.DEBUG,
    "FirestoreDashboardDatasource": LogLevel.DEBUG,
    "LocalStorageDashboardDatasource": LogLevel.DEBUG,
    "RemoteDashboardStore": LogLevel.DEBUG,
    "OnPremDashboardStore": LogLevel.DEBUG,
    "GraphComponent": LogLevel.DEBUG,
  },
  defaultLevel: LogLevel.ERROR
};
