import { Component, OnInit, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { LoggerService, Logger } from '../../../services/logger.service';
import { ICellRendererParams } from 'ag-grid-community';
import { SeverityService } from '../../../services/strategies/severity.service';
import { LegacySeverity } from '../../../models/severity.model';
import { isLast24Hours } from 'src/app/shared/operators/time-operator';
import { TimeManagerService } from 'src/app/shared/services/time-manager.service';

@Component({
  selector: 'app-health-cell-renderer',
  templateUrl: './health-cell-renderer.component.html',
  styleUrls: ['./health-cell-renderer.component.scss']
})
export class HealthCellRendererComponent implements ICellRendererAngularComp, OnDestroy {

  private logger: Logger;
  severity: LegacySeverity;
  healthPercent: number;
  healthNum: number;
  Severity = LegacySeverity;
  warningTooltipText: string;
  kpiMissingDevicesNo: number;

  constructor(private loggerFactory: LoggerService,
    private dateConvertor: TimeManagerService,
    private severityService: SeverityService) {
    this.logger = this.loggerFactory.getLogger("HealthCellRendererComponent");
  }

  agInit(params: ICellRendererParams): void {
    this.checkIfKpiMissing(params);
    this.generateHealthProperties(params);

  }
  generateHealthProperties(params: ICellRendererParams) {
    // For this to work defined the "field" in the colDef
    let health = params.getValue()
    if (health === undefined) {
      health = 100;
    }
    // this.healthPercent = +health.toFixed(0)
    if (health == -1) {
      // value of -1 in health is a special number that means that the health was not calculated yet
      this.healthNum = -1;
    } else {
      this.healthNum = this.healthPercent = +health.toFixed(0)
    }
    this.severity = this.severityService.serverityByHealth(+health.toFixed(0))

    //debugger
    //severityService: SeverityService, health: number
    // this.categoryName = params.data.categoryName;
    // this.category = params.data.category;
    // this.logger.debug(`health ${health} severity ${this.severity}`)
    //console.log("params in category renderer %o", params)
  }
  checkIfKpiMissing(params: ICellRendererParams) {
    if (this.displayWarningCol(params.colDef.field) && params && params.data) {
      if (params.data.kpiMissingDevicesNo && params.data.kpiMissingDevicesNo > 0) {
        this.warningTooltipText = `${params.data.kpiMissingDevicesNo} devices stopped receiving KPIs`;
      }
      if (params.data.lastKpiUpdate && !isLast24Hours(params.data.lastKpiUpdate)) {
        this.warningTooltipText = `Last KPI time: ${this.dateConvertor.dateByFormat(params.data.lastKpiUpdate, 'DD-MMM-YYYY HH:mm')}`;
      }
    }
  }
  displayWarningCol(field: string): boolean {
    if (field === "devicesHealth" ||
      field === "health"
    ) {
      return true;
    }
    return false;
  }
  refresh(params: any): boolean {
    return false;
  }
  ngOnDestroy(): void {
  }

}
