import {Component, OnInit} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ThemeService} from '../../../services/theme.service';
import {ICellRendererParams} from 'ag-grid-community';
import {ICONS_PATH} from '../../../../config/path';

@Component({
  selector: 'app-from-to-cell-renderer',
  templateUrl: './from-to-cell-renderer.component.html',
  styleUrls: ['./from-to-cell-renderer.component.scss']
})
export class FromToCellRendererComponent implements OnInit, ICellRendererAngularComp {

  constructor(private themeService: ThemeService) {
  }

  from: number = 0;
  to: number = 0;
  fromColor: string = this.themeService.HEALTH_INDICATOR_COLORS.SEVERITY_MEDIUM;
  fromToClass: string = 'severity';
  clickable: boolean = false;
  icon: string = 'gain.svg';
  iconPath: string = `${ICONS_PATH}${this.icon}`;
  iconClass: string = 'mat-icon';
  rowClass: string = 'from-to-icon-text';

  ngOnInit(): void {
  }

  agInit(params: ICellRendererParams): void {
    if (params) {
      if (Object.keys(params as any).includes('fromToClass'))
        this.fromToClass = (params as any).fromToClass ? this.fromToClass + ` ${(params as any).fromToClass}` : this.fromToClass;

      if (params.data) {
        this.from = typeof (params as any)['from'] === 'function'
          ? (params as any).from(params.data) ?? this.from
          : this.from;
        this.to = typeof (params as any)['to'] === 'function'
          ? (params as any).to(params.data) ?? this.to
          : this.to;
        this.fromColor = typeof (params as any)['fromColor'] === 'function'
          ? (params as any).fromColor(params.data) ?? this.fromColor
          : this.fromColor;
      }

      if (Object.keys(params as any).includes('clickable')) {
        this.clickable = (params as any).clickable !== undefined ? (params as any).clickable : this.clickable;
        if (this.clickable) {
          if (Object.keys(params as any).includes('icon'))
            this.icon = (params as any).icon ?? this.icon;
          if (Object.keys(params as any).includes('iconClass'))
            this.iconClass = (params as any).iconClass ? this.iconClass + ` ${(params as any).iconClass}` : this.iconClass;
          if (Object.keys(params as any).includes('rowClass'))
            this.rowClass = (params as any).rowClass ? this.rowClass + ` ${(params as any).rowClass}` : this.rowClass;
        }
      }
    }
  }

  refresh(params: any): boolean {
    return false;
  }

}
