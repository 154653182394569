import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import { Logger } from '../../logger.service';
import { Hierarchy, EntityTreeNode, OrganizationHierarchyTree } from '../../../models/hierarchy.model';
import { isFirebaseObjectExist, isFirebaseObjectHasOwnPreferences } from '../../../operators/firebase.operators';
import { HierarchyWithSnapshot, ClientStorageEntities } from '../../../models/client-storage.model';
import { CrudFireBase } from './crud-firebase';


@Injectable({
  providedIn: 'root'
})
export class UpdateEntitySiblingsPreferencesService extends CrudFireBase {
  constructor() {
    super()
  }
  /**
   * Update preferences for all current High level org siblings.
   */
  updateCurrentOrgSibling(firebaseDB: AngularFireDatabase, firebaseLocationString: string, currentOrgHierarchy: Hierarchy, preferences: any, logger: Logger, hierarchyWithSnapshot: HierarchyWithSnapshot[], entireOrgTree: OrganizationHierarchyTree, currentFirebaseEntity: ClientStorageEntities) {
    this.hierarchyWithSnapshot = hierarchyWithSnapshot;
    this.entireOrgTree = entireOrgTree;
    this.currentFirebaseEntity = currentFirebaseEntity;
    if (this.entireOrgTree)
      this.entireOrgTree.forEach(entity => {
        this.updateIfNoSelfPreferences(firebaseDB, firebaseLocationString, entity, currentOrgHierarchy, preferences, logger);
      })
  }
  /**
   * Recursive method for checking if sibling has it own preferences, and if yes, leave it and its children
   * If not, update the sibling and check for its children
   */
  updateIfNoSelfPreferences(firebaseDB: AngularFireDatabase, firebaseLocationString: string, entity: EntityTreeNode, currentOrgHierarchy: Hierarchy, preferences: any, logger: Logger) {
    if (entity.value.id !== currentOrgHierarchy[0].id) {
      let entityWitFirebase = this.hierarchyWithSnapshot.find(entityWitFirebase => entityWitFirebase.entityTreeNode.value.id == entity.value.id);
      let entityAsFireBaseobj: AngularFireObject<any> = firebaseDB.object(firebaseLocationString + `/${entity.value.id}-${entity.value.type}`);
      if (entityWitFirebase && isFirebaseObjectExist(entityWitFirebase.firebaseSnapshot)) {
        if (!isFirebaseObjectHasOwnPreferences(entityWitFirebase.firebaseSnapshot)) {
          entityAsFireBaseobj.update(this.getFinalPreferencesVersion(preferences, this.generateCompanyName(entityWitFirebase.firebaseSnapshot.payload.val().preferences.data)));
          if (entity.children && entity.children.length > 0) {
            entity.children.forEach(child => {
              this.updateIfNoSelfPreferences(firebaseDB, firebaseLocationString, child, currentOrgHierarchy, preferences, logger)
            })
          }
        }
      }
      else {
        entityAsFireBaseobj.set(this.getFinalPreferencesVersion(preferences, entity.value.name));
      }
    }
    else {
      entity.children.forEach(child => {
        this.updateIfNoSelfPreferences(firebaseDB, firebaseLocationString, child, currentOrgHierarchy, preferences, logger)
      })
    }
  }
  generateCompanyName(data: any): string {
    return data && data["orgBranding"] ?
      data["orgBranding"].companyName :
      null
  }
}
