import {Injectable} from '@angular/core';
import {InitatePropertiesDataService} from '../../properties/services/initate-properties-data.service';
import {SingleDevice, SingleDeviceType} from '../../../models/single-device.model';
import {BasicLink, SingleLink, SingleLinkType} from '../../../models/single-link.model';
import {GenericDevice, Topology} from '../../../models/topology';
import {Logger, LoggerService} from '../../../services/logger.service';
import {CloudLink, CloudNodeKPIs, CloudNodeType} from '../../../models/cloud-network.model';
import {TreeConfiguration} from "../models/topology-configuration";
import {LimitStringLengthPipe} from "../../../pipes/limit-string-length.pipe";
import * as uuid from 'uuid';
import {SwitchStack} from "../../../models/devices.model";


@Injectable({
  providedIn: 'root'
})
export class TopologyInformationService {
  logger: Logger;
  readonly WWW_ID = uuid();

  constructor(
    private limitStringLengthPipe: LimitStringLengthPipe,
    private preparePropData: InitatePropertiesDataService,
    private loggerFactory: LoggerService) {
    this.logger = this.loggerFactory.getLogger('TopologyInformationService');
  }

  /**
   * Checks if there are disconnected nodes, and if there is, push them to a different array
   * The method remove UnidentifiedDevice types from both nodes array and disconnected array
   * @param nodes the current topology nodes
   * @param links the current topology links
   */
  removeDisconnectedNodes(nodes: GenericDevice<any>[], links: BasicLink[]): [GenericDevice<any>[], any[]] {
    let disConnectedNodes = [];
    let filteredNodes: GenericDevice<SingleDevice>[] = nodes;
    nodes.forEach(node => {
      let isConnected = false;
      links.forEach(link => {
        if (node.id == link.startDeviceId || node.id == link.endDeviceId) {
          isConnected = true;
        }
      });
      if (!isConnected) {
        if (node.type == SingleDeviceType.UnidentifiedDevice) {
          filteredNodes = filteredNodes.filter(filteredNode => filteredNode.id !== node.id);
        } else {
          disConnectedNodes.push(node);
          disConnectedNodes = [...new Set(disConnectedNodes.map(node => node))];
        }
      }
    });

    this.logger.debug('Disconnected nodes: ', disConnectedNodes);
    disConnectedNodes.forEach(disNode => {
      filteredNodes = filteredNodes.filter(treeNode => treeNode.id !== disNode.id);
    });
    return [filteredNodes, disConnectedNodes];
  }

  /**
   * @method multiHealthReasonBy100 Calculate the reason percentage for health issues
   * @param nodes Is The current nodes array
   */
  multiHealthReasonBy100(nodes: any[]) {
    nodes.forEach(node => {
      node.healthDegradationReasons = this.preparePropData.multiPercentageBy100(node.healthDegradationReasons);
    });
    return nodes;
  }

  /**
   * Device topology need special size calculation
   */
  getDeviceTopologyRowClass(isDevice: boolean, selectedFabricTopology: Topology<SingleDevice, SingleLink>) {
    if (isDevice) {
      return 'tplg-device-topology-row';
    }
    if (selectedFabricTopology) {
      return 'tplg-venue-fabric-topology-row';
    }
  }

  getDeviceZoomContainerClass(isDevice: boolean) {
    if (isDevice) {
      return 'tplg-footer-device-right-corner';
    }
  }

  getClassForVenueFabricScreen(isFabricsVenue: boolean) {
    if (isFabricsVenue) {
      return 'disconnected-venue-fabrics-devices-container';
    }
  }

  getHeaderRowHeight(isDevice: boolean) {
    if (isDevice) {
      return 'topology-header-row-for-device';
    }
  }

  convertTopologyToGeneric(topologyData: Topology<any, any>) {
    if (topologyData.nodes[0].hasOwnProperty('device')) {
      return this.convertDeviceTopologyToGenericTopology((topologyData as Topology<SingleDevice, SingleLink>));
    }
    if (topologyData.nodes[0].hasOwnProperty('node')) {
      return this.convertCloudTopologyToGenericTopology(topologyData as Topology<CloudNodeKPIs, CloudLink>);
    }
  }

  /**
   * Convert Device DTO based topology to generic
   */
  private convertDeviceTopologyToGenericTopology(topologyData: Topology<SingleDevice, SingleLink>): Topology<GenericDevice<SingleDevice>, SingleLink> {
    let d3Topology: Topology<GenericDevice<SingleDevice>, SingleLink> = new Topology<GenericDevice<SingleDevice>, SingleLink>();
    d3Topology = {nodes: [], links: this.changeWWWLinksID(topologyData.links)};
    topologyData.nodes.forEach(node => {
      const genericDevice: GenericDevice<SingleDevice> = new GenericDevice<SingleDevice>(
        node.device.type === SingleDeviceType.WWW ? this.WWW_ID : node.device.id,
        node.device.type,
        this.limitStringLengthPipe.transform(node.device.name, 20),
        node
      );
      d3Topology.nodes.push(genericDevice);
    });
    topologyData.switchStacks?.forEach(switchStack => {
      const switchStackParent = this.findSwitchStackParent(switchStack, d3Topology);
      if (switchStackParent) {
        d3Topology.links.push({
          id: uuid(),
          type: SingleLinkType.Swith_Stack,
          startDeviceId: switchStack.vendorId,
          endDeviceId: switchStackParent.id
        });
      }
      else {
        d3Topology.links.push({
          id: uuid(),
          type: SingleLinkType.Swith_Stack,
          startDeviceId: switchStack.vendorId,
          endDeviceId: this.WWW_ID
        });
      }
      d3Topology.nodes.push({
        id: switchStack.vendorId,
        type: SingleDeviceType.SwitchStack,
        name: switchStack.name,
        originalData: {
          device: {
            id: switchStack.vendorId,
            name: switchStack.name,
            type: SingleDeviceType.SwitchStack
          },
          kpis: {}
        }
      });
      switchStack.members?.forEach(member => {
        d3Topology.links.push({
          id: uuid(),
          type: SingleLinkType.Swith_Stack,
          startDeviceId: member.deviceId,
          endDeviceId: switchStack.vendorId
        });
      });
    });
    return d3Topology;
  }

  private convertCloudTopologyToGenericTopology(topologydata: Topology<CloudNodeKPIs, CloudLink>): Topology<GenericDevice<CloudNodeKPIs>, BasicLink> {
    let d3Topology: Topology<GenericDevice<CloudNodeKPIs>, BasicLink> = new Topology<GenericDevice<CloudNodeKPIs>, BasicLink>();
    d3Topology = {nodes: [], links: []};
    topologydata.nodes.forEach(cloudNode => {
      const genericDevice: GenericDevice<CloudNodeKPIs> = new GenericDevice<CloudNodeKPIs>(
        cloudNode.node.type === CloudNodeType.WWW ? this.WWW_ID : cloudNode.node.id,
        cloudNode.node.type,
        cloudNode.node.name,
        cloudNode
      );
      d3Topology.nodes.push(genericDevice);
    });
    topologydata.links.forEach(link => {
      const basicLink: BasicLink = {
        id: link.id,
        status: link.status,
        type: link.type,
        startDeviceId: link.startDeviceId === 0 ? this.WWW_ID : link.startDeviceId,
        endDeviceId: link.endDeviceId === 0 ? this.WWW_ID : link.endDeviceId,
        description: 'Cloud Link'
      };
      d3Topology.links.push(basicLink);
    });

    return d3Topology;
  }

  findSwitchStackParent(switchStack: SwitchStack, d3Topology: Topology<GenericDevice<SingleDevice>, SingleLink>): GenericDevice<SingleDevice> {
    let sdwanParent: GenericDevice<SingleDevice> = null;
    for (let i = 0; i < switchStack.members.length; i++) {
      const member = switchStack.members[i];
      for (let j = 0; j < d3Topology.links.length; j++) {
        const link = d3Topology.links[j];
        if (link.startDeviceId == member.deviceId || link.endDeviceId == member.deviceId) {
          const potentialSdwanParent = d3Topology.nodes.find(node => node.type == SingleDeviceType.SDWAN && (node.id == link.startDeviceId || node.id == link.endDeviceId));
          if (potentialSdwanParent && potentialSdwanParent.originalData?.device?.properties && potentialSdwanParent.originalData.device.properties['isPrimary']) {
            return potentialSdwanParent;
          }
          else if (potentialSdwanParent) {
            sdwanParent = potentialSdwanParent;
          }
        }
      }
    }
    return sdwanParent;
  }

  /**
   * Return icon name for topology title.
   * @param treeConfiguration The TreeConfiguration object
   */
  getVendorIcon(treeConfiguration: TreeConfiguration) {
    if (treeConfiguration && treeConfiguration.nodes && treeConfiguration.nodes.length > 0) {
      const vendorName = treeConfiguration.nodes.map(node => {
        if (node.originalData && node.originalData.hasOwnProperty('node') && node.originalData.node.vendorName) {
          return node.originalData.node.vendorName;
        }
      });
      if (vendorName.length > 0) {
        switch (vendorName[0]) {
          case 'gcp':
            return 'google-cloud-icon';
          case 'aws':
            return 'aws-cloud-icon';
          default:
            break;
        }
      }
    }
  }

  private changeWWWLinksID(links: SingleLink[]) {
    return links.map(link => {
      if (link.startDeviceId === 0) {
        link.startDeviceId = this.WWW_ID;
      }
      if (link.endDeviceId === 0) {
        link.endDeviceId = this.WWW_ID;
      }
      return link;
    })
  }
}
