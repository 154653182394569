import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {TimeRangePickerComponent} from "./time-range-picker.component";
import {DateTimePickerComponent} from "./date-time-picker/date-time-picker.component";
import {DatePickerComponent} from "./date-picker/date-picker.component";
import {TimePickerComponent} from "./time-picker/time-picker.component";
import {TimePeriodPickerComponent} from "./time-period-picker/time-period-picker.component";
import {CommonModule} from "@angular/common";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {ReactiveFormsModule} from "@angular/forms";
import {TimePeriodInputComponent} from "./time-period-input/time-period-input.component";
import {MatSliderModule} from "@angular/material/slider";

const components = [
  TimeRangePickerComponent,
  DateTimePickerComponent,
  DatePickerComponent,
  TimePickerComponent,
  TimePeriodPickerComponent,
  TimePeriodInputComponent
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSliderModule,
    MatDatepickerModule
  ],
  exports: components,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TimeRangePickerModule {
}
