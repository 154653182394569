import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {ScheduleModel, SchedulingState} from "../../models/journal.model";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Logger, LoggerService} from "../../services/logger.service";

@Component({
  selector: 'app-schedule-dialog',
  templateUrl: './schedule-dialog.component.html',
  styleUrls: ['./schedule-dialog.component.scss']
})
export class ScheduleDialogComponent implements OnInit {

  @Input() dialogData: SchedulingState;
  @Input() titleText: string;
  @Input() scheduleNowText: string;
  @Input() scheduleText: string;
  @Output() scheduleSubmitted = new EventEmitter<ScheduleModel>();
  @Output() dialogClosed = new EventEmitter();
  scheduleState: boolean;
  defaultCron: string = '0 00 12 1/1 * ?';
  scheduleForm: FormGroup;
  logger: Logger;

  constructor(private fb: FormBuilder, private loggerService: LoggerService) { }

  ngOnInit(): void {
    this.logger = this.loggerService.getLogger('ScheduleDialogComponent');
    this.scheduleState = this.dialogData?.enabled;
    this.initiateForm();
  }

  initiateForm() {
    this.scheduleForm = this.fb.group({
      scheduleEnabled: new FormControl(this.scheduleState, [Validators.required]),
      cron: new FormControl(this.defaultCron, [Validators.required])
    });
    this.scheduleForm.valueChanges.subscribe(changes => {
      this.logger.debug('scheduleForm changes', changes);
    })
  }

  onCronSelected(cron: string) {
    this.scheduleForm.controls['cron'].setValue(cron);
  }

  submitSchedule() {
    const data: ScheduleModel = {scheduleEnabled: this.isScheduleEnabled, cron: this.cron};
    this.scheduleSubmitted.emit(data);
  }

  closeDialog() {
    this.dialogClosed.emit();
  }

  get isScheduleEnabled() {
    return this.scheduleForm && this.scheduleForm.controls['scheduleEnabled']?.value;
  }

  get cron() {
    return this.scheduleForm && this.scheduleForm.controls['cron']?.value;
  }

  get isFormDirty() {
    return this.scheduleForm && this.scheduleForm.dirty;
  }

  get isFormValid() {
    return this.scheduleForm && this.scheduleForm.valid;
  }

  get buttonColor() {
    return this.isScheduleEnabled
      ? 'primary'
      : 'warn';
  }

}
