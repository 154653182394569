import {KpiType, TrafficUnits, TrafficUnitsType, UnitTypes, ValueWithUnit} from "../models/kpi.model";
import {arrayMax, arrayMean} from "../operators/numeric-operator";

export class UnitUtils {
  static getUnitTypeByKpiType(kpiType: KpiType): string {
    switch (kpiType) {
      case KpiType.Traffic:
      case KpiType.Traffic_Prediction:
        return UnitTypes.Traffic;
      case KpiType.Throughput:
        return UnitTypes.Throughput;
      case KpiType.Latency:
        return UnitTypes.Milliseconds;
      case KpiType.Loss:
      case KpiType.Cpu:
      case KpiType.Memory:
        return UnitTypes.Percentage;
      case KpiType.Clients:
      case KpiType.Sessions:
        return UnitTypes.Count;
      default:
        return '';
    }
  }

  static getValueByKpiType(kpiType: KpiType, value: number, fromUnit?: string|TrafficUnits, toUnit?: string|TrafficUnits): ValueWithUnit {
    const unitType = this.getUnitTypeByKpiType(kpiType);
    switch (unitType) {
      case UnitTypes.Traffic:
      case UnitTypes.Throughput:
        const valueWithUnit = this.getTrafficValue(value, fromUnit as TrafficUnits, toUnit as TrafficUnits);
        valueWithUnit.unit = kpiType == KpiType.Throughput ? TrafficUnits.MBps : valueWithUnit.unit;
        return valueWithUnit;
      default:
        return {value, unit: unitType};
    }
  }

  static getTrafficValue(value: number, fromUnit: TrafficUnits, toUnit: TrafficUnits): ValueWithUnit {
    if (fromUnit && toUnit) {
      return {value: this.adujstTrafficUnits(value, fromUnit, toUnit), unit: toUnit};
    }

    return this.getBytesSizeAfterCalc(value);
  }

  static adujstTrafficUnits(value: number, valueUnit: TrafficUnits, desiredUnit: TrafficUnits, unitType?: TrafficUnitsType): number {
    let numOfDevition: number;
    let valueUnitIndex = TrafficUnits.unitIndex(valueUnit);
    let desiredUnitIndex = TrafficUnits.unitIndex(desiredUnit);
    if (valueUnitIndex > desiredUnitIndex) {
      numOfDevition = valueUnitIndex - desiredUnitIndex;
      while (numOfDevition > 0) {
        value = value * 1024;
        numOfDevition--;
      }
      return value;
    }
    if (desiredUnitIndex > valueUnitIndex) {
      numOfDevition = desiredUnitIndex - valueUnitIndex;
      while (numOfDevition > 0) {
        value = value / 1024;
        numOfDevition--;
      }
      return value;
    }
    return value;
  }

  static getBytesSizeByValue(countOfDeviation: number, kpiType?: KpiType): TrafficUnits {
    const zero = kpiType == KpiType.Throughput ? TrafficUnits.KBps : TrafficUnits.KB;
    const one = kpiType == KpiType.Throughput ? TrafficUnits.MBps : TrafficUnits.MB;
    const two = kpiType == KpiType.Throughput ? TrafficUnits.GBps : TrafficUnits.GB;
    const three = kpiType == KpiType.Throughput ? TrafficUnits.TBps : TrafficUnits.TB;

    switch (true) {
      case countOfDeviation == 0:
        return zero;
      case countOfDeviation == 1:
        return one;
      case countOfDeviation == 2:
        return two;
      case countOfDeviation == 3:
        return three;
      default:
        break;
    }
  }

  static calculateTrafficValue(value: number, count: number = 0): { finalValue: number, countOfDevition: number } {
    if (value > 1024) {
      value = value / 1024;
      count++;
      return this.calculateTrafficValue(value, count);
    }
    if (value <= 1024) {
      return {finalValue: +value, countOfDevition: count};
    }
  }

  static getBytesSizeAfterCalc(value: number, kpiType?: KpiType): ValueWithUnit {
    if (value) {
      let finalValue = this.calculateTrafficValue(value);
      let finalSize = this.getBytesSizeByValue(finalValue.countOfDevition, kpiType);
      return {value: finalValue.finalValue, unit: finalSize};
    }
    if (value == 0) {
      return {value: 0, unit: TrafficUnits.KB};
    }
    return null;
  }

  static divideByTrafficUnit(value: number, size: TrafficUnits, kpiType?: KpiType) {
    let divideNumber: number = 0;
    const b = 0;
    const kb = 1;
    const mb = 2;
    const gb = 3;
    const tb = 4;

    switch (true) {
      case size == TrafficUnits.Byte:
      case size == TrafficUnits.Bps:
        divideNumber = b;
        break;
      case size == TrafficUnits.KB:
      case size == TrafficUnits.KBps:
        divideNumber = kb;
        break;
      case size == TrafficUnits.MB:
      case size == TrafficUnits.MBps:
        divideNumber = mb;
        break;
      case size == TrafficUnits.GB:
      case size == TrafficUnits.GBps:
        divideNumber = gb;
        break;
      case size == TrafficUnits.TB:
      case size == TrafficUnits.TBps:
        divideNumber = tb;
        break;
      default:
        break;
    }

    let newSize = value;

    while (divideNumber > 0) {
      newSize = newSize / 1024;
      divideNumber--;
    }

    return newSize;
  }

  static calculateHighestUnit(values: number[], kpiType?: KpiType): TrafficUnits {
    return values?.length > 0 ? this.getBytesSizeAfterCalc(arrayMax(values), kpiType).unit as TrafficUnits : TrafficUnits.KB;
  }

  static calculateMeanUnit(values: number[]): TrafficUnits {
    return values.length > 0 ? this.getBytesSizeAfterCalc(arrayMean(values)).unit as TrafficUnits : TrafficUnits.KB;
  }

  static formatBytes(bytes) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let i;
    for (i = 0; bytes >= 1024 && i < 4; i++) {
      bytes /= 1024;
    }
    return `${bytes.toFixed(bytes < 1024 ? 0 : 2)} ${units[i]}`;
  }

  static formatValue(unit: string, value: number): number {
    if (unit in Object.values(TrafficUnits)) {
      return +value.toFixed(2);
    }

    return +value.toFixed(0);
  }

  static getUnitSuffix(unit: string): string {
    return unit != UnitTypes.Count ? `${unit}` : '';
  }

  static getFromUnit(kpiType: KpiType): TrafficUnits {
    return kpiType == KpiType.Throughput ? TrafficUnits.Byte : TrafficUnits.KB;
  }
}
