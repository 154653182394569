import {Component, OnInit} from '@angular/core';
import {TopologyWithProperties} from '../../shared-classes/topology-with-properties';
import {EditPropertiesService} from 'src/app/shared/components/properties/services/edit-properties.service';
import {GlobalEntitiesService} from 'src/app/shared/services/rest-services/global-entities.service';
import {SingleSizeCalculationService} from 'src/app/venues/services/single-size-calculation.service';
import {InitatePropertiesDataService} from 'src/app/shared/components/properties/services/initate-properties-data.service';
import {TranslateService} from '@ngx-translate/core';
import {D3TreeEventsService} from 'src/app/shared/components/network-topology/services/d3-tree-events.service';
import {ClickedElementService} from 'src/app/shared/services/strategies/clicked-element.service';
import {EntityType} from 'src/app/shared/models/entity-type.enum';
import {VenuesService} from 'src/app/shared/services/rest-services/venues.service';
import {BaseSingleEntityService} from 'src/app/shared/services/base-single-entity.service';
import {LoggerService} from 'src/app/shared/services/logger.service';
import {StoreKpisBarService} from '../kpis-bar/store-kpis-bar.service';
import {filter, take} from 'rxjs/operators';
import {GenerateFabricPropertiesService} from "../../../properties/services/generate-fabric-properties.service";
import {GenerateVenuePropertiesService} from "../../../properties/services/generate-venue-properties.service";
import {CloudSubnetDTO} from "../../../../models/cloud-network.model";
import {VenueData, VenueSyncData, VenueType} from "../../../../models/venues.model";
import {MatDialog} from "@angular/material/dialog";
import {PropertiesAnimation} from "../../../single-entities/base-single-entities-health";
import {RestKpiGeneratorService} from "../../../kpi-display-components/services/rest-kpi-generator.service";

@Component({
  selector: 'app-venue-topology-with-properties',
  templateUrl: './venue-topology-with-properties.component.html',
  styleUrls: ['./venue-topology-with-properties.component.scss'],
  animations: PropertiesAnimation
})
export class VenueTopologyWithPropertiesComponent extends TopologyWithProperties implements OnInit {
  venueData: VenueData;
  syncData: VenueSyncData;
  vendorName: string;
  syncClicked: boolean = false;

  constructor(
    public dialog: MatDialog,
    private venuesService: VenuesService,
    protected storeKpisBarService: StoreKpisBarService,
    protected editPropertiesService: EditPropertiesService,
    protected baseSingleEntityService: BaseSingleEntityService,
    protected globalEntitiesService: GlobalEntitiesService,
    protected singleSizeCalculationService: SingleSizeCalculationService,
    protected restKpiGeneratorService: RestKpiGeneratorService,
    protected preparePropsDataService: InitatePropertiesDataService,
    protected translate: TranslateService,
    protected d3TreeEventsService: D3TreeEventsService,
    protected loggerFactory: LoggerService,
    protected clickedElementService: ClickedElementService,
    protected fabricPropsGenerator: GenerateFabricPropertiesService,
    protected venuePropsGenerator: GenerateVenuePropertiesService,
  ) {
    super(dialog, storeKpisBarService, editPropertiesService, globalEntitiesService, singleSizeCalculationService, restKpiGeneratorService, preparePropsDataService, translate, d3TreeEventsService, loggerFactory, baseSingleEntityService, clickedElementService, fabricPropsGenerator, venuePropsGenerator);
  }

  ngOnInit() {
    const entitySubsc = this.baseSingleEntityService.notifyEntityDataAsObservable$.pipe(
      filter(entity => !!entity),
      take(1))
      .subscribe(entity => {
        if (entity && entity !== this.venueData) {
          this.venueData = entity as VenueData;
          this.entityData = entity;
          this.singleEntityHealth = +entity.health;
          this.entityType = EntityType.VENUE;
          this.propTitle = this.translate.instant('data.PROPERTIES.HEADER');
          this.initEntityData(this.entityData);
          this.subscribeToD3TreeClickEvents();
          this.calculateTopologySize();
          this.initTopology();
        }
      });
    this.subscription.push(entitySubsc);
  }

  /**
   * @method initTopology Receive the graph data from the snapshot data property
   * The method check if venue is campus type or cloud and activate the relevant api
   */
  initTopology() {
    let topology$;
    switch (this.venueData.venue.type) {
      case VenueType.CAMPUS:
        topology$ = this.venuesService.getVenueTopologyById(this.venueData.venue.id);
        break;
      case VenueType.CLOUD:
      case VenueType.SASE:
        topology$ = this.venuesService.getVenueCloudTopology(this.venueData.venue.id);
        break;
      default:
        break;
    }
    topology$.pipe(take(1)).subscribe(topology => {
      if (topology) {
        this.topology = {...topology};
      }
    });
  }

  get subnets(): CloudSubnetDTO[] {
    if (this.propData && this.propData.mainData) {
      const item = this.propData.mainData.panelElements.find(panel => panel && panel.key == 'subnets');
      if (item && item.value && item.value.length > 0) {
        return item.value as CloudSubnetDTO[];
      }
    }
  }

  get isShowClients(): boolean {
    return this.venueData && this.venueData.venue && this.venueData.venue.type == VenueType.CAMPUS;
  }

  get topologyTitle(): string {
    return this.venueData && this.venueData.venue && this.venueData.venue.name;
  }

  ngOnDestroy() {
    this.onDestroy();
  }
}
