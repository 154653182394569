import {Injectable} from '@angular/core';
import * as FileSaver from 'file-saver';

const EXCEL_EXTENSION = '.xlsx';
const CSV_EXTENSION = '.csv';
const CSV_TYPE = 'text/csv;charset=utf-8';

@Injectable({
  providedIn: 'root'
})
export class ExportFileService {

  constructor() {
  }

  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  public exportExcel(jsonData: any[] | any, fileName: string): void {
    import("xlsx").then(XLSX => {
      if (Array.isArray(jsonData)) {
        const ws = XLSX.utils.json_to_sheet(jsonData);
        const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
        const excelBuffer: any = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        this.saveExcelFile(excelBuffer, fileName);
      } else {
        const wb = XLSX.utils.book_new();
        for (const [key, value] of Object.entries(jsonData)) {
          if (key && Array.isArray(value)) {
            /* make the worksheet */
            const ws = XLSX.utils.json_to_sheet(value);
            /* add to workbook */
            XLSX.utils.book_append_sheet(wb, ws, key);
          }

        }
        const excelBuffer: any = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        this.saveExcelFile(excelBuffer, fileName);
      }
    });
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: this.fileType});
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

  /**
   * Saves the file on the client's machine via FileSaver library.
   *
   * @param buffer The data that need to be saved.
   * @param fileName File name to save as.
   * @param fileType File type to save as.
   */
  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], {type: fileType});
    FileSaver.saveAs(data, fileName);
  }

  /**
   * Creates an array of data to CSV. It will automatically generate a title row based on object keys.
   *
   * @param rows array of data to be converted to CSV.
   * @param fileName filename to save as.
   * @param columns array of object properties to convert to CSV. If skipped, then all object properties will be used for CSV.
   */
  public exportToCsv(rows: object[], fileName: string, columns?: string[]): string {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]).filter(k => {
      if (columns?.length) {
        return columns.includes(k);
      } else {
        return true;
      }
    });
    const csvContent =
      keys.join(separator) +
      '\n' +
      rows.map(row => {
        return keys.map(k => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator);
      }).join('\n');
    this.saveAsFile(csvContent, `${fileName}${CSV_EXTENSION}`, CSV_TYPE);
  }
}
