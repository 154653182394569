import {Component, Input, ChangeDetectionStrategy, Inject, EventEmitter, Output} from '@angular/core';
import {
  DynamicWordingRuleType,
  WordsWithRules,
  DYNAMIC_WORDING_CHARACTERS,
  DynamicWordingCharacters
} from './models/rules.model';
import {SentenceToWordsService} from './services/sentence-to-words.service';
import {DynamicSentence} from './models/dynamic-sentence.model';
import {debounceTime} from 'rxjs/operators';
import {Validators, FormControl} from '@angular/forms';

@Component({
  selector: 'app-dynamic-wordings',
  templateUrl: './dynamic-wordings.component.html',
  styleUrls: ['./dynamic-wordings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicWordingsComponent {
  @Input() dynamicSentence: DynamicSentence;
  @Output() wordChanged: EventEmitter<WordsWithRules<any>> = new EventEmitter();
  wording: WordsWithRules<any>[] = [];
  dynamicWordingRuleType = DynamicWordingRuleType;
  formControls: { [wordId: string]: FormControl } = {};


  constructor(private sentenceToWordsService: SentenceToWordsService,
              @Inject(DYNAMIC_WORDING_CHARACTERS) readonly dynamicWordingCharacters: DynamicWordingCharacters[],
  ) {
  }

  ngOnInit() {
    this.wording = this.sentenceToWordsService.buildWordsArray(this.dynamicSentence, this.dynamicWordingCharacters);
    this.generateForm();
  }

  /**
   * Follow the input field via the valuechanges observable
   */
  generateForm() {
    let inputWords: Array<WordsWithRules<any>>;
    if (this.wording) {
      inputWords = this.wording.filter(word => word.rule.type === DynamicWordingRuleType.Input);
    }
    if (inputWords && inputWords.length > 0) {
      inputWords.forEach(word => {
        this.formControls[word.id] = new FormControl(word.value, [Validators.required]);
      })
      Object.keys(this.formControls).forEach(key => {
        this.formControls[key].valueChanges.pipe(debounceTime(1000)).subscribe(changes => {
          if (this.formControls[key].valid) {
            let word = this.wording.find(word => word.id.toString() === key);
            if (word != undefined) {
              word.value = changes;
              this.wordChanged.emit(word);
            }
          }
        })
      })
    }
  }

  /**
   * Update the current text after dropdown selection
   */
  onDropdownClicked(value: (string | number), clickedWord: WordsWithRules<any>) {
    let word = this.wording.find(word => word.id == clickedWord.id);
    if (word != undefined && word.value !== value.toString()) {
      word.value = value.toString();
      this.wordChanged.emit(word);
    }
  }

  /**
   * Update the current Input word for the keyup event
   */
  onKeyUp(event, inputWord: WordsWithRules<any>) {
    event.stopPropagation();
  }
}
