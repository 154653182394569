import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {KpiDataset, KpiTrendDisplay} from '../../../models/kpi.model';
import {EntityType} from '../../../models/entity-type.enum';
import {NgChanges} from '../../../extend-angular-classes/on-changes';
import {KPIChartJsAnnotation} from '../../../modals/deviation-reports-dialog/components/venue-deviation-reports-dialog/venue-deviation-reports-dialog.component';
import {MultiKpiChartsService} from "../../chartjs-components/services/multi-kpi-charts.service";
import {TrendOptionsData} from '../multi-kpi-action-graph-trend/multi-kpi-action-graph-trend.model';

export type DataForKpiRequest = {
  entityType: EntityType;
  entityId: number;
  tenantId: number;
  date: string;
}

@Component({
  selector: 'app-kpis-trends',
  templateUrl: './kpis-trends.component.html',
})
export class KpisTrendsComponent {
  @Input() height: number;
  @Input() width: number;
  @Input() isResponsive: boolean = true;
  @Input() isShowStacked: boolean = true;
  @Input() kpiTrends: KpiTrendDisplay[];
  @Input() maxTicksX: number = 4;
  @Input() maxTicksY: number = 5;
  @Input() annotations: KPIChartJsAnnotation;
  @Input() timeDisplayFormat: string = 'HH:mm';
  @Input() minX: number;
  @Input() maxX: number;
  @Input() hideTooltip: boolean = false;
  isKpiTrends: boolean = true;

  constructor(private cdr: ChangeDetectorRef,
              private multiKpiChartsService: MultiKpiChartsService
  ) {
  }

  ngOnChanges(changes: NgChanges<KpisTrendsComponent>) {
    if (!this.kpiTrends || this.kpiTrends.length < 1) {
      this.isKpiTrends = false;
    }
    this.cdr.detectChanges();
  }

  onBarHovered(event: KpiDataset) {
    this.multiKpiChartsService.regenerateKpiMarkerDisplayData(this.kpiTrends, event);
  }

  onTrendOptionSelected(option: TrendOptionsData) {
    if (option) {
      this.kpiTrends[option.index].currentOption = option.option;
      this.kpiTrends[option.index].trend = option.data;
      this.kpiTrends[option.index].unit = option.unit;
      this.kpiTrends[option.index].type = option.type;
      // this.kpiTrends[option.index].marker = option.marker;
      this.multiKpiChartsService.regenerateKpiMarkerDisplayDataSingle(this.kpiTrends[option.index]);
    }
  }
}
