import {TopologyConfiguration, TreeConfiguration} from './topology-configuration';
import {SingleDeviceType} from 'src/app/shared/models/single-device.model';
import {
  SingleLink,
  SingleLinkType,
  SingleLinkStatus,
  D3Link,
  LinkVendorStatus
} from 'src/app/shared/models/single-link.model';
import {GenericDevice, SingleD3Node} from 'src/app/shared/models/topology';
import * as d3 from 'd3';
import * as uuid from 'uuid';
import {TopologyBuilderService} from '../services/topology-builder.service';
import {WidthHeight} from "../../../models/utils-classes/sizes.model";


export class D3Tree {
  treeConfiguration: TreeConfiguration;
  topologyConfiguration: TopologyConfiguration;
  treeSizes: WidthHeight;
  svgSizes: WidthHeight;
  /**
   * The original nodes array
   */
  public nodesBeforeD3: GenericDevice<any>[] = [];
  /**
   * The nodes array as being created out of the d3.tree function
   */
  public nodesAfterD3: SingleD3Node<any>[] = [];
  /**
   * The original link array
   */
  linksBeforeD3: SingleLink[];
  /**
   * The links array as being created out of the d3.tree function
   */
  linksAfterD3: D3Link[];
  treeData: any;
  root: any;
  maxChildrenNumber = 0;
  isSqueezable: boolean;


  constructor(
    treeConfiguration: TreeConfiguration,
    topologyConfiguration: TopologyConfiguration,
    treeSizes: WidthHeight,
    private topologyBuilder: TopologyBuilderService,
    isSqueezable: boolean = false,
  ) {
    this.treeSizes = treeSizes;
    this.treeConfiguration = treeConfiguration;
    this.topologyConfiguration = topologyConfiguration;
    this.isSqueezable = isSqueezable;
    this.createTreeLayout(this.treeConfiguration, this.topologyConfiguration);
  }

  /**
   * @method createTreeLayout Responsible to create the tree layout using two main tools
   * 1. The create tree service
   * 2. The d3.js functions
   */
  createTreeLayout(treeConfiguration: TreeConfiguration, topologyConfiguration: TopologyConfiguration) {
    this.convertTopologyArraysToTreeStructure(treeConfiguration);
    this.generateTreeWithD3(topologyConfiguration);
  }

  /**
   * Uses the nodes and links array, in order to create one tree object,
   * That will use the d3 in creating the tree layout
   */
  convertTopologyArraysToTreeStructure(treeConfiguration: TreeConfiguration) {
    this.nodesBeforeD3 = [...treeConfiguration.nodes];
    this.linksBeforeD3 = [...treeConfiguration.links];
    this.root = this.topologyBuilder.generateTree(this.nodesBeforeD3, this.linksBeforeD3);

    this.addLinksForFakeRoot(this.root);
  }


  /**
   * D3 create tree with nodes and links (in D3 DTO)
   */
  private generateTreeWithD3(topologyConfiguration: TopologyConfiguration) {
    if (this.root && this.nodesBeforeD3.length > 0 && this.linksBeforeD3.length > 0) {
      this.root = d3.hierarchy(this.root);
      this.maxChildrenNumber = this.topologyBuilder.calculateMaxChildrenPerDepth(this.root);
      this.svgSizes = this.topologyBuilder.getTreeComputedSizes(this.treeSizes, this.topologyConfiguration, this.maxChildrenNumber, this.isSqueezable);
      const treemap = d3.tree().separation(function (a, b) {
        if ((a.parent as SingleD3Node<any>).data.id === (a.parent as SingleD3Node<any>).data.id) {
          return 1.1;
        } else {
          return 1;
        }
      })
        .size([this.svgSizes.height, this.svgSizes.width]);

      this.treeData = treemap(this.root);
      this.nodesAfterD3 = this.treeData.descendants();

      /**
       * Restore the link data
       */
      this.linksAfterD3 = this.treeData.links(this.nodesAfterD3);
      this.linksAfterD3.forEach(link => {
        this.linksBeforeD3.forEach(beforeLink => {
          if ((link.source.data.id == beforeLink.startDeviceId || link.source.data.id == beforeLink.endDeviceId)
            && (link.target.data.id == beforeLink.startDeviceId || link.target.data.id == beforeLink.endDeviceId)) {
            link.data = beforeLink;
          }
        });
      });

      this.addOutsidersLinks();
      this.nodesLastAdditions(topologyConfiguration);
    }
  }

  /**
   * Add links that was didn't not created by the d3Tree functions:
   * In case we might want to display those links anyway
   * The method does the following:
   * 1. Find link that is part of the linksBeforeD3 array, and not part of the
   * linksAfterD3 array.
   * 2. The method generateNew link with source, target and data properties
   */
  addOutsidersLinks() {
    let outsiderLink: SingleLink;
    let outsiderD3Link: D3Link;
    this.linksBeforeD3.forEach(beforeLink => {
      let isLinkExist: boolean = false;
      this.linksAfterD3.forEach(afterLink => {
        if (beforeLink.id == afterLink.data.id) {
          isLinkExist = true;
        }
      });
      if (!isLinkExist && beforeLink.origin == LinkVendorStatus.MULTI_VENDOR) {
        outsiderLink = beforeLink;
        outsiderD3Link = {source: null, target: null, data: beforeLink};
        this.nodesAfterD3.forEach(node => {
          if (node.data.id == outsiderLink.endDeviceId || node.data.id == outsiderLink.startDeviceId) {
            if (!outsiderD3Link.source) {
              outsiderD3Link.source = node;
            }
            if (!outsiderD3Link.target && node.data.id !== outsiderD3Link.source.data.id) {
              outsiderD3Link.target = node;
            }
          }
          if (outsiderD3Link.source && outsiderD3Link.target && this.linksAfterD3.find(link => link.data.id == outsiderD3Link.data.id) == null) {
            this.linksAfterD3.push(outsiderD3Link);
          }
        });
      }
    });
  }

  /**
   * @method noLinkToFakeRoot Make Sure that in case of reload topology (e.g., when show client is turn off after being turn on),
   * fake links will be added only if there are no existing fake links already
   * @param child The current child of the fake root
   */
  noLinkToFakeRoot(child: any) {
    let noLink = true;
    this.linksBeforeD3.forEach(link => {
      if (link.type == SingleLinkType.Fake &&
        (link.startDeviceId == child.id || link.endDeviceId == child.id)) {
        noLink = false;
      }
    });
    return noLink;
  }

  /**
   * addLinksForFakeRoot Added link to the links array, in case that there is fake root node
   */
  private addLinksForFakeRoot(root: any) {
    if (root?.type == SingleDeviceType.FakeRoot) {
      root.children?.forEach(child => {
        if (this.noLinkToFakeRoot(child)) {
          let fakeLink: SingleLink = {
            id: uuid(),
            type: SingleLinkType.Fake,
            logicalConnections: null,
            status: SingleLinkStatus.OK,
            origin: LinkVendorStatus.VENDOR,
            description: 'Fake Link To fake root node',
            startDeviceId: child.id,
            startPort: null,
            startPortDetails: null,
            endDeviceId: root.id,
            endPort: null,
            endPortDetails: null
          };
          this.linksBeforeD3.push(fakeLink);
        }
      });
    }
  }

  /**
   * Responsible for 2 things:
   * 1. initilize the selectedDevice property at the configuration file.
   * Even thought we already has the select device Id,
   * We need to initlize the entire selected device object here, after it has its x and y postioning
   * Only then, the view box could determine its height related to the selected device height,
   * and by that, displaying the selected device in the middle of the single device screen at the topology container
   *
   * 2. Make sure WWW node has no name for display
   */
  private nodesLastAdditions(topologyConfiguration: TopologyConfiguration) {

    this.nodesAfterD3.forEach(node => {
      if (node.data.type == SingleDeviceType.WWW) {
        node.data.name = '';
      }
      if (node.data.id == topologyConfiguration.selectedDeviceID) {
        topologyConfiguration.selectedDevice = node;
      }
    });
  }
}
