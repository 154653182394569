import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AnomaliesListComponent} from './anomalies-list/anomalies-list.component';
import {AnomaliesGridsRendererComponent} from './anomalies-grids-renderer/anomalies-grids-renderer.component';
import {AnomalyGridDirective} from './directives/anomaly-grid.directive';
import {DeviceIssuesGridComponent} from './components/device-issues-grid/device-issues-grid.component';
import {VpnIssuesGridComponent} from './components/vpn-issues-grid/vpn-issues-grid.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {SharedComponentsModule} from '../../../../../shared-components.module';
import {SharedPipesModule} from '../../../../../shared-pipes.module';
import {LossLatencyGridComponent} from './components/loss-latency-grid/loss-latency-grid.component';
import {TrafficAnomaliesComponent} from './components/traffic-anomalies/traffic-anomalies.component';
import {VpnTrafficAnomaliesComponent} from './components/vpn-traffic-anomalies/vpn-traffic-anomalies.component';
import {SharedDirectivesModule} from '../../../../../shared-directives.module';
import {AnomaliesGridFiltersComponent} from './anomalies-grid-filters/anomalies-grid-filters.component';
import {MatRadioModule} from '@angular/material/radio';
import {UserAnomalyComponent} from './components/user-anomaly/user-anomaly.component';
import {ConnectionFailuresGridComponent} from './components/connection-failures-grid/connection-failures-grid.component';
import {ThroughputAnomalyGridComponent} from './components/throughput-anomaly-grid/throughput-anomaly-grid.component';
import {IncompleteFabricsGridComponent} from './components/incomplete-fabrics-grid/incomplete-fabrics-grid.component';
import {ANOMALY_GRID_SIZES, anomalyInsightsGridSizes} from "../../../../../global-utils/sizes";
import {AgGridTablesModule} from "../../../../../ag-grid/ag-grid.module";
import {CpuGridComponent} from "./components/cpu-grid/cpu-grid.component";
import {MemoryGridComponent} from "./components/memory-grid/memory-grid.component";
import {VpnLatencyGridComponent} from "./components/vpn-latency-grid/vpn-latency-grid.component";
import {VpnLossGridComponent} from "./components/vpn-loss-grid/vpn-loss-grid.component";
import {VpnJitterGridComponent} from "./components/vpn-jitter-grid/vpn-jitter-grid.component";
import {VpnMosGridComponent} from "./components/vpn-mos-grid/vpn-mos-grid.component";

const gridComponents = [
  DeviceIssuesGridComponent,
  VpnIssuesGridComponent,
  LossLatencyGridComponent,
  TrafficAnomaliesComponent,
  VpnTrafficAnomaliesComponent,
  VpnLatencyGridComponent,
  VpnLossGridComponent,
  VpnJitterGridComponent,
  VpnMosGridComponent,
  UserAnomalyComponent,
  ConnectionFailuresGridComponent,
  ThroughputAnomalyGridComponent,
  IncompleteFabricsGridComponent,
  CpuGridComponent,
  MemoryGridComponent
]

@NgModule({
  declarations: [
    AnomaliesListComponent,
    AnomaliesGridsRendererComponent,
    AnomalyGridDirective,
    AnomaliesGridFiltersComponent,
    gridComponents
  ],

  imports: [
    CommonModule,
    MatIconModule,
    MatDividerModule,
    AgGridTablesModule,
    SharedComponentsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    MatRadioModule
  ],
  exports: [
    AnomaliesListComponent,
    AnomaliesGridsRendererComponent,
    gridComponents
  ],

  providers: [
    {
      provide: ANOMALY_GRID_SIZES,
      useValue: anomalyInsightsGridSizes
    }]
})
export class InsightsAnomaliesGridModule {
}
