<div class="row filters-bar-row">
  <div class="col filter-bar-col" [ngClass]="cssColContainer" *ngFor="let filter of filters">
    <ng-container *ngIf="filter.fieldType == filterFieldType.SELECT">
      <mat-form-field class="filters-form-field">
        <mat-label>{{filter.name | camelToUpper}}</mat-label>
        <ng-container *ngIf="filter.searchable">
          <input matInput [formControl]="searchFormControl" [matAutocomplete]="auto" placeholder="Search venues">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="searchInputDisplay" [panelWidth]="280">
            <mat-option *ngFor="let datum of getFilteredData(filter)" [value]="datum">{{datum.name}}</mat-option>
          </mat-autocomplete>
        </ng-container>
        <mat-select *ngIf="!filter.searchable" class="dashboard-selection-container" (selectionChange)="onSingleSelectionChanged($event.value)"
                    [compareWith]="setDefaultValue">
          <mat-option *ngFor="let datum of filter.data" [value]="datum"
                      [disabled]="filter.isDisableSelection">
            {{datum.name | camelToUpper}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="filter.fieldType == filterFieldType.INPUT">
      <mat-form-field class="filters-form-field">
        <mat-label>{{filter.name | camelToUpper}}</mat-label>
        <input matInput [formControl]="inputFormControl">
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="filter.fieldType == filterFieldType.Multi_Select">
      <mat-form-field class="filters-form-field">
        <mat-label>{{filter?.name | camelToUpper}}</mat-label>
        <mat-select [formControl]="multiSelectFormControl(filter.name)" multiple (openedChange)="onOpenChanged($event)"
                    (selectionChange)="onMultiSelectionChanged($event)">
          <mat-select-trigger>
            {{getTriggerValue(filter)}}
          </mat-select-trigger>
          <mat-option *ngFor="let entry of filter.data" [value]="entry">{{entry.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>
</div>
