import {Component, OnInit, Input, ChangeDetectorRef, ChangeDetectionStrategy, SimpleChanges} from '@angular/core';
import {HoriznotalTimeSpan, HorizontalTimelineMarker} from './models/horizontal-time-span.model';
import {HorizontalTimelineService} from './services/horizontal-timeline.service';
import {Logger, LoggerService} from '../../services/logger.service';
import {NgChanges} from "../../extend-angular-classes/on-changes";

@Component({
  selector: 'app-horizontal-timeline',
  templateUrl: './horizontal-timeline.component.html',
  styleUrls: ['./horizontal-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HorizontalTimelineComponent {
  @Input() items: HoriznotalTimeSpan[];
  @Input() marker: HorizontalTimelineMarker;
  @Input() height: number = 25;
  @Input() width: number = 265;

  private logger: Logger;
  timeSpans: HoriznotalTimeSpan[];
  markerTooltipData: string;

  constructor(
    private horizontalTimelineService: HorizontalTimelineService,
    private loggerFactory: LoggerService,
    private cdr: ChangeDetectorRef,
  ) {
    this.logger = this.loggerFactory.getLogger("HorizontalTimelineComponent");
  }

  ngOnChanges(changes: NgChanges<HorizontalTimelineComponent>) {
    this.logger.debug("width", this.width);
    this.timeSpans = this.horizontalTimelineService.addSpansDisplayData(this.items, this.width, this.marker);
    this.logger.debug("timeSpans", this.timeSpans);
    this.logger.debug("marker", this.marker);
    this.markerTooltipData = this.horizontalTimelineService.generateMarkerTooltipData(this.timeSpans);
    this.cdr.markForCheck();
  }
}
