import * as moment from 'moment';
import {GraphTrendConfig} from '../components/chartjs-components/models/graph-trend-config';
import {GraphTrendMarker, SelectedBarDatasets} from "../components/chartjs-components/models/chart-js-events.model";
import {StringKeyValue} from "./utils-classes/key-value.model";
import {TrendOptionsDto} from '../components/kpi-display-components/multi-kpi-action-graph-trend/multi-kpi-action-graph-trend.model';
import {EntityType} from "./entity-type.enum";
import {Observable} from "rxjs";

export enum KpiType {
  Health = "Health",
  Utilization = "Utilization",
  Latency = "Latency",
  Loss = "Loss",
  Jitter = "Jitter",
  Mos = "Mos",
  Traffic = "Traffic",
  Throughput = "Throughput",
  Clients = "Clients",
  Status = "Status",
  APP = "App",
  Connection_Failures = "Connection_Failures",
  Traffic_Prediction = "Traffic_Prediction",
  SSID = "SSID",
  Cpu = 'Cpu',
  Memory = 'Memory',
  Sessions = 'Sessions'
}

export namespace KpiType {
  export function getKpiTypeAsArray() {
    return [KpiType.Latency, KpiType.Loss, KpiType.Traffic, KpiType.Clients]
  }
}

export class KpiValue {
  constructor(public value: number) {
  }
}

export class KpiValueChange extends KpiValue {
  constructor(value: number, change: string) {
    super(value)
    this.change = change || "0";
  }

  change?: string;
}

export class KpiData extends KpiValue {
  key: KpiType;

  constructor(key: KpiType, value: number) {
    super(value);
    this.key = key;
  }


  get kpiWidgetClass() {
    if (this.key == KpiType.Health)
      return "health-widget-bar-container";
  }
}

export class KpiTrend extends Array<{ datetime: moment.Moment, value: number }> {}

export type DateValue = {date: number, value: number};
export type KpiTrendByDestination = {[destination: string]: Array<DateValue>};
export type AggregatedKpiTrendByDestination = {id: string, value: number};

/**
 * Imry: Added the kpiDisplayData class because of the need to display big numbers with comma separtors.
 * The meaning of this, is they can only be strings.
 */
export class kpiDisplayData {
  key: KpiType;
  value: string;
}

export enum KpiGroupedBy {
  Application = "Application",
  Protocol = "Protocol",
  Port = "Port",
  Wired = "Wired",
  Traffic = "Traffic",
  Failure_Step = "Failure_Step"
}

export class StackedKpiData {
  type: string;
  data: { x: any, y: number }[];
}

export class TrafficKpiDataDisplay {
  type: string;
  data: { x: any, y: number }[];
  unit: TrafficUnits;
}

export type StackedOrSplit = ("stacked" | "split");

export class splitAndStackedConfig {
  splitGraphConfig: GraphTrendConfig;
  stackedGraphConfig: GraphTrendConfig
}

export enum TrafficUnits {
  Byte = "Byte",
  KB = "KB",
  MB = "MB",
  GB = "GB",
  TB = "TB",
  Bps = "Bytes/s",
  KBps = "KBb/s",
  MBps = "Mb/s",
  GBps = "Gb/s",
  TBps = "Tb/s"
}

export enum TrafficUnitsType {
  Raw,
  Rate
}

export namespace TrafficUnits {
  export function trafficUnitsAsArray() {
    return [
      TrafficUnits.KB,
      TrafficUnits.MB,
      TrafficUnits.GB,
      TrafficUnits.TB
    ];
  }

  export function throughputUnitsAsArray() {
    return [
      TrafficUnits.KBps,
      TrafficUnits.MBps,
      TrafficUnits.GBps,
      TrafficUnits.TBps
    ];
  }

  export function unitIndex(unit: TrafficUnits): number {
    const units = trafficUnitsAsArray().includes(unit) ? trafficUnitsAsArray() : throughputUnitsAsArray();
    return units.findIndex(u => u == unit);
  }
}

export enum ThresholdKpiType {
  Latency = "Latency",
  Loss = "Loss",
  Traffic = "Traffic",
  Throughput = "Throughput"
}

export class TrafficValueAndSize {
  value: number;
  size: TrafficUnits
}

export class KpiTrendDisplay {
  type: KpiType;
  trend: KpiTrend | StackedKpiData[];
  options?: TrendOptionsDto;
  currentOption?: string;
  application?: StackedKpiData[];
  wired?: StackedKpiData[];
  port?: StackedKpiData[];
  marker?: GraphTrendMarker;
  unit?: TrafficUnits;

  constructor(type: KpiType, trend: KpiTrend | StackedKpiData[], marker?: GraphTrendMarker, application?: StackedKpiData[], wired?: StackedKpiData[], unit?: TrafficUnits, options?: TrendOptionsDto, currentOption?: string) {
    this.type = type;
    this.trend = trend;
    this.marker = marker;
    this.unit = unit;
    this.application = application;
    this.wired = wired;
    this.options = options;
    this.currentOption = currentOption;
  }

  dateByFormat(unix: Date | number | string | moment.Moment, format: string) {
    let dateString = moment(unix).format(format);
    return dateString;
  }

  get dateDisplay(): StringKeyValue[] {
    if (this.marker) {
      const timestamp = this.marker.moment;
      let dataAsString: StringKeyValue[] = [];
      if (this.type === KpiType.Traffic || this.type === KpiType.Throughput) {
        const index = (this.trend[0] as StackedKpiData).data.findIndex(point => (point.x as any)._i === timestamp._i);
        if (index > -1) {
          dataAsString[0] = {
            key: `${(this.trend[0] as StackedKpiData).type}: `,
            value: `${(this.trend[0] as StackedKpiData).data[index].y}`
          };
          dataAsString.push({
            key: `${(this.trend[1] as StackedKpiData).type}: `,
            value: `${(this.trend[1] as StackedKpiData).data[index].y}`
          });
          dataAsString.push({
            key: `Time: `,
            value: `${this.dateByFormat(timestamp, 'DD-MMM-YYYY HH:mm')}`
          })
        }
      } else {
        const index = (this.trend as KpiTrend).findIndex(point => (point.datetime as any)._i === timestamp._i);
        if (index > -1) {
          dataAsString[0] = {
            key: ``,
            value: `${(this.trend as KpiTrend)[index].value}`
          }
          dataAsString[1] = {
            key: `Time: `,
            value: `${this.dateByFormat(timestamp, 'DD-MMM-YYYY HH:mm')}`
          }
        }
      }
      return dataAsString;
    } else {
      return null;
    }
  }
}

export type KpiDataset = {
  kpiType: KpiType;
  selectedDataset: SelectedBarDatasets;
}

export type KpiEventMomentDataset = {
  kpiType: KpiType;
  moment: any;
}

export function isStacked(trend: KpiTrend | StackedKpiData[]) {
  return trend && trend.length > 0 && trend[0].hasOwnProperty("type");
}

export interface DataWithUnit {
  data: any[];
  unit: string;
  type: string;
  uniqueIdentifier: string;
  additionalData?: any;
}

export interface KpiMVCData extends DataWithUnit {
  fromColor: string | string[];
  toColor: string | string[];
}

export class IdAndObservable<T, S> {
  id: number;
  data: T;
  observable: Observable<S>;
}

export type EntityTypeWithIds = { [key in EntityType]?: number[] };

export type ValueWithUnit = {value: number, unit: string};

export class ValuesWithUnit<T> {
  values: T[];
}

export namespace UnitTypes {
  export const Traffic = 'traffic';
  export const Throughput = 'throughput';
  export const Milliseconds = 'ms';
  export const Percentage = '%';
  export const Count = 'Count';
}
