import {Injectable} from '@angular/core';
import {SingleLinkStatus} from '../../models/single-link.model';
import {RssiLabels} from '../../models/clients.model';
import {KpiType} from '../../models/kpi.model';
import {GenericDevice, SingleD3Node} from '../../models/topology';
import {DeviceState} from '../../models/devices.model';
import {VPNConnectionStatus} from '../../models/vpn.model';

@Injectable({
  providedIn: 'root'
})
export class HealthColorsService {

  readonly SEVERITY_COLORS: string[] = ['#ff8780', '#F39915', '#7dd090', '#C4C4C4'];

  constructor() {
  }


  getKpiChangeColor(kpi: { key: KpiType; value: string | number; change: number; }) {
    if (kpi.key == KpiType.Loss || kpi.key == KpiType.Latency) {
      if (kpi.change >= 0)
        return this.SEVERITY_COLORS[1];
      return this.SEVERITY_COLORS[2]
    }
    return '#BAB8B8';
  }

  getHealthColors(health: number) {
    switch (true) {
      case health <= 59:
        return this.SEVERITY_COLORS[0];
      case health >= 60 && health <= 90:
        return this.SEVERITY_COLORS[1];
      case health >= 90:
        return this.SEVERITY_COLORS[2];
      default:
        break;
    }
  }

  getMapMarkerColorsbyHealth(health: string | number) {
    switch (true) {
      case health <= 59:
        return 'assets/media/netop/map-marker-low.svg';
      case health >= 60 && health <= 90:
        return 'assets/media/netop/map-marker-medium.svg';
      case health >= 90:
        return 'assets/media/netop/map-marker-high.svg';
      default:
        break;
    }
  }


  getDotColor(health: number) {
    let healthPercentage: number = health;
    switch (true) {
      case healthPercentage <= 59 && healthPercentage >= 0:
        return '/assets/media/netop/topology/health-dot-error.svg';
      case healthPercentage >= 60 && healthPercentage < 90:
        return '/assets/media/netop/topology/health-dot-warning.svg';
      case healthPercentage >= 90:
        return '/assets/media/netop/topology/health-dot-ok.svg';
      case healthPercentage == -1:
        return '/assets/media/netop/topology/health-dot-undefined.svg';
      default:
        break;
    }
  }

  getNodeDotColor(health: number) {
    let healthPercentage: number = health;
    switch (true) {
      case healthPercentage <= 59 && healthPercentage >= 0:
        return this.SEVERITY_COLORS[0];
      case healthPercentage >= 60 && healthPercentage < 90:
        return this.SEVERITY_COLORS[1];
        ;
      case healthPercentage >= 90:
        return this.SEVERITY_COLORS[2];
      case healthPercentage == -1:
        return this.SEVERITY_COLORS[3];
      default:
        break;
    }
  }

  getNodeStartLinkDotColor(children: SingleD3Node<GenericDevice<any>>[]) {
    let totalChildrenHealth: number = 0;
    children.forEach(child => totalChildrenHealth += child.data.originalData.health)
    let healthPercentage: number = totalChildrenHealth / children.length;
    switch (true) {
      case healthPercentage <= 59 && healthPercentage >= 0:
        return this.SEVERITY_COLORS[0];
      case healthPercentage >= 60 && healthPercentage < 90:
        return this.SEVERITY_COLORS[1];
        ;
      case healthPercentage >= 90:
        return this.SEVERITY_COLORS[2];
      case healthPercentage == -1:
        return this.SEVERITY_COLORS[3];
      default:
        break;
    }
  }

  getLinkStatusColors(d: any) {
    //let LinkStatus = d['data'].status;
    let LinkStatus = d;
    switch (LinkStatus) {
      case SingleLinkStatus.Error:
        return this.SEVERITY_COLORS[0];
      case SingleLinkStatus.Warning:
        return this.SEVERITY_COLORS[1];
      case SingleLinkStatus.OK:
        return this.SEVERITY_COLORS[2];
      default:
        return this.SEVERITY_COLORS[2];
    }
  }

  getRSSIColors(property: { key: string, value: string | number | boolean }) {
    let value = property.value ? property.value.toString() : "1";
    if (value.includes("db")) {
      let rssi = parseInt(value.substring(0, value.indexOf("db")));
      switch (true) {
        case rssi >= -80 && rssi <= 0:
          return this.SEVERITY_COLORS[2];
        case rssi <= -80 && rssi >= -95:
          return this.SEVERITY_COLORS[1];
        case rssi <= -95 && rssi >= -130:
          return this.SEVERITY_COLORS[0];
        default:
          break;
      }
    }
  }

  getClientDotColor(color: RssiLabels) {
    switch (color) {
      case color = RssiLabels.RED:
        return this.SEVERITY_COLORS[0];
      case color = RssiLabels.YELLOW:
        return this.SEVERITY_COLORS[1];
      case color = RssiLabels.GREEN:
        return this.SEVERITY_COLORS[2];
      default:
        break;
    }
  }

  getColorByDeviceStatus(status: DeviceState) {
    switch (status.toLowerCase()) {
      case DeviceState.Online:
        return this.SEVERITY_COLORS[2];
      case DeviceState.Alerting:
        return this.SEVERITY_COLORS[1];
      case DeviceState.Offline:
        return this.SEVERITY_COLORS[0];
      case DeviceState.Unknown:
        return this.SEVERITY_COLORS[3];
      case DeviceState.Fake:
        return '#ffffff';
      default:
        break;
    }
  }

  getColorByConnectionStatus(status: VPNConnectionStatus) {
    switch (status.toLowerCase()) {
      case VPNConnectionStatus.REACHABLE:
        return this.SEVERITY_COLORS[2];
      case VPNConnectionStatus.UNKNOWN:
        return this.SEVERITY_COLORS[1];
      case VPNConnectionStatus.UNREACHABLE:
        return this.SEVERITY_COLORS[0];
      default:
        break;
    }
  }
}
