import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {User} from '../../models/users.model';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {IAppState} from 'src/app/store/state/app.state';
import {GlobalEntitiesService} from './global-entities.service';
import {RoleAccess} from '../../models/role-access.model';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends GlobalEntitiesService {

  constructor(private http: HttpClient, store: Store<IAppState>) {
    super(store)
  }

  fetchUserById(userId: number): Observable<User> {
    return this.http.get<User>(`users/${userId}`);
  }

  fetchCurrentUser(): Observable<User> {
    return this.http.get<User>(`users/`);
  }

  fetchUserByEmail(email: string): Observable<User> {
    return this.http.get<User>(`users/getByEmail/${email}`);
  }

  updateUser(updatedUser: User): Observable<any> {
    return this.http.put(`users/`, updatedUser);
  }

  deleteUser(userId: number): Observable<any> {
    return this.http.delete(`users/${userId}`);
  }

  fetchDefaultAccess(): Observable<RoleAccess> {
    return this.http.get<RoleAccess>(`globalInfo/access`)
  }

  authenticateUser(): Promise<User> {
    return this.http.post<User>('users/authenticate', {}).toPromise();
  }

  logout(): Observable<any> {
    return this.http.post<any>(`logout`, {});
  }
}
