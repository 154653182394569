import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentDate'
})
export class MomentDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value > 0) {
      const date = new Date(value);
      return moment(date).format('DD-MMM-YYYY HH:mm');
    }
    return null;
  }

}
