import {Component, OnInit, Output, EventEmitter, Input, ChangeDetectionStrategy} from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';
import {getYesterdayDate} from '../../operators/time-operator';
import {NgChanges} from '../../extend-angular-classes/on-changes';
import {FromToDates} from "../../models/time.model";

export enum FromTo {
  From,
  To
}

@Component({
  selector: 'app-from-to-date-picker',
  templateUrl: './from-to-date-picker.component.html',
  styleUrls: ['./from-to-date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FromToDatePickerComponent {
  @Input() daysBetweenDatesLimits: number = 30
  @Input() maxDateLimit: Date;
  @Input() fromDate: Date = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
  @Input() toDate: Date = getYesterdayDate();
  @Output() selectedTime: EventEmitter<FromToDates> = new EventEmitter();
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  isPickerOpen: boolean = false;

  constructor() {
  }

  ngOnChanges(changes: NgChanges<FromToDatePickerComponent>) {
    if (this.fromDate && this.toDate) {
      this.range.get('start').setValue(this.fromDate);
      this.range.get('end').setValue(this.toDate);
    }
  }

  onPickerClosed() {
    this.isPickerOpen = false;
    this.onTimePickerChanged();
  }

  onInputDateChanged(event) {
    if (!this.isPickerOpen) {
      this.onTimePickerChanged();
    }
  }

  /**
   * If both dates are selected, allow emittion
   */
  onTimePickerChanged() {
    if (this.range.controls.start.valid && this.range.controls.end.valid && this.range.value && this.range.value.start && this.range.value.end) {
      this.selectedTime.emit({start: this.range.value.start, end: this.range.value.end});
    }
  }

  /**
   * Return the max date available for user.
   * If start date was selected, the user will be allowed to select date that it is
   * 30 days later, only if its not later than maxDateLimit
   * If start date wasn't selected, the user will be able to select dates until maxDateLimit
   */
  get maxDate(): Date {
    if (this.range && this.range.value && this.range.value.start) {
      let fromDate: Date = new Date(this.range.value.start);
      let fromDatePlusLimit = new Date(fromDate.setDate(fromDate.getDate() + this.daysBetweenDatesLimits));
      return fromDatePlusLimit.getTime() > this.maxDateLimit.getTime() ?
        this.maxDateLimit :
        fromDatePlusLimit
    }
    return this.maxDateLimit;
  }

}
