import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map, mergeMap, switchMap, take } from "rxjs/operators";
import { IAppState } from "src/app/store/state/app.state";
import { TimeSeriesWrapper, AnomalyInsightsTrend } from "../../models/actions.model";
import { AnomalySummaryGrouping, AnomalySummary, AnomalySummaryByEntity, AnomalySummaryByCategory, AnomalyCategory, DeviceIssuesAnomaly, TrendAnomaly, VPNAnomaly, AnomalySettingsDTO } from "../../models/anomalies.model";
import { EntityType } from "../../models/entity-type.enum";
import { Entity } from "../../models/entity.model";
import { Page } from "../../models/page.model";
import { BaseSortFilter } from "../../models/sort-filter/base-sort-filter.model";
import { FromToDatesStrings, TimeUnit } from "../../models/time.model";
import { GlobalEntitiesService } from "./global-entities.service";


@Injectable({
  providedIn: 'root'
})
export class InsightsService extends GlobalEntitiesService {

  constructor(private http: HttpClient, store: Store<IAppState>) {
    super(store)
  }

  getAnomalySummaries(entityId: number, entityType: EntityType, groupBy: AnomalySummaryGrouping, timeBack: number, timeUnit: TimeUnit, specificDate: Date = new Date()): Observable<AnomalySummary[]> {
    return this.http.get<AnomalySummary[]>(`insights/count/${entityType}/${entityId}?groupBy=${groupBy}&timeBack=${timeBack}&timeUnit=${timeUnit}&specificDate=${specificDate?.toISOString()}`);
  }

  // DEPRECATED
  deprecatedGetAnomaliesSummary(dates: FromToDatesStrings): Observable<AnomalySummaryByEntity> {
    return this.currentEntity$.pipe(
      take(1),
      mergeMap((entity) => this.deprecatedGetAnomaliesSummaryByEntity(dates, entity?.id, entity?.type)),
    )
  }

  // DEPRECATED
  deprecatedGetAnomaliesSummaryByEntity(dates: FromToDatesStrings, entityId: number, entityType: EntityType): Observable<AnomalySummaryByEntity> {
    return this.http.get<AnomalySummaryByCategory[]>(`insights/anomalies/${entityId}/${entityType}/summary/?startDate=${dates.start}&endDate=${dates.end}`).pipe(
      map((anomalySummary) => ({entity: {id: entityId, type: entityType}, anomalySummary: anomalySummary}))
    )
  }

  getAnomaliesListByCategory(entity: Entity, category: AnomalyCategory, sortAndFilter: BaseSortFilter, dates: FromToDatesStrings): Observable<Page<DeviceIssuesAnomaly | TrendAnomaly | VPNAnomaly>> {
    if (entity) {
      return this.http.get<Page<DeviceIssuesAnomaly | TrendAnomaly | VPNAnomaly>>((sortAndFilter.appendToURL(`insights/anomalies/${entity.type}/${entity.id}/${category}`) + `&startDate=${dates.start}&endDate=${dates.end}`))
    }
  }

  getAnomaliesBehindTimeRange(category: AnomalyCategory, maxTimeBack): Observable<number> {
    return this.currentEntity$.pipe(
      take(1),
      switchMap((entity) =>
        this.http.get<number>(`insights/anomalies/${entity.type}/${entity.id}/${category}/${maxTimeBack}`))
    )
  }

  getTenantAnomalySettings(currentEntity: Entity, anomalyCategory: AnomalyCategory): Observable<AnomalySettingsDTO[]> {
    if (currentEntity.type == EntityType.TENANT) {
      return this.http.get<AnomalySettingsDTO[]>(`insights/anomaly/settings/${currentEntity.type.toLowerCase()}/${currentEntity.id}/${anomalyCategory}`)
    }
    return null;
  }

  updateTenantAnomalySettings(currentEntity: Entity, anomalySettings: AnomalySettingsDTO): Observable<any> {
    if (currentEntity.type == EntityType.TENANT) {
      return this.http.put<any>(`insights/anomaly/settings/${currentEntity.type}/${currentEntity.id}`, anomalySettings)
    }
    return null;
  }

  updateMultiTenantsAnomalySettings(currentEntity: Entity, anomalySettings: AnomalySettingsDTO, tenantsId: number[]): Observable<any> {
    if (currentEntity.type == EntityType.ORGANIZATION) {
      let body = {
        anomalySettings: anomalySettings,
        tenantIds: tenantsId
      }
      return this.http.put<any>(`insights/anomaly/settings/tenants`, body)
    }
    return null;
  }

  getInsightsAnomaliesTrend(dates: FromToDatesStrings): Observable<TimeSeriesWrapper<AnomalyInsightsTrend>> {
    return this.currentEntity$.pipe(
      take(1),
      switchMap((entity) => this.http.get<TimeSeriesWrapper<AnomalyInsightsTrend>>(`insights/anomalies/${entity.type}/${entity.id}/trend/?startDate=${dates.start}&endDate=${dates.end}`))
    )
  }
}
