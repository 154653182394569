import {Component, OnInit, ChangeDetectorRef, Input, Inject} from '@angular/core';
import {ReportsStoreService} from 'src/app/shared/pages/reports/services/reports-store.service';
import {TimeManagerService} from 'src/app/shared/services/time-manager.service';
import {TranslateService} from '@ngx-translate/core';
import {LoggerService, Logger} from 'src/app/shared/services/logger.service';
import {EntityType} from 'src/app/shared/models/entity-type.enum';
import {LossLatencyService} from 'src/app/shared/services/rest-services/loss-latency.service';
import {VerticalBarData} from 'src/app/shared/components/chartjs-components/models/vertical-bar-config';
import {take} from 'rxjs/operators';
import {LossLatencyBarGeneratorService} from './services/loss-latency-bar-generator.service';
import {OpenDialogService} from 'src/app/shared/services/open-dialog.service';
import {LOSS_LATENCY_THRESHOLDS, LossLatencyThreshold} from 'src/app/shared/global-utils/loss-latency-threshold';
import {BaseReportsBarWidgets} from 'src/app/shared/pages/reports/models/base-reports-bar-widgets';

@Component({
  selector: 'app-loss-latency-bar',
  templateUrl: './loss-latency-bar.component.html',
  styleUrls: ['./loss-latency-bar.component.scss']
})
export class LossLatencyBarComponent extends BaseReportsBarWidgets {
  barTitle: string;
  verticalBarDatasets: VerticalBarData;
  verticalBarHeight: number = 300;
  verticalBarWidth: number = 130;
  private logger: Logger;

  constructor(
    protected reportsStoreService: ReportsStoreService,
    protected dateConvertor: TimeManagerService,
    protected translate: TranslateService,
    protected cdr: ChangeDetectorRef,
    protected openDialogService: OpenDialogService,
    private lossLatencyService: LossLatencyService,
    protected barGenerator: LossLatencyBarGeneratorService,
    @Inject(LOSS_LATENCY_THRESHOLDS) readonly THRESHOLDS: LossLatencyThreshold,
    private loggerFactory: LoggerService) {
    super(reportsStoreService, dateConvertor, barGenerator, translate, openDialogService, cdr)
    this.logger = this.loggerFactory.getLogger("LossLatencyBarComponent");
  }

  loadData() {
    if (this.datesAsString && this.currentEntity && (this.currentEntity.type == EntityType.VENUE || this.currentEntity.type == EntityType.TENANT)) {
      this.barGenerator.THRESHOLDS = this.THRESHOLDS;
      this.lossLatencyService.getLossAndLatency(this.currentEntity, this.datesAsString.start, this.datesAsString.end, this.kpiType).pipe(take(1)).subscribe(deviationData => {
        let deviationDataByDates = this.barGenerator.adujstResultToDates(deviationData, this.datesAsString);
        this.verticalBarDatasets = this.barGenerator.generateVerticalDatasets(deviationDataByDates, this.kpiType, this.datesAsString);
        this.logger.debug("verticalBarDatasets", this.verticalBarDatasets);
        this.barTitle = this.kpiType;
      })
    }
  }

  ngOnDestroy() {
    this.destroyProcedure();
  }

}
