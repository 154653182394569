import {Injectable} from '@angular/core';
import {
  LegacyClient,
  ClientQueriesParam,
  ClientDevice,
  ClientTrafficSummary,
  FailedConnection, Client
} from '../../models/clients.model';
import {GlobalEntitiesService} from './global-entities.service';
import {IAppState} from 'src/app/store/state/app.state';
import {Store} from '@ngrx/store';
import {Observable, BehaviorSubject} from 'rxjs';
import {take, mergeMap, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Page} from '../../models/page.model';
import {TimeApiFields, TimeUnit} from "../../models/time.model";
import {BaseSortFilter} from "../../models/sort-filter/base-sort-filter.model";

@Injectable({
  providedIn: 'root'
})
export class ClientsService extends GlobalEntitiesService {
  private notifySearchClientQueries: BehaviorSubject<ClientQueriesParam> = new BehaviorSubject(null);
  notifySearchClientQueriesAsObservable$ = this.notifySearchClientQueries.asObservable();

  constructor(private http: HttpClient, store: Store<IAppState>) {
    super(store)
  }

  getClients(venueId: number,
             timeBack: number,
             timeUnit: TimeUnit,
             specificDate: Date,
             params: BaseSortFilter,
             search: string = ''
  ): Observable<Page<Client>> {
    return this.http.get<Page<Client>>(params.appendToURL(`venues/${venueId}/clients-traffic?timeBack=${timeBack}&timeUnit=${timeUnit}&specificDate=${specificDate.toISOString()}&search=${search}`));
  }

  // DEPRECATED
  getVenueClientsHistory(search: string, pageNo: number, pageSize: number, params): Observable<Page<LegacyClient>> {
    return this.currentVenue$.pipe(
      take(1),
      mergeMap((venue) => this.http.get<Page<LegacyClient>>(
        `venues/${venue.id}/clients-summary/?search=${search}&pageNo=${pageNo}&pageSize=${pageSize}&${TimeApiFields.TIME_BACK}=${params[TimeApiFields.TIME_BACK]}&${TimeApiFields.TIME_UNIT}=${params[TimeApiFields.TIME_UNIT]}&${TimeApiFields.SPECIFIC_DATE}=${params[TimeApiFields.SPECIFIC_DATE]}`
      )),
      map(clients => {
        clients.data.forEach(client => this.formatClientName(client));
        return clients;
      }));
  }

  getClientsTrafficHistory(search: string, pageNo: number, pageSize: number, params): Observable<Page<ClientTrafficSummary>> {
    return this.currentVenue$.pipe(
      take(1),
      mergeMap((venue) => this.http.get<Page<ClientTrafficSummary>>(
        `venues/${venue.id}/clients/traffic-summary/?search=${search}&pageNo=${pageNo}&pageSize=${pageSize}&${TimeApiFields.TIME_BACK}=${params[TimeApiFields.TIME_BACK]}&${TimeApiFields.TIME_UNIT}=${params[TimeApiFields.TIME_UNIT]}&${TimeApiFields.SPECIFIC_DATE}=${params[TimeApiFields.SPECIFIC_DATE]}&sortBy=${params['sortBy']}&sortDir=${params['sortDir']}`
      ))
    );
  }

  getConnectedVenueClients(venueId: number): Observable<ClientDevice[]> {
    return this.http.get<ClientDevice[]>(`venues/${venueId}/connected-clients/`);
  }

  getCurrentConnectedVenueClients(): Observable<ClientDevice[]> {
    return this.currentVenue$.pipe(
      take(1),
      mergeMap(venue => this.getConnectedVenueClients(venue.id)));
  }

  getVenueFailedClientConnections(venueId: number): Observable<Page<FailedConnection>> {
    return this.http.get<Page<FailedConnection>>(`venues/${venueId}/failed-clients/`);
  }

  getCurrentVenueFailedClientConnections(): Observable<Page<FailedConnection>> {
    return this.currentVenue$.pipe(
      take(1),
      mergeMap(venue => this.getVenueFailedClientConnections(venue.id)));
  }

  setQueriesForClientGrid(searchClientQueries: ClientQueriesParam) {
    this.notifySearchClientQueries.next(searchClientQueries);
  }

  private formatClientName(client) {
    const originalClientName = client.clientName;
    if (client.clientName.trim() === ',') {
      client.clientName = client.mac;
      return;
    }
    if (originalClientName.split(',').length > 1) {
      client.clientName = originalClientName.split(',')[0] === ',' ? originalClientName.split(',')[1] : originalClientName.split(',')[0];
    }
  }
}
