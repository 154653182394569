import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {IAppState} from 'src/app/store/state/app.state';
import {Store} from '@ngrx/store';
import {ClientStorageEntities, ClientOrgPreferencesData} from '../../../models/client-storage.model';
import {LoggerService} from '../../logger.service';

import {HierarchyService} from '../../rest-services/hierarchy.service';

import {UpdateEntitySiblingsPreferencesService} from './update-entity-siblings-preferences.service';
import {SetEntityPreferencesByParentService} from './set-entity-preferences-by-parent.service';

import {BaseFirebaseByOrganizations} from './base-firebase-by-organization';
import {removeNullPropertiesFromObject} from '../../../operators/object-operators/null-checks';
import {LoadClientStorageService, NETOP_DEFAULT_ORG_PREFERENCES} from "../load-client-storage.service";


@Injectable({
  providedIn: 'root'
})
export class FirebaseOrgPreferencesService extends BaseFirebaseByOrganizations {


  constructor(db: AngularFireDatabase,
              protected store: Store<IAppState>,
              protected hierarchyService: HierarchyService,
              protected updateEntitySiblingsPreferencesService: UpdateEntitySiblingsPreferencesService,
              protected setEntityPreferencesByParentService: SetEntityPreferencesByParentService,
              protected loggerFactory: LoggerService,
              protected loadClientStorageService: LoadClientStorageService,
  ) {
    super(store, hierarchyService, updateEntitySiblingsPreferencesService, setEntityPreferencesByParentService, loggerFactory, loadClientStorageService)
    this.setDefaultServiceValues(ClientStorageEntities.ORG_PREFERENCES, "FirebaseOrgPreferencesService", {...NETOP_DEFAULT_ORG_PREFERENCES}, db)
  }


  /**
   * Set Default values and selecte values for both branding objects
   */
  applyDefaultAndSelectedPreferences(firebaseCurrentValue: ClientOrgPreferencesData<any>) {
    this.loadClientStorageService.storeOrgPreferences(this.firebaseSelection);
    this.loadClientStorageService.storeOrgBrandingLoaded(this.firebaseSelection || false);
  }

  preparePreferencesForFirebase(data: any) {
    let dataWithoutNulls = removeNullPropertiesFromObject(data);
    for (let [key, value] of Object.entries(dataWithoutNulls)) {
      this.firebaseSelection.preferences.data[key] = value;
    }
    this.firebaseSelection.preferences.isOwnPreferences = true;
    this.updateOrgPreferencesSelection();
  }
}
