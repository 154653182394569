import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-tooltip-as-cell-renderer',
  templateUrl: './tooltip-as-cell-renderer.component.html',
  styleUrls: ['./tooltip-as-cell-renderer.component.scss']
})
export class TooltipAsCellRendererComponent implements OnInit, ICellRendererAngularComp {
  markerTooltipData: string;
  mainData: string;
  refresh(params: any): boolean {
    throw new Error("Method not implemented.");
  }
  agInit(params: import("ag-grid-community").ICellRendererParams): void {
    if (params) {
      this.markerTooltipData = params.value;
      this.mainData = params.value;
    }
  }
  afterGuiAttached?(params?: import("ag-grid-community").IAfterGuiAttachedParams): void {
  }

  constructor() { }

  ngOnInit() {
  }

}
