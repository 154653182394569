import {Component, ViewChild} from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {PolicyDisplay} from "../../../models/open-ports.model";
import {MatMenuTrigger} from "@angular/material/menu";

@Component({
  selector: 'app-policy-cell-renderer',
  templateUrl: './policy-cell-renderer.component.html',
  styleUrls: ['./policy-cell-renderer.component.scss']
})
export class PolicyCellRendererComponent implements ICellRendererAngularComp {
  policies: PolicyDisplay[];
  gridOverride: string = 'global-policy-row-in-grid';
  tooltipOverride: string = 'global-policy-row-in-tooltip';
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  agInit(params: ICellRendererParams): void {
    if (params && params.getValue() && params.getValue().length > 0) {
      this.policies = params.getValue();
    }
  }

  refresh(params: any): boolean {
    return false;
  }
  closeMenu() {
    this.trigger.closeMenu();
  }

  get policyRulesAsText() {
    return this.policies && this.policies.length > 0 ?
      this.policies.map(policy => policy.rules.map(rule => rule.toPlainText).toString()) :
      ''
  }
}
