import { Injectable } from '@angular/core';
import * as log from 'loglevel';
import { LoggerConfig } from './logger-config'
import { DeviceDetectorService } from 'ngx-device-detector';
import { RemoteLogger } from './remote-logger';
import { environment } from 'src/environments/environment';
export type Logger = log.Logger;

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private remoteLoggers: any;
  // For now just using the logLevel own loggers separation
  private colors: { [name: string]: string };
  private nextColorIdx = 0;
  colorStep: number = 0;
  /**
   * @param logConfig the configuration object for the logger
   * @params deviceService an object for browser detection
   */
  constructor(private logConfig: LoggerConfig,
    private deviceService: DeviceDetectorService) {
    this.colors = {};
    this.remoteLoggers = [];
    // console.log("log config %o", logConfig)
    var originalFactory = log.methodFactory;
    // suppress typescript error
    let s = log;
    let that = this;
    s.methodFactory = (methodName, logLevel, loggerName) => {
      var rawMethod = originalFactory(methodName, logLevel, loggerName);
      let colors = this.colors;
      return function () {
        //debugger;
        let deviceInfo = deviceService.getDeviceInfo();

        if (methodName.toUpperCase() == "ERROR") {
          // debugger
          let remoteMessages: any[] = []
          let loggerMessage = `${loggerName.toString()} : ${arguments[0]}`;
          for (var i = 1; i < arguments.length; i++) {
            remoteMessages.push(arguments[i]);
          }

          Object.values(that.remoteLoggers).forEach((rl) => (rl as RemoteLogger).logError(loggerMessage, remoteMessages))
          //console.trace
        }
        let loggerMessage = `${loggerName.toString()} : `;
        let needsColor = false;
        if (that.logConfig.enableColors &&
          methodName.toUpperCase() == "DEBUG" &&
          that.colors[loggerName.toString()] &&
          (deviceInfo.browser == "Chrome" ||
            deviceInfo.browser == "Firefox")) {
          needsColor = true;
          loggerMessage = `%c [${loggerName.toString()}] : `;
        }

        let messages: string[] = [loggerMessage];
        if (needsColor) {
          messages.push(`color: ${that.colors[loggerName.toString()]}`);
        }

        for (var i = 0; i < arguments.length; i++) {
          messages.push(arguments[i]);
        }
        // remotely log errors
        //rawMethod.apply(undefined, messages);
      };
    };
    log.setLevel(log.getLevel()); // Be sure to call setLevel method in order to apply plugin
  }
  /**
   * Allows remote logger to add itself to the logger service
   * The remote logger will be used only if it is listed in environment.remoteLoggers
   * (an separated by comma list of remote loggers)
   * @param remoteLoggerToken The name of remote logger
   * @param remoteLogger remote logger object
   */
  addRemoteLogger(remoteLoggerToken: string, remoteLogger: RemoteLogger) {
    let remoteLoggers: string[] = environment.remoteLoggers.split(",");
    console.log("remote loggers configured %o", remoteLoggers)
    if (remoteLoggers.indexOf(remoteLoggerToken) != -1) {
      // Check if the remote logger is configured
      console.log("adding remote logger %o", remoteLoggerToken)
      this.remoteLoggers[remoteLoggerToken] = remoteLogger
    }
  }
  /**
   * Get a logger
   * @param loggerName The logger name, should be the component/pipe/directive name
   */
  getLogger(loggerName: string) {
    let logger = log.getLogger(loggerName);
    let levels = this.logConfig.levels;
    if (!levels) {
      levels = {};
    }
    let level = loggerName in levels ?
      levels[loggerName] : this.logConfig.defaultLevel
    logger.setLevel(level)
    if (!this.colors[loggerName]) {
      this.computeStep()
      this.colors[loggerName] = possibleColors[this.nextColorIdx];
      this.nextColorIdx = (this.colorStep + this.nextColorIdx) % possibleColors.length;
      // console.log(`the color index ${this.nextColorIdx} the color step ${this.colorStep}` +
      //   ` the color name ${this.colors[loggerName]} ` +
      //   ` the logger name ${loggerName}` +
      //   ` the log level ${level}`)
    }
    return logger;
  }
  private computeStep(): void {
    if (this.colorStep) {
      return;
    }
    // any coprime number to possibleColors.length will do
    const primes = [11, 17, 19];
    this.colorStep = primes.find((prime) => { return possibleColors.length % prime != 0 })
  }
}

// taken from debug log project, commented out dark colors
let possibleColors = [
  // '#0000CC',
  // '#0000FF',
  // '#0033CC',
  // '#0033FF',
  // '#0066CC',
  // '#0066FF',
  // '#0099CC',
  // '#0099FF',
  // '#00CC00',
  // '#00CC33',
  // '#00CC66',
  // '#00CC99',
  // '#00CCCC',
  // '#00CCFF',
  // '#3300CC',
  // '#3300FF',
  // '#3333CC',
  // '#3333FF',
  // '#3366CC',
  '#3366FF',
  '#3399CC',
  '#3399FF',
  '#33CC00',
  '#33CC33',
  '#33CC66',
  '#33CC99',
  '#33CCCC',
  '#33CCFF',
  // '#6600CC',
  // '#6600FF',
  '#6633CC',
  '#6633FF',
  '#66CC00',
  '#66CC33',
  '#9900CC',
  '#9900FF',
  '#9933CC',
  '#9933FF',
  '#99CC00',
  '#99CC33',
  // '#CC0000',
  // '#CC0033',
  // '#CC0066',
  // '#CC0099',
  // '#CC00CC',
  // '#CC00FF',
  '#CC3300',
  '#CC3333',
  '#CC3366',
  '#CC3399',
  '#CC33CC',
  '#CC33FF',
  '#CC6600',
  '#CC6633',
  '#CC9900',
  '#CC9933',
  '#CCCC00',
  '#CCCC33',
  // '#FF0000',
  // '#FF0033',
  // '#FF0066',
  // '#FF0099',
  '#FF00CC',
  '#FF00FF',
  '#FF3300',
  '#FF3333',
  '#FF3366',
  '#FF3399',
  '#FF33CC',
  '#FF33FF',
  '#FF6600',
  '#FF6633',
  '#FF9900',
  '#FF9933',
  '#FFCC00',
  '#FFCC33'
];
