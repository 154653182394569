import * as moment from 'moment';
export class ActionsTrend {
    total: Array<{
        datetime: moment.Moment;
        value: number;
    }>;
    closed: Array<{ datetime: moment.Moment, value: number }>;
}
;
export class HealthTrend {
    total: Array<{ datetime: moment.Moment, value: number }>;
    unhealthy: Array<{ datetime: moment.Moment, value: number }>;
}

export class HealthStatus {
    total: number;
    high: number;
    medium: number;
}

export class HealthChangeStatus extends HealthStatus {
    change: string
}

export enum HealthIndicator {
    MOST_SEVERE = "MOST_SEVERE"
}