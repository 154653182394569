import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {DynamicWordingsComponent} from "./dynamic-wordings.component";
import {CommonModule} from "@angular/common";
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatMenuModule} from "@angular/material/menu";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {DYNAMIC_WORDING_CHARACTERS, specialCharacters} from "./models/rules.model";

@NgModule({
  declarations: [DynamicWordingsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule
  ],
  exports: [DynamicWordingsComponent],
  providers: [
    {
      provide: DYNAMIC_WORDING_CHARACTERS,
      useValue: specialCharacters
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DynamicWordingsModule {
}
