import {Directive, Input, ViewContainerRef} from '@angular/core';
import {TimeUnit, TimeUnitFormat} from '../models/time.model';

@Directive({
  selector: '[timeBackAgo]'
})
export class TimeBackAgoDirective {

  constructor(private viewContainer: ViewContainerRef) {}

  @Input('timeBack') timeBack: number;
  @Input('timeUnit') timeUnit: TimeUnit = TimeUnit.HOURS;

  @Input('timeBackSingle') timeBackSingle = '';
  @Input('singleHour') singleHour: string = '';
  @Input('singleMinute') singleMinute: string = '';
  @Input('singleSecond') singleSecond: string = '';
  @Input('singleMillisecond') singleMilli: string = '';

  @Input('timeUnitFormat') timeUnitFormat: TimeUnitFormat = 'lower';

  innerText: string = '';
  timeUnitText: string = '';

  ngAfterViewInit() {
    if (this.timeBack) {
      this.generateInnerText();
      this.viewContainer.element.nativeElement.innerText = this.innerText;
    }
  }

  private generateInnerText() {
    this.innerText = this.viewContainer.element.nativeElement.innerText;
    if (!this.innerText.replace(' ', ''))
      this.innerText = 'last [timeBack] [timeUnit]';

    this.handleTimeBack();
    this.handleTimeUnit();
    this.innerText = this.innerText.replace(/  +/g, ' ').trim();

    this.innerText = this.innerText ?? '';
  }

  private handleTimeBack() {
    this.setTimeBackSingle();

    this.innerText = this.timeBack > 1
      ? this.innerText.replace('[timeBack]', this.timeBack.toLocaleString())
      : !this.timeBackSingle.includes('[timeUnit]')
        ? this.innerText.replace('[timeBack]', this.timeBackSingle)
        : this.timeBackSingle.replace('[timeBack]', this.timeBack.toLocaleString());
  }

  private setTimeBackSingle() {
    switch (this.timeUnit) {
      case TimeUnit.HOURS:
        this.timeBackSingle = !!this.singleHour ? this.singleHour : this.timeBackSingle;
        break;
      case TimeUnit.MINUTES:
        this.timeBackSingle = !!this.singleMinute ? this.singleMinute : this.timeBackSingle;
        break;
      case TimeUnit.SECONDS:
        this.timeBackSingle = !!this.singleSecond ? this.singleSecond : this.timeBackSingle;
        break;
      case TimeUnit.MILLIS:
        this.timeBackSingle = !!this.singleMilli ? this.singleMilli : this.timeBackSingle;
        break;
      default:
        break;
    }

    this.timeBackSingle = this.timeBackSingle ?? '';
  }

  private handleTimeUnit() {
    this.setTimeUnitText();
    this.innerText = this.innerText.replace('[timeUnit]', this.timeUnitText);
  }

  private setTimeUnitText() {
    this.timeUnitText = this.timeBack > 1 ? this.timeUnit.toString() : this.timeUnit.toString().replace(/S$/g, '');

    switch (this.timeUnitFormat) {
      case 'capital':
        this.timeUnitText = this.timeUnitText.toLowerCase();
        this.timeUnitText = this.timeUnitText.replace(/^[a-z]/g, this.timeUnitText[0].toUpperCase());
        break;
      case 'upper':
        this.timeUnitText = this.timeUnitText.toUpperCase();
        break;
      case 'lower':
        this.timeUnitText = this.timeUnitText.toLowerCase();
        break;
      default:
        this.timeUnitText = this.timeUnitText.toLowerCase();
        break;
    }
  }
}
