import {Injectable, Injector} from '@angular/core';
import {Resolve} from '@angular/router';
import {FirebaseOrgPreferencesService} from '../services/client-storage-services/firebase-services/firebase-org-preferences.service';
import {FirebaseDynamicDashboardService} from '../services/client-storage-services/firebase-services/firebase-dynamic-dashboard.service';
import {LocalStorageDynamicDashboardService} from "../services/client-storage-services/local-storage-services/local-storage-dynamic-dashboard.service";
import {LocalStorageOrgPreferencesService} from "../services/client-storage-services/local-storage-services/local-storage-org-preferences.service";
import {ClientStorageDatasource} from "../models/client-storage.model";
import {Auth0Service} from "../services/auth0.service";
import {FirebaseUserPreferencesService} from "../services/client-storage-services/firebase-services/firebase-user-preferences.service";
import {LocalStorageUserPreferencesService} from "../services/client-storage-services/local-storage-services/local-storage-user-preferences.service";
import {EnvironmentType} from "../../../environments/environment.model";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ClientStorageResolver implements Resolve<boolean> {
  private readonly environmentType: EnvironmentType = environment.environmentType;

  constructor(
    private injector: Injector) {
  }

  resolve(route: import("@angular/router").ActivatedRouteSnapshot, state: import("@angular/router").RouterStateSnapshot) {
    const datasource = EnvironmentType.isOnPrem(this.environmentType) ? ClientStorageDatasource.LOCAL_STORAGE : ClientStorageDatasource.FIREBASE;
    switch (datasource) {
      case ClientStorageDatasource.LOCAL_STORAGE: {
        const localStorageDynamicDashboardService: LocalStorageDynamicDashboardService = this.injector.get(LocalStorageDynamicDashboardService);
        const localStorageOrgPreferencesService: LocalStorageOrgPreferencesService = this.injector.get(LocalStorageOrgPreferencesService);
        const localStorageUserPreferencesService: LocalStorageUserPreferencesService = this.injector.get(LocalStorageUserPreferencesService);
        localStorageDynamicDashboardService.getHighLevelOrgPreferences();
        localStorageOrgPreferencesService.getHighLevelOrgPreferences();
        localStorageUserPreferencesService.loadUserDetails();
        break;
      }
      case ClientStorageDatasource.FIREBASE: {
        const firebaseDynamicDashboardService: FirebaseDynamicDashboardService = this.injector.get(FirebaseDynamicDashboardService);
        const firebaseOrgPreferencesService: FirebaseOrgPreferencesService = this.injector.get(FirebaseOrgPreferencesService);
        const firebaseUserPreferencesService: FirebaseUserPreferencesService = this.injector.get(FirebaseUserPreferencesService);
        firebaseDynamicDashboardService.getHighLevelOrgPreferences();
        firebaseOrgPreferencesService.getHighLevelOrgPreferences();
        firebaseUserPreferencesService.loadUserDetails();

        break;
      }
    }
    return true;
  }
}
