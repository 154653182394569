<div class="portlet-header text-center mb-2">{{donutConfig?.title}}</div>
<app-graph-donut
  [ngClass]="centerNoDataText && (donutConfig == null || donutConfig.datasets == null || donutConfig.datasets.length == 0)
  ? 'graph-donut-container center-no-data-text'
  : 'graph-donut-container'"
  [graphConfig]="donutConfig"
  [width]="width"
  [height]="height"
  [showSpinner]="isLoading"
  [isLabelsDisplay]="isLabelsDisplay"
  [isResponsive]="isResponsive"
  [maintainAspectRatio]="maintainAspectRatio"
  [noDataText]="!isLoading && donutConfig?.datasets?.length == 0 ? noDataText : null"></app-graph-donut>
