import { Component, OnInit } from '@angular/core';
import { INoRowsOverlayAngularComp } from "ag-grid-angular";

const svgBasePath = 'assets/media/icons/svg/Navigation/';

@Component({
  selector: 'app-priority-cell-renderer',
  template: `<span [class]="priorityClass" [inlineSVG]="svgIcon"></span>`,
  styleUrls: ['./priority-cell-renderer.component.scss']
})
export class PriorityCellRenderer implements INoRowsOverlayAngularComp {

  svgIcon: string;
  priorityClass: string;

  agInit({ value }): void {

    switch (value) {
      case 'H':
        this.svgIcon = `${svgBasePath}Arrow-up.svg`;
        this.priorityClass = 'ag-high';
        break;
      case 'M':
        this.svgIcon = `${svgBasePath}Arrow-up.svg`;
        this.priorityClass = 'ag-medium';
        break;
      case 'L':
        this.svgIcon = `${svgBasePath}Arrow-down.svg`;
        this.priorityClass = 'ag-low';
        break;
      default:
        break;
    }
  }
}
