import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {MaterialModule} from './material.module';
import {NetworkTopologyModule} from './components/network-topology/network-topology.module';
import {InsightsAnomaliesGridModule} from './components/dynamic-dashboard/components/insights-anomalies/anomalies-grids/insights-anomalies-grid.module';
import {NetopDialogModule} from './modals/netop-dialog/netop-dialog.module';
import {ColumnBoxComponent} from './components/column-box/column-box.component';
import {ConfirmationModalComponent} from './modals/confirmation-modal/confirmation-modal.component';
import {TermsModalComponent} from './modals/terms-modal/terms-modal.component';
import {SnackBarComponent} from './modals/snack-bar/snack-bar.component';
import {MapComponent} from './components/map/map.component';
import {PagePlaceholderComponent} from './components/page-placeholder/page-placeholder.component';
import {TranslateModule} from '@ngx-translate/core';
import {AgGridTablesModule} from './ag-grid/ag-grid.module';
import {ClDeleteButtonComponent} from './ag-grid/cell-renderers/cl-delete-button/cl-delete-button.component';
import {InlineSVGModule} from 'ng-inline-svg';
import {BreadcrumbsComponent} from './components/breadcrumbs/breadcrumbs.component';
import {RouterModule} from '@angular/router';
import {PageSpinnerComponent} from './components/page-spinner/page-spinner.component';
import {SingleSelectListComponent} from './components/select-list/single-select-list/single-select-list.component';
import {MultiSelectListComponent} from './components/select-list/multi-select-list/multi-select-list.component';
import {LegacyPropertiesComponent} from './components/properties/legacy-properties.component';
import {PropertiesTitleComponent} from './components/properties/properties-title/properties-title.component';
import {PropertiesHeaderComponent} from './components/properties/properties-header/properties-header.component';
import {LegacyPropertiesContentComponent} from './components/properties/properties-content/legacy-properties-content.component';
import {SingleWidgetsBarComponent} from './components/single-widgets-bar/single-widgets-bar.component';
import {SingleWidgetsBarContent} from './components/single-widgets-bar/single-widgets-bar-content/single-widgets-bar-content.component';
import {FlowComponent} from './components/flow/flow.component';
import {FlowStepDirective} from './components/flow/directive/flow-step.directive';
import {DialogTitleComponent} from './modals/dialog-title/dialog-title.component';
import {GraphTypeSelectorComponent} from './components/graph-type-selector/graph-type-selector.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {GraphTypeSelectorsComponent} from './components/graph-type-selectors/graph-type-selectors.component';
import {DashboardPortalModule} from './components/dashboard-portal/dashboard-portal.module';
import {LayoutModule} from '@angular/cdk/layout';
import {SmallGraphTrendComponent} from './components/small-graph-trend/small-graph-trend.component';
import {NgxSelectModule} from './ngx-select/ngx-select.module'
import {IssuesHealthDialogComponent} from './modals/issues-health-dialog/issues-health-dialog.component';
import {AlertDataDialogComponent} from './modals/alert-data-dialog/alert-data-dialog.component';
import {BasicContainerComponent} from './components/basic-container/basic-container.component';
import {AlertDialogComponent} from './modals/alert-dialog/alert-dialog.component';
import {IssueActionsDialogComponent} from './modals/issue-actions-dialog/issue-actions-dialog.component';
import {TotalGridRowsComponent} from './ag-grid/cell-renderers/only-grid-components/total-grid-rows/total-grid-rows.component';
import {FabricsHealthListComponent} from '../fabrics/components/portlets/fabrics-health-list/fabrics-health-list.component'
import {GridHeaderComponent} from './ag-grid/cell-renderers/grid-header/grid-header.component';
import {SaveEditedPropertiesComponent} from './modals/save-edited-properties/save-edited-properties.component';
import {ActionsTypeSelectComponent} from './components/entity-dashboard/actions-type-select/actions-type-select.component';
import {SingleSyncStatusComponent} from './components/single-entities/single-sync-status/single-sync-status.component';
import {ActionKpiDialogComponent} from './modals/action-kpi-dialog/action-kpi-dialog.component';
import {ActionConfigurationDialogComponent} from './modals/action-configuration-dialog/action-configuration-dialog.component';
import {ActionConfigurationDataComponent} from './modals/action-configuration-dialog/configuration-data/action-configuration-data.component';
import {UserSelectionActionConfigurationaComponent} from './modals/action-configuration-dialog/user-selection-action-configurationa/user-selection-action-configurationa.component';
import {HintComponent} from './components/hint/hint.component';
import {FabricsVersionControlListComponent} from '../fabrics/components/fabrics-version-control-list/fabrics-version-control-list.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {DiagramComponent} from './components/diagram/diagram.component';
import {SyncChangesModalComponent} from './modals/sync-changes-modal/sync-changes-modal.component';
import {SyncedEntityPropertiesComponent} from './components/synced-entity-properties/synced-entity-properties.component';
import {SetFocusDirective} from './components/legacy-editable-text/set-focus.directive';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TemplateRefDirective} from './pipes/string-as-template-ref/template-ref.directive';
import {WidgetManagerDialogComponent} from './modals/widget-manager-dialog/widget-manager-dialog.component';
import {EditLayoutComponent} from './components/edit-layout/edit-layout.component';
import {FileUploadComponent} from './components/file-upload/file-upload.component';
import {ProgressBarComponent} from './components/progress-bar/progress-bar.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {OrgHealthListComponent} from '../organizations/portlets/org-health-list/org-health-list.component';
import {TenantVenuesListComponent} from '../venues/components/portlets/tenant-venues-list/tenant-venues-list.component';
import {DevicesHealthListComponent} from 'src/app/devices/components/portlets/devices-health-list/devices-health-list.component';
import {NavForDynamicComponent} from './components/nav-for-dynamic/nav-for-dynamic.component';
import {ClientsHistoryListComponent} from '../clients/components/clients-history-list/clients-history-list.component';
import {EventsListComponent} from '../alarms/components/events-list/events-list.component';
import {AddOrgTypeDialogComponent} from './modals/add-org-type-dialog/add-org-type-dialog.component';
import {OrganizationTypesManagerComponent} from '../organizations/settings/components/organization-types-manager/organization-types-manager.component';
import {OrgChildrenGridComponent} from '../organization-tenants/components/org-children-grid/org-children-grid.component';
import {AnomalyThresholdComponent} from './modals/anomaly-threshold/anomaly-threshold.component';
import {SharedComponentsModule} from './shared-components.module';
import {SharedPipesModule} from './shared-pipes.module';
import {SharedDirectivesModule} from './shared-directives.module';
import {ForceDirectedGraphModule} from './components/force-directed-graph/force-directed-graph.module';
import {AccordionDisplayComponent} from './components/accordion-display/accordion-display.component';
import {InfoBarComponent} from './components/info-bar/info-bar.component';
import {PeriodTimeSelectionComponent} from './components/period-time-selection/period-time-selection.component';
import {DeviationReportsDialogModule} from './modals/deviation-reports-dialog/deviation-reports-dialog.module';
import {MainActionsDashboardComponent} from './components/entity-dashboard/main-actions-dashboard/main-actions-dashboard.component';
import {ActionsListComponent} from './components/entity-dashboard/main-actions-dashboard/components/actions-list/actions-list.component';
import {ActionsFiltersComponent} from './components/entity-dashboard/main-actions-dashboard/components/actions-filters/actions-filters.component';
import {MatMenuModule} from "@angular/material/menu";
import {MatRippleModule} from "@angular/material/core";
import {MatButtonModule} from "@angular/material/button";

@NgModule({
  declarations: [
    ColumnBoxComponent,
    ConfirmationModalComponent,
    TermsModalComponent,
    SnackBarComponent,
    MapComponent,
    PagePlaceholderComponent,
    ClDeleteButtonComponent,
    BreadcrumbsComponent,
    PageSpinnerComponent,
    SingleSelectListComponent,
    MultiSelectListComponent,
    FlowComponent,
    FlowStepDirective,
    DialogTitleComponent,
    GraphTypeSelectorComponent,
    LegacyPropertiesComponent,
    PropertiesTitleComponent,
    PropertiesHeaderComponent,
    LegacyPropertiesContentComponent,
    SingleWidgetsBarComponent,
    SingleWidgetsBarContent,
    GraphTypeSelectorsComponent,
    SmallGraphTrendComponent,
    IssuesHealthDialogComponent,
    AlertDataDialogComponent,
    BasicContainerComponent,
    AlertDialogComponent,
    IssueActionsDialogComponent,
    TotalGridRowsComponent,
    FabricsHealthListComponent,
    FabricsVersionControlListComponent,
    OrgChildrenGridComponent,
    GridHeaderComponent,
    SaveEditedPropertiesComponent,
    ActionsTypeSelectComponent,
    SingleSyncStatusComponent,
    ActionKpiDialogComponent,
    ActionConfigurationDialogComponent,
    ActionConfigurationDataComponent,
    UserSelectionActionConfigurationaComponent,
    HintComponent,
    HintComponent,
    DiagramComponent,
    SetFocusDirective,
    FileUploadComponent,
    ProgressBarComponent,
    SyncChangesModalComponent,
    SyncedEntityPropertiesComponent,
    SetFocusDirective,
    TemplateRefDirective,
    WidgetManagerDialogComponent,
    EditLayoutComponent,
    OrgHealthListComponent,
    TenantVenuesListComponent,
    DevicesHealthListComponent,
    ClientsHistoryListComponent,
    EventsListComponent,
    NavForDynamicComponent,
    AddOrgTypeDialogComponent,
    OrganizationTypesManagerComponent,
    AccordionDisplayComponent,
    InfoBarComponent,
    PeriodTimeSelectionComponent,
    AnomalyThresholdComponent,
    MainActionsDashboardComponent,
    ActionsListComponent,
    ActionsFiltersComponent,
  ],
  exports: [
    CommonModule,
    NetworkTopologyModule,
    ForceDirectedGraphModule,
    NgbTooltipModule,
    InsightsAnomaliesGridModule,
    NetopDialogModule,
    DragDropModule,
    SharedComponentsModule,
    ColumnBoxComponent,
    MapComponent,
    PagePlaceholderComponent,
    TranslateModule,
    AgGridTablesModule,
    ReactiveFormsModule,
    MatMenuModule,
    FormsModule,
    MaterialModule,
    MatRippleModule,
    SharedPipesModule,
    SharedDirectivesModule,

    ClDeleteButtonComponent,
    PeriodTimeSelectionComponent,
    SingleSelectListComponent,
    InlineSVGModule,
    ColorPickerModule,


    BreadcrumbsComponent,
    IssuesHealthDialogComponent,
    AlertDataDialogComponent,
    ActionKpiDialogComponent,
    SyncChangesModalComponent,
    SaveEditedPropertiesComponent,
    SingleSyncStatusComponent,

    ConfirmationModalComponent,
    TermsModalComponent,
    DialogTitleComponent,
    SnackBarComponent,
    InfoBarComponent,

    PageSpinnerComponent,
    MultiSelectListComponent,
    FlowComponent,
    FlowStepDirective,
    FlexLayoutModule,
    SetFocusDirective,
    GraphTypeSelectorComponent,
    TemplateRefDirective,
    LegacyPropertiesComponent,
    PropertiesTitleComponent,
    PropertiesHeaderComponent,
    LegacyPropertiesContentComponent,
    SingleWidgetsBarComponent,
    SingleWidgetsBarContent,
    GraphTypeSelectorsComponent,
    SmallGraphTrendComponent,
    NgxSelectModule,
    BasicContainerComponent,
    TotalGridRowsComponent,
    FabricsHealthListComponent,
    FabricsVersionControlListComponent,
    OrgChildrenGridComponent,
    TotalGridRowsComponent,
    GridHeaderComponent,
    ActionsTypeSelectComponent,
    HintComponent,
    HintComponent,
    DiagramComponent,
    EditLayoutComponent,
    FileUploadComponent,
    ProgressBarComponent,
    OrgHealthListComponent,
    TenantVenuesListComponent,
    DevicesHealthListComponent,
    ClientsHistoryListComponent,
    EventsListComponent,
    NavForDynamicComponent,
    OrganizationTypesManagerComponent,
    AccordionDisplayComponent,
    MainActionsDashboardComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    AgGridTablesModule,
    MaterialModule,
    InlineSVGModule,
    RouterModule,
    DashboardPortalModule,
    FlexLayoutModule,
    LayoutModule,
    NgxSelectModule,
    NetopDialogModule,
    NgbTooltipModule,
    DragDropModule,
    MatMenuModule,
    ColorPickerModule,
    MatRippleModule,
    SharedComponentsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    MatButtonModule,
    InsightsAnomaliesGridModule,
    DeviationReportsDialogModule
  ]
})
export class SharedModule {
}
