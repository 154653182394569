import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable, of, throwError} from 'rxjs';
import {catchError, finalize, take} from 'rxjs/operators';
import {PageSpinnerService} from './page-spinner.service';
import {DisplayErrorService} from './display-error.service';
import {Logger, LoggerService} from './logger.service';
import {AuthMethodGenerator} from "../../auth/auth-method-generator";
import {UrlResolverService} from './url-resolver-service';
import {UsersService} from "./rest-services/users.service";
import {EnvironmentType} from "../../../environments/environment.model";
import {Router} from "@angular/router";


export enum HTTPContentType {
  APPLICATION_JSON = 'application/json',
  X_WWW_FORM_URLENCODED = 'application/x-www-form-urlencoded; charset=utf-8',
  TEXT_PLAIN = 'text/plain',
  FORM_DATA = 'multipart/form-data'
}

@Injectable({
  providedIn: 'root'
})
export class HttpSettingInterceptorService implements HttpInterceptor {

  private requestsNum = 0;

  private request: HttpRequest<any>;
  private logger: Logger;

  constructor(private urlResolverService: UrlResolverService,
              private spinnerService: PageSpinnerService,
              private displayErrorService: DisplayErrorService,
              private usersService: UsersService,
              private injector: Injector,
              private loggerFactory: LoggerService,
              private router: Router) {
    this.logger = this.loggerFactory.getLogger("HttpSettingInterceptorService");

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requestsNum++;

    // this.spinnerService.show();
    const headers = this.generateHeaders(request);
    const apiBaseUrl = this.urlResolverService.getBaseApiUrl(request.url === "logout");
    const apiReq = request.clone({
      url: `${apiBaseUrl}/${request.url}`,
      setHeaders: headers
    });

    const localAssetsRegExp = /^[\.]?(\/)?(assets\/)/;
    if (request.url.startsWith('http') || localAssetsRegExp.test(request.url)) {
      if (request.url.startsWith('http')) {
        //debugger
      }
      this.request = request;
    } else {
      this.request = apiReq;
    }

    return next.handle(this.request).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        const isAuthenticated = errorResponse?.status !== 0 && errorResponse?.status !== 401;

        if (isAuthenticated) {
          this.logger.error('http error %o', {
            error_type: 'Http Error',
            ...errorResponse
          })
        }
        else if (errorResponse.status === 0 || errorResponse.message == "undefined") {
          location.reload();
        }

        if (errorResponse &&
          errorResponse.url && errorResponse.url.includes("globalInfo/access") || errorResponse.url.includes("authenticate")) {
          this.logout();
        }
        if (!errorResponse.url.includes("logout")) {
          this.displayErrorService.openMessage(this.displayErrorService.generateErrorText(errorResponse));
        }

        // Rethrow already handled http errors - for now, the global error handler will ignore it anyway,
        // another option might be return NEVER; But this will cause infinite loading spinner.
        return throwError(errorResponse);
      }),
      finalize(() => {
        this.requestsNum--;
        if (this.requestsNum === 0) {
          // this.spinnerService.hide();
        }
      })
    );
  }

  /**
   * Check if the body is formData instance,
   * If yes, remove the Content-Type field (the browser will take care of it)
   */
  generateHeaders(request: HttpRequest<any>) {
    let headers = {
      'Content-Type': HTTPContentType.APPLICATION_JSON,
      Accept: HTTPContentType.APPLICATION_JSON,
    }
    if (request && request.body instanceof FormData) {
      delete headers["Content-Type"]
    }
    return headers;
  }

  logout() {
    this.usersService.logout().pipe(take(1), catchError((e) => {
      const authService = AuthMethodGenerator.getAuthService(this.injector);
      return authService.logout();
    })).subscribe(result => {
      const authService = AuthMethodGenerator.getAuthService(this.injector);
      return authService.logout();
    });
  }
}
