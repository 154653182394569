import { Component, OnInit } from '@angular/core';
import {AnomalyCategory, AnomalySettingsDTO} from "../../../../models/anomalies.model";
import {Observable} from "rxjs";
import {TenantSummaryDTO} from "../../../../models/tenants.model";
import {Entity} from "../../../../models/entity.model";
import {Logger, LoggerService} from "../../../../services/logger.service";
import {GlobalEntitiesService} from "../../../../services/rest-services/global-entities.service";
import {InsightsService} from "../../../../services/rest-services/insights.service";
import {OrganizationsService} from "../../../../services/rest-services/organizations.service";
import {take} from "rxjs/operators";
import {EntityType} from "../../../../models/entity-type.enum";
import {ListViewItem} from "../../../list-view/list-view.component";

@Component({
  selector: 'app-anomaly-settings-container',
  templateUrl: './anomaly-settings-container.component.html',
  styleUrls: ['./anomaly-settings-container.component.scss']
})
export class AnomalySettingsContainerComponent implements OnInit {
  anomalyCategories: AnomalyCategory[];
  currentAnomalySettings$: Observable<AnomalySettingsDTO[]>;
  currentOrgTenants: TenantSummaryDTO[];
  selectedTenants: TenantSummaryDTO[];
  selectedTenant: Entity;
  currentEntity: Entity;
  listItems: ListViewItem<AnomalyCategory>[];
  private logger: Logger;

  constructor(
    private globalEntitiesService: GlobalEntitiesService,
    private insightsService: InsightsService,
    private organizationsService: OrganizationsService,
    private loggerFactory: LoggerService,
  ) {
    this.logger = this.loggerFactory.getLogger("AnomalyThresholdComponent");
  }

  ngOnInit() {
    this.globalEntitiesService.currentEntity$.pipe(take(1)).subscribe(entity => {
      this.currentEntity = entity;
      if (entity.type == EntityType.ORGANIZATION) {
        this.loadOrgTenantsData();
      }
      if (entity.type == EntityType.TENANT) {
        this.selectedTenant = entity;
        this.setAnomalyCategories();
      }
    })
  }

  loadOrgTenantsData() {
    this.organizationsService.fetchTenantsById(this.currentEntity.id).pipe(take(1)).subscribe(tenants => {
      this.currentOrgTenants = tenants;
      this.selectedTenant = { id: this.currentOrgTenants[0].id, name: this.currentOrgTenants[0].name, type: EntityType.TENANT };
      this.selectedTenants = tenants;
      this.setAnomalyCategories();
    })
  }

  onCategorySelected(anomalyCategory: AnomalyCategory) {
    this.currentAnomalySettings$ = this.insightsService.getTenantAnomalySettings(this.selectedTenant, anomalyCategory);
  }

  onSettingChanged(newSetting: AnomalySettingsDTO) {
    this.logger.debug("onSettingChanged", newSetting);
    this.isOrg ?
      this.insightsService.updateMultiTenantsAnomalySettings(this.currentEntity, newSetting, this.selectedTenants.map(tenant => tenant.id)).subscribe() :
      this.insightsService.updateTenantAnomalySettings(this.selectedTenant, newSetting).subscribe();
  }

  private setAnomalyCategories() {
    this.anomalyCategories = AnomalyCategory.anomaliesCategoryArray();
    this.extractListItems();
  }

  private extractListItems() {
    this.listItems = this.anomalyCategories
      ? this.anomalyCategories.map(category => ({
        id: category,
        label: AnomalyCategory.anomalyAsString(category),
        icon: 'anomaly-icon'
      }))
      : undefined;
  }

  get isOrg(): boolean {
    return this.currentEntity && this.currentEntity.type == EntityType.ORGANIZATION;
  }
}
