import {Component, Input, EventEmitter, Output, SimpleChanges} from '@angular/core';
import {MainData, PropertiesElementType, PanelElement} from '../models/properties-content';
import {Logger, LoggerService} from 'src/app/shared/services/logger.service';
import {EditPropertiesService} from '../services/edit-properties.service';
import {FormGroup, FormBuilder, FormControl,} from '@angular/forms';
import {FormAction} from 'src/app/shared/modals/models/form-actions.model';
import {debounceTime} from 'rxjs/operators';
import {SourceChanged} from '../models/source-changes';
import {EditPropSetValidtorsService} from '../services/edit-prop-set-validtors.service';
import {EditAbleKeys} from '../models/editableKeys';
import {Subscription} from 'rxjs';
import {EditableKey} from '../../legacy-editable-text/models/editable-text.model';
import {LowerNoSpacesPipe} from './lower-no-spaces.pipe';
import {stringToCamelcase} from 'src/app/shared/operators/string-opertators';
import {deepEqual} from "../../../operators/object-operators/object-comparison";
import {BooleanKey} from "../../../models/utils-classes/key-value.model";
import {alphaNumericWithSpecialCharsValidator} from "../../../form-validators/form-validators";
import {ERRORS_NAME} from "../../../form-validators/form-model";

@Component({
  selector: 'app-properties-content',
  templateUrl: './legacy-properties-content.component.html',
  styleUrls: ['./legacy-properties-content.component.scss']
})
export class LegacyPropertiesContentComponent {
  @Input() propertiesData: MainData;
  @Input() isDevice: boolean;
  @Output() specialItemStatus: EventEmitter<BooleanKey> = new EventEmitter();
  /**
   * @param editAbleObject Contain the Object of the editable values.
   * This is the Object that after changes will be sent to ther server
   */
  @Input() editAbleObject: EditAbleKeys;
  /**
   * @param editablePropertiesChanged Event emitter that emit when editable values were changed
   */
  @Output() editablePropertiesChanged: EventEmitter<any> = new EventEmitter();

  /**
   * @param editableKey The current key and its editabilty state
   */
  editableKey: EditableKey;
  /**
   * @param isEditable Deter if the property is editable or not
   */
  isEditable: boolean = false;
  /**
   * @param panelElements Properties element for display
   */
  panelElements: PanelElement[] = [];
  /**
   * @param editPropertiesForm Form that conatain the changes on the editable properties
   */
  editPropertiesForm: FormGroup;
  /**
   * @param originalEditableValues Containt the original values of the editable keys
   */
  originalEditableValues = {};
  optionsForEnums: any[];
  subsc: Subscription[] = [];
  private logger: Logger
  panelStatus: { [key: string]: boolean } = {};

  constructor(
    private lowerNoSpaces: LowerNoSpacesPipe,
    // public editStringsService: EditStringsService,
    public editPropertiesService: EditPropertiesService,
    private setValidtorsService: EditPropSetValidtorsService,
    private loggerFactory: LoggerService,
    private fb: FormBuilder
  ) {
    this.logger = this.loggerFactory.getLogger("PropertiesContentComponent");
  }

  /**
   * @method subscribeToFormActionStatus Listen to the current form action status.
   * If delete, close the editable key and re-initilize the form data and original source data
   */
  private subscribeToFormActionStatus() {
    this.editPropertiesService.notifyFormActionStatusObservable$.subscribe(status => {
      if (status == FormAction.DELETE) {
        this.editableKey = null;
        this.initiateEditAbleKeysValues();
      }
      if (status == FormAction.SAVE) {
        this.editableKey = null;
        this.editPropertiesService.isEditMode(false);
        this.updateOriginalValues();
      }
    })
  }

  private updateOriginalValues() {
    this.editAbleObject.getPropertiesList().forEach(prop => {
      this.editAbleObject.getPropertyKeys(prop).forEach(key => {
        let combinedKey = prop + key;
        this.originalEditableValues[combinedKey] = this.editPropertiesForm.get(combinedKey).value;
      })
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    this.logger.debug("changes", changes);
    if (!this.editAbleObject) {
      this.isEditable = false;
      this.editPropertiesForm = null;
      this.editableKey = null;
    }
    if (!this.editPropertiesForm && this.editAbleObject) {
      this.initiateForm();
    }
    if (changes["propertiesData"] && this.editAbleObject) {
      this.originalEditableValues = {};
      this.initiateForm();
    }

    this.prepareElements();
  }

  ngAfterViewInit() {
    //The subsription to the form values can start only after the view was initilized.
    //Otherwise, it will show the edit button (It will think the value were chagned)
    if (this.editPropertiesForm && this.propertiesData.elementType == PropertiesElementType.FABRIC) {
      this.subscribeToFormChanges();
    }
  }

  /**
   * @method initiateForm Initilized the form based on the editable keys array
   */
  private initiateForm() {
    this.editPropertiesForm = this.fb.group({});
    this.editAbleObject.getPropertiesList().forEach(prop => {
      this.editAbleObject.getPropertyKeys(prop).forEach(key => {
        //The control name is comination of the prop and key name, in order to make it unique
        this.editPropertiesForm.setControl(prop + key, new FormControl('', alphaNumericWithSpecialCharsValidator(ERRORS_NAME.FORBIDDEN_CHARS)));
        // this.setValidtorsService.setValidator(this.editPropertiesForm, this.editAbleObject, prop, key)
      })
    })
    this.subscribeToFormActionStatus();
    this.subscribeToFormChanges();
  }

  /**
   * @method onFormChanges Check if form values different from the original values.
   * If yes: it emit event eith the new values
   */
  private onFormChanges(data: any): void {
    let isFormValid: boolean = true;
    Object.values(this.editPropertiesForm.controls).forEach(control => {
      if (control.value !== undefined && !control.valid) {
        isFormValid = false;
      }
    })
    if (!isFormValid) {
      this.editPropertiesService.isFormValid(false);
    } else
      this.editPropertiesService.isFormValid(true);

    if (!deepEqual(data, this.originalEditableValues)) {
      let changedAndSource: SourceChanged = {source: this.originalEditableValues, changed: data}
      this.editPropertiesService.isEditMode(true);
      this.editablePropertiesChanged.emit(changedAndSource);
    } else {
      this.editPropertiesService.isEditMode(false);
      this.editablePropertiesChanged.emit(null)
    }
  }

  /**
   * @method prepareElements Prepare the elments for display, and invoke @method initiateEditAbleKeysValues
   */
  private prepareElements() {
    this.panelElements = [];
    this.panelElements.pop();
    if (this.propertiesData) {
      this.propertiesData.panelElements.forEach(element => {
        if (element) {
          this.panelElements.push({
            key: element.key,
            value: element.value,
            withCrudIcons: element.withCrudIcons,
            isTeleported: element.isTeleported,
            isValueAsTooltip: element.isValueAsTooltip
          })
          this.panelStatus[element.key] = false;
        }
      })
    }
    if (this.editAbleObject && this.editPropertiesForm) {
      this.initiateEditAbleKeysValues()
    }
    this.logger.debug("Properties elements are: ", this.panelElements)
  }

  /**
   * @method initiateEditAbleKeysValues Initlize the form values and the object that save the original values (for compare)
   */
  private initiateEditAbleKeysValues() {
    this.editAbleObject.getPropertiesList().forEach(prop => {
      this.panelElements.forEach(element => {
        if (element.key) {
          let formattedElement = this.lowerNoSpaces.transform(element.key);
          if (formattedElement == prop) {
            this.editAbleObject.getPropertyKeys(prop).forEach(key => {
              if (element.value) {
                let formattedValue = element.value[stringToCamelcase(key)];
                this.originalEditableValues[prop + key] = formattedValue;
                this.editPropertiesForm.get(prop + key).setValue(formattedValue);
              } else {
                this.originalEditableValues[prop + key] = null;
                this.editPropertiesForm.get(prop + key).setValue(null);
              }
            })
          }
        }
      })
    });
    this.logger.debug("initiateEditAbleKeysValues", this.originalEditableValues);
  }

  /**
   * @method changeValueMode Responsible to change the property that entered edit mode (after user clicked the pencil icon)
   * @param key The current element key
   */
  changeValueMode(itemKey: string, propKey: string, event: any) {
    let formmatedKey = `${itemKey}${this.lowerNoSpaces.transform(propKey)}`;
    if (this.editPropertiesForm.controls[formmatedKey].valid) {
      if (this.editableKey && this.editableKey.key == formmatedKey) {
        this.isEditable = !this.isEditable;
        this.editableKey = {key: formmatedKey, isEditable: this.isEditable}
      } else {
        this.isEditable = true;
        this.editableKey = {key: formmatedKey, isEditable: this.isEditable};
      }
    }
  }

  /**
   * @method getPropValue Return the form value if there is one, and Return the origin prop value if there is not
   * @param prop The current property being displayed
   */
  getPropValue(itemKey: string, prop: { key: string, value: any }) {
    let key = this.lowerNoSpaces.transform(prop.key);
    let formKeyValue = itemKey + key;
    let value;
    if (this.editAbleObject && this.editPropertiesForm && this.editPropertiesForm.get(formKeyValue)) {
      value = this.editPropertiesForm.get(formKeyValue).value;
    }
    if (value)
      return value
    return prop.value;
  }

  /**
   * @method getToolBarClass Return different class for device screen, because it has different layout
   */
  get toolBarClass() {
    if (!this.isDevice)
      return ""
    return "device-prop-toolbar"
  }

  /**
   * @method returnZero Return 0 for the key value pipe, and by that, it disabling the sorting
   */
  returnZero() {
    return 0;
  }

  /**
   * @method isEnum Check if variable is an object.
   * We are asssuming that only enums will be objects.
   * The mothod check if the current property is as the same type of the editableObject.
   * @param prop current property
   * @param itemValue item that is the parent of the property
   */
  isEnum(prop: { key: string, value: string }, itemValue: string) {
    let isEnum = false;
    this.editAbleObject.getPropertiesList().forEach(property => {
      if (property == itemValue.toLowerCase()) {
        if (this.originalEditableValues[itemValue.toLowerCase() + prop.key.toLowerCase()]) {
          this.editAbleObject.getEnumsArray().forEach(editableEnum => {
            if (editableEnum.enumName.toLowerCase() == prop.key.toLowerCase())
              isEnum = true;
          })
        }
      }
    })
    return isEnum;
  }

  /**
   * @method getEnumValues Check if there are enums names that matches the property name
   * If yes, it invoke the getEnumsArray method.
   * The method return an object that contain the enum's name and enum's values.
   * If the enum name matches to the property name, it will return its values
   * Important: the method will work only if the enum name will be the same as the value of the enum type
   */
  getEnumValues(prop: any) {
    let enumValues: [] = [];
    this.editAbleObject.getEnumsArray().forEach(editableEnum => {
      if (editableEnum.enumName.toLowerCase() == prop.key.toLowerCase())
        enumValues = editableEnum.enum.getEnumAsArray();
    });
    return enumValues
  }

  get isEditMode() {
    let isEditMode = false;
    let editModeSubsc = this.editPropertiesService.notifyIsEditModeObservable$.subscribe(mode => {
      if (!mode)
        isEditMode = true;
    })
    this.subsc.push(editModeSubsc);
    return isEditMode;
  }

  subscribeToFormChanges() {
    this.editPropertiesForm.valueChanges.pipe(debounceTime(300)).subscribe
    (data => this.onFormChanges(data));
  }

  onSpecialChange(itemKey: string) {
    this.panelStatus[itemKey] = !this.panelStatus[itemKey];
    this.specialItemStatus.emit({[itemKey]: this.panelStatus[itemKey]});
  }

  ngOnDestroy() {
    this.subsc.forEach(subsc => {
      subsc.unsubscribe();
    })
  }
}
