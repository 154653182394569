export class BaseEnvironment {
  environmentType: EnvironmentType;
  dashboardEnvironment?: DashboardEnvironment;
  disabledAuth: boolean;
  authMethod: AuthMethod;
  disableFirebase: boolean;
  apiBaseUrl?: string;
  searchUrl?: string;
}

export class EnvironmentModel extends BaseEnvironment {
  [param: string]: any;
}

export enum EnvironmentType {
  STAGE = "stage",
  STAGE_AZ = "stage_az",
  LOCAL_STAGE = "local_stage",
  LOCAL = "local",
  PRODUCTION = "production",
  REVIEW = "review",
  DEV = "dev",
  UNSET = "unset",
  ON_PREM_LOCAL = "on_prem_local",
  ON_PREM_PROD = "on_prem_prod"
}

export namespace EnvironmentType {
  export function isOnPrem(type: EnvironmentType) {
    return type === EnvironmentType.ON_PREM_LOCAL || type === EnvironmentType.ON_PREM_PROD;
  }

  export function isLoggingAllowed(type: EnvironmentType) {
    return type !== EnvironmentType.PRODUCTION && type !== EnvironmentType.STAGE;
  }
}

export enum DashboardEnvironment {
  OnPrem = 'on_prem',
  Remote = 'remote'
}

export enum AuthMethod {
  AUTH_0,
  KEY_CLOAK,
  NONE
}
