import {Component, Input, OnInit} from '@angular/core';
import {GraphDonutConfig} from '../chartjs-components/models/graph-donut-config';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-graph-donut-plain',
  templateUrl: './graph-donut-plain.component.html',
  styleUrls: ['./graph-donut-plain.component.scss']
})
export class GraphDonutPlainComponent implements OnInit {

  @Input() donutConfig: GraphDonutConfig;
  @Input() width: number = 280;
  @Input() height: number = 220;
  @Input() diameter: number = 200;
  @Input() isLoading: boolean = false;
  @Input() isLabelsDisplay: boolean = true;
  @Input() isResponsive: boolean = true;
  @Input() maintainAspectRatio: boolean = false;
  @Input() centerNoDataText: boolean = true;

  noDataText: string;

  constructor(translate: TranslateService) {
    this.noDataText = translate.instant("data.ERRORS.NO_DATA");
  }

  ngOnInit(): void {
  }

}
