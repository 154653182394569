import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {KpiGroupedBy, KpiTrend, KpiType, splitAndStackedConfig, StackedKpiData} from 'src/app/shared/models/kpi.model';
import * as moment from 'moment';
import {GraphTrendConfig} from '../../chartjs-components/models/graph-trend-config';
import {MultiKpiChartsService} from '../../chartjs-components/services/multi-kpi-charts.service';
import {KpiMultiDataService} from 'src/app/shared/components/chartjs-components/services/kpi-stacked-split-data.service';
import {isArrayEmpty, isObjectEmpty} from '../../../operators/object-operators/null-checks';
import {TranslateService} from '@ngx-translate/core';
import {ChartJsAnnotation} from '../../chartjs-components/models/annotations.model';
import {KpiService} from '../../../services/rest-services/kpi.service';
import {KpiDataService} from '../../../services/strategies/kpi-data.service';
import {NgChanges} from '../../../extend-angular-classes/on-changes';
import {GraphTrendMarker} from '../../chartjs-components/models/chart-js-events.model';
import {BaseKpiChartDisplay} from '../../single-entities/multi-kpi-action-graph-trend/base-kpi-chart-display';
import {StringKeyValue} from '../../../models/utils-classes/key-value.model';
import {TrendOptionsData, TrendOptionsDto} from './multi-kpi-action-graph-trend.model';
import {drawArrow} from "../../../operators/encoded-arrow";

@Component({
  selector: 'app-multi-kpi-action-graph-trend',
  templateUrl: './multi-kpi-action-graph-trend.component.html',
  styleUrls: ['./multi-kpi-action-graph-trend.component.scss']
})
export class MultiKpiActionGraphTrendComponent extends BaseKpiChartDisplay {
  @Input() isSecondRow: boolean;
  @Input() kpiTrend: KpiTrend | StackedKpiData[];

  private _optionsTrend: TrendOptionsDto;
  @Input() set optionsTrend(optionsTrend: TrendOptionsDto) {
    if (optionsTrend) {
      this._optionsTrend = optionsTrend;
      this.trendToggleOptions = Object.keys(optionsTrend);
    }
  };

  get optionsTrend() {
    return this._optionsTrend;
  };

  private _selectedTrendOption: string
  @Input() set selectedTrendOption(selectedTrendOption: string) {
    if (!this._selectedTrendOption || this._selectedTrendOption !== selectedTrendOption)
      this._selectedTrendOption = selectedTrendOption;
  };

  get selectedTrendOption(): string {
    return this._selectedTrendOption;
  };

  @Input() applicationTrend: StackedKpiData[];
  @Input() wiredTrend: KpiTrend;

  @Input() kpiAnnotation: ChartJsAnnotation[];
  @Input() marker: GraphTrendMarker;
  @Input() nextToTitle: StringKeyValue[];
  @Output() trendOptionSelected = new EventEmitter<TrendOptionsData>();

  stackedAndSplitData: splitAndStackedConfig;
  kpiGroupedBy = KpiGroupedBy;
  trendToggleOptions: string[];

  /**
   * @param trendLineConfig The canvas configuration
   */
  trendLineConfig: GraphTrendConfig = {datasets: []};

  constructor(
    protected kpiStackedAndSplitDataService: KpiMultiDataService,
    protected kpiService: KpiService,
    protected kpiDataService: KpiDataService,
    protected cdr: ChangeDetectorRef,
    private multiKpiChartsService: MultiKpiChartsService,
    private translate: TranslateService) {
    super(kpiStackedAndSplitDataService, kpiService, kpiDataService, cdr);
  }

  ngOnChanges(changes: NgChanges<MultiKpiActionGraphTrendComponent>) {
    if (changes.kpiTrend) {
      this.setChartConfig();
    }
  }

  /**
   * @method setChartConfig Receive the kpi configuration and initiate the trendLineConfig param
   * @param kpiTrend
   */
  setChartConfig() {
    let data: GraphTrendConfig;
    let trendLineConfig: GraphTrendConfig;

    if (this.kpiType !== KpiType.Traffic && this.kpiType !== KpiType.Throughput) {
      let formattedKpiTrend: { x: any, y: any }[] = (this.kpiTrend as KpiTrend).map((datum: any) => {
        return {x: moment(datum.datetime || datum.date), y: datum.value}
      });
      data = this.kpiStackedAndSplitDataService.setTrendLineConfig(formattedKpiTrend, trendLineConfig, this.kpiType, this.toggleSelection);
    }
    if (this.kpiType == KpiType.Traffic || this.kpiType == KpiType.Throughput) {
      data = this.kpiStackedAndSplitDataService
        .setTrendLineConfig(this.trendByToggle, trendLineConfig, this.kpiType, this.toggleSelection);
    }

    if (data && !isArrayEmpty(data) || !isObjectEmpty(data)) {
      this.trendLineConfig = !this.isAppDisplay ? data : {...this.kpiStackedAndSplitDataService.mergeMultiLineConfig(this.trendLineConfig)};
      // this.stackedAndSplitData = this.isAppDisplay ? {
      //   splitGraphConfig: {...this.trendLineConfig},
      //   stackedGraphConfig: this.kpiStackedAndSplitDataService.convertStackedToTrafficUnit(this.unit, data)
      // } : null;
    }
    this.cdr.markForCheck();
  };

  onToggleChange(kpiGrouped: KpiGroupedBy) {
    this.toggleSelection = kpiGrouped;
    this.setChartConfig();
  }

  onTrendToggleChange(toggleOption: string) {
    this.selectedTrendOption = toggleOption;
    this.kpiTrend = this.optionsTrend[toggleOption].data;
    this.kpiType = this.optionsTrend[toggleOption].type;
    this.trendOptionSelected.emit(this.optionsTrend[toggleOption]);
    this.setChartConfig();
  }

  get graphTitle() {
    return this.multiKpiChartsService.setKpiTitleWithUnits(this.kpiType, this.unit);
  }

  get isFromZero() {
    return true;
  }

  get noDataMessage() {
    if (this.kpiType === KpiType.Traffic) {
      switch (this.toggleSelection) {
        case KpiGroupedBy.Application:
          return this.translate.instant('data.COMMON.NO_APPLICATION_TRAFFIC_AVAILABLE');
        case KpiGroupedBy.Wired:
          return this.translate.instant('data.COMMON.NO_WIRED_TRAFFIC_AVAILABLE');
        default:
          break;
      }
    }
    return this.translate.instant('data.COMMON.NO_GRAPH_DATA_TO_DISPLAY');
  }

  get trendByToggle() {
    return this.toggleSelection === KpiGroupedBy.Wired && this.kpiType === KpiType.Traffic ? this.wiredTrend : this.kpiTrend;
  }

  get downArrowAsString() {
    return drawArrow('down');
  }
}
