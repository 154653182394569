export function devideStringListsToSubstrings(fieldInput: string) {
  if (fieldInput || fieldInput == '0') {
    let splited: string[] = fieldInput.split(' ');
    return splited;
  }
}

export function convertCamelcaseToKebabCased(key: string) {
  if (key) {
    let newKey: string;
    newKey = key;
    for (let i = 1; i < newKey.length; i++) {
      if (newKey[i] == newKey[i].toUpperCase()) {
        newKey = newKey.substring(0, i) + "-" + newKey[i].toLowerCase() + newKey.substring(i + 1, newKey.length);
        i++;
      }
    }
    if (newKey)
      return newKey;
  }
}

export function convertWordsToKebabCased(words: string) {
  let kebabWord: string = '';
  if (words) {
    const splatted = words.split(' ');
    splatted.forEach((word, index) => {
      if (index < splatted.length - 1) {
        kebabWord += `${word.toLowerCase()}-`;
      } else {
        kebabWord += `${word.toLowerCase()}`;
      }
    })
  }
  return kebabWord;
}

export function stringToCamelcase(str: string) {
  if (str) {
    let wordsArray = str.split(" ");
    let newStr: string = "";
    wordsArray.forEach(currentWord => {
      let formmatedWord = "";
      if (!wordIsUppercase(currentWord)) {
        for (let i = 0; i < currentWord.length; i++) {
          const currentChar = currentWord[i];
          const nextChar = currentWord[i + 1];
          if (i > 0) {
            if (currentChar == currentChar.toLowerCase() && isCharacterALetter(nextChar) && nextChar && nextChar == nextChar.toUpperCase() && currentWord[i + 2]) {
              formmatedWord = formmatedWord + currentChar + " ";
            } else {
              formmatedWord = formmatedWord + currentChar;
            }
          }
          if (i == 0) {
            formmatedWord = currentChar.toUpperCase();
          }
        }
        newStr = newStr + " " + formmatedWord;
      } else {
        newStr = newStr + " " + currentWord;
      }
    })

    if (newStr) {
      return newStr.replace(/\s\s+/g, ' ');
    }
  }
}

export function convertSnakeKebabToPascalCase(str: string, sep='-') {
  if (str) {
    let wordsAsArray = str.split(/[-_]/);
    wordsAsArray = wordsAsArray.map(word => word[0].toUpperCase() + word.substr(1));
    return wordsAsArray.join(sep);
  }
}

export function isCharacterALetter(char) {
  return (/[a-zA-Z]/).test(char)
}

export function wordIsUppercase(word: string) {
  return word == word.toUpperCase();
}

export function stringIsNumber(s) {
  let x = +s; // made cast obvious for demonstration
  return x.toString() === s;
}

export function convertCommaSeparatedStringToArray(str: string) {
  const trimmedString = str.trim();
  return trimmedString.replace(/,$/, "").split(",");
}

export function convertArrayIntoCommaSeparatedString(arr: Array<string>) {
  const formattedString = arr.join(', ');
  return formattedString;
}

export function convertStringArray(str: string | string[]) {
  if (Array.isArray(str)) {
    return convertArrayIntoCommaSeparatedString(str);
  }
  return str;
}

export function strIsNullOrEmpty(str: string) {
  return str == null || false || str.replace(' ', '').length == 0;
}

export function splitString(value: string, delimiter: string = '\n'): string[] {
  return value?.replace(/\\n/g, '\n').split('\n') ?? []
}
