export class HoriznotalTimeSpan {
    start: Date;
    end: Date;
    backgroundColor: string;
    width: number;
    marker: HorizontalTimelineMarker;
    spanTooltipText: string;

    constructor(start: Date, end: Date, backgroundColor: string, spanTooltipText: string) {
        this.start = start;
        this.end = end;
        this.backgroundColor = backgroundColor;
        this.spanTooltipText = spanTooltipText;
    }
}

export class HorizontalTimelineMarker {
    date: Date;
    markerTooltipData: string;
    cssClass: string;
    markerWidthPercentage?: number;
}