import {Injectable} from '@angular/core';
import {LoggerService, Logger} from '../../logger.service';
import {Subscription} from 'rxjs';
import {
  ClientUserPreferencesData,
  GridTypes,
  GridUserPreferences,
  GridUserPreferencesOptions
} from "../../../models/client-storage.model";
import {LoadClientStorageService} from "../load-client-storage.service";
import {SaveUserClientStorageService} from "../save-user-client-storage.service";


@Injectable({
  providedIn: 'root'
})
export class StoreUserPreferencesService {
  userPreferences: ClientUserPreferencesData;
  currentGrid: GridTypes;
  firebaseSubcs: Subscription;
  private logger: Logger;

  constructor(private loggerFactory: LoggerService,
              private loadClientStorage: LoadClientStorageService,
              private saveUserClientStorageService: SaveUserClientStorageService) {
    this.logger = this.loggerFactory.getLogger("StoreUserPreferencesService");
    this.subscribeToClientPreferences();
  }

  /**
   * @method setPreference Set the current grid preferences
   * @param gridPref The current grid preferences
   */
  setAllGridPreferences(gridPref: GridUserPreferences) {
    if (!this.userPreferences.preferences[gridPref.gridType])
      this.userPreferences.preferences[gridPref.gridType] = gridPref.preferences;
    else {
      for (let [key, value] of Object.entries(gridPref.preferences)) {
        this.userPreferences.preferences[gridPref.gridType][key] = value;
      }
    }
  }

  /**
   * @method setCurrentGrid Setting the current grid param.
   * The method uses for the updating the graph selectors for a specific grid
   * @param currentGrid The current grid
   */
  setCurrentGrid(currentGrid: GridTypes) {
    this.currentGrid = currentGrid;
    if (this.userPreferences && !this.userPreferences.preferences[this.currentGrid]) {
      this.userPreferences.preferences[this.currentGrid] = {};
    }
  }

  setNonGridPreferences(prefName: string, prefValue: any) {
    if (this.currentGrid && !this.userPreferences.preferences[this.currentGrid])
      this.userPreferences.preferences[this.currentGrid] = {[prefName]: prefValue};
    if (this.currentGrid && this.userPreferences.preferences[this.currentGrid])
      this.userPreferences.preferences[this.currentGrid][prefName] = prefValue;
    this.saveUserClientStorageService.prepareUserPreferences(this.userPreferences, this.currentGrid, prefName, prefValue);
  }

  addNonGridPreferencesToFirebase(prefName: string, prefValue: any) {
    this.userPreferences.preferences[prefName] = prefValue;
    this.saveUserClientStorageService.addNonGridPreferencesToClientStorage(this.userPreferences, prefName, prefValue);
  }

  getCurrentGridPreferences() {
    if (this.userPreferences?.preferences) {
      return this.userPreferences?.preferences[this.currentGrid];
    }
  }

  getCurrentGridPreference(type: GridUserPreferencesOptions) {
    if (this.userPreferences?.preferences && this.userPreferences.preferences[this.currentGrid]) {
      return this.userPreferences.preferences[this.currentGrid][type];
    }
  }

  setCurrentGridPreference(prefName: string, prefValue: any) {
    if (prefName && prefValue && this.userPreferences?.preferences && this.userPreferences.preferences[this.currentGrid]) {
      this.userPreferences.preferences[this.currentGrid][prefName] = prefValue;
      this.logger.debug("Grid new preferences are: ", this.userPreferences);
      this.saveUserClientStorageService.prepareUserPreferences(this.userPreferences, this.currentGrid, prefName, prefValue);
    }
  }

  private subscribeToClientPreferences() {
    this.loadClientStorage.notifyUserPreferencesAsObservable$.subscribe(clientsStorage => {
      if (clientsStorage) {
        this.userPreferences = {...(clientsStorage as ClientUserPreferencesData)};
      }
    })
  }
}
