<div class="row">
  <ng-container *ngIf="kpiTrends">
    <div class="col trend-col" *ngFor="let kpi of kpiTrends; let i = index"
         [ngClass]="{'five-side-padding': annotations && annotations[kpi.type]}">
      <app-multi-kpi-action-graph-trend [kpiType]="kpi.type" [kpiTrend]="kpi.trend" [unit]="kpi.unit"
                                        [maxX]="maxX" [minX]="minX" [wiredTrend]="kpi?.wired" [applicationTrend]="kpi?.application"
                                        [graphHeight]="height" [graphWidth]="width" [nextToTitle]="kpi.dateDisplay"
                                        [marker]="kpi.marker" [isSecondRow]="i > 1" [isResponsive]="isResponsive"
                                        [maxTicksX]="maxTicksX" [maxTicksY]="maxTicksY"
                                        [kpiAnnotation]="annotations? annotations[kpi.type]: null"
                                        [timeDisplayFormat]="timeDisplayFormat" (barHovered)="onBarHovered($event)"
                                        [hideTooltip]="hideTooltip"
                                        [optionsTrend]="kpi.options"  [selectedTrendOption]="kpi.currentOption"
                                        (trendOptionSelected)="onTrendOptionSelected($event)">
      </app-multi-kpi-action-graph-trend>
    </div>
  </ng-container>
</div>

<ng-container *ngIf="!isKpiTrends">
  <div class="error-message-text">
    {{'data.ERRORS.NO_DATA'|translate}}
  </div>
</ng-container>
