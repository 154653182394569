import {Injectable} from '@angular/core';
import {GenericDevice, SingleD3Node, Topology} from "../../../models/topology";
import {BasicLink, SingleLinkStatus, SingleLinkType} from "../../../models/single-link.model";
import {SingleDeviceType} from "../../../models/single-device.model";
import * as uuid from 'uuid';
import {CamelToUpperPipe} from "../../../pipes/camel-to-upper.pipe";


@Injectable({
  providedIn: 'root'
})
export class TopologyZipperService {
  readonly TYPES_TO_BE_ZIPPED = [SingleDeviceType.AccessPoint, SingleDeviceType.UnidentifiedDevice];

  constructor(private camelToUpper: CamelToUpperPipe
  ) {
  }

  /**
   * Return the maximum nodes allowed without zipping
   * @param type
   */
  maxNodeOfSameType(type: SingleDeviceType) {
    switch (type) {
      case SingleDeviceType.AccessPoint: {
        return 5;
      }
      case SingleDeviceType.UnidentifiedDevice: {
        return 3;
      }
    }
  }

  /**
   * Mark nodes and links as part of zipping mechanism
   * @param originalRoot Parent of all topology
   * @param d3TopologyData
   */
  zipTooManyChildren(originalRoot: GenericDevice<any>, d3TopologyData: Topology<GenericDevice<any>, any>) {
    this.zipTopologyForParentWithTooManyChildren(originalRoot, d3TopologyData);
    return d3TopologyData;
  }

  /**
   * Find out if children need to be zipped.
   * If Yes, activate zipping mechanism for nodes and links
   * @private
   */
  private zipTopologyForParentWithTooManyChildren(parent: GenericDevice<any>, newTopology: Topology<GenericDevice<any>, BasicLink>) {
    if (parent?.children && parent.children.length > 0) {
      if (parent.type !== SingleDeviceType.FakeRoot) {
        this.TYPES_TO_BE_ZIPPED.forEach(type => {
          let zippedChildren: GenericDevice<any>[] = [];
          parent.children.forEach(child => {
            if (child.type === type) {
              zippedChildren.push(child);
            }
          });
          if (zippedChildren && zippedChildren.length >= this.maxNodeOfSameType(type)) {
            let zipperNodeId = uuid();
            this.addZipperFlagsToNodes(zippedChildren, newTopology, zipperNodeId, type);
            this.addZipperFlagsToLinks(zippedChildren, newTopology, zipperNodeId, type, parent);
          }
        })
      }
      parent.children.forEach(child => {
        this.zipTopologyForParentWithTooManyChildren(child, newTopology)
      });
    }
  }

  /**
   *
   * Add zipper flag to nodes
   * Add special node as zipper
   * Assign isZipMode for all
   * @private
   */
  private addZipperFlagsToNodes(zippedChildren: GenericDevice<any>[], newTopology: Topology<GenericDevice<any>, BasicLink>, zipperNodeId: any, type: SingleDeviceType) {
    let zipperNode = new GenericDevice<any>(
      zipperNodeId,
      type,
      `${this.camelToUpper.transform(type)}s`,
      zippedChildren
    );
    zipperNode.isZipper = true;
    zipperNode.isZipMode = true;
    newTopology.nodes.push(zipperNode);

    zippedChildren.forEach(child => {
      const childNode = newTopology.nodes.find(node => child.id === node.id);
      if (childNode !== undefined) {
        childNode.isToBeZipped = true;
        childNode.isZipMode = true;
      }
    });
    newTopology.nodes.filter(node => {
      const childNode = zippedChildren.find(child => child.id === node.id);
      if (childNode !== undefined) {
        childNode.isToBeZipped = true;
        childNode.isZipMode = true;
      }
    })
  }

  /**
   * Add zipper flag to links
   * Add special link for zipper node
   * @private
   */
  private addZipperFlagsToLinks(zippedChildren: GenericDevice<any>[], newTopology: Topology<GenericDevice<any>, BasicLink>, zipperNodeId: any, type: SingleDeviceType, parent: GenericDevice<any>) {
    newTopology.links.push({
      id: uuid(),
      startDeviceId: parent.id,
      endDeviceId: zipperNodeId,
      type: SingleLinkType.Zipper,
      status: SingleLinkStatus.OK,
      description: null,
      isZippedMode: true
    });
    newTopology.links.forEach(link => {
      if (link.startDeviceId === parent.id && zippedChildren.find(child => child.id === link.endDeviceId) !== undefined ||
        link.endDeviceId === parent.id && zippedChildren.find(child => child.id === link.startDeviceId) !== undefined) {
        link.type = SingleLinkType.To_Be_Zipped;
        link.isZippedMode = true;
      }
    })
  }

  /**
   * When zipped node is clicked. Update its zip mode and do so for all his children
   * @param parent_id current clicked element id
   * @param d3TopologyData all of the topology data
   * @param toBeChangedIds all of current element children ids and current element own id
   * @param closeOpenZippers is Parent element was clicked (not zipper, but the zipper's parent)
   */
  updateZipMode(parent_id: number | string, d3TopologyData: Topology<GenericDevice<any>, any>, toBeChangedIds?: (string | number)[], closeOpenZippers: boolean = false) {
    let children: GenericDevice<any>[] = [];
    d3TopologyData.nodes.forEach(node => {
      //Change zip mode for all zippers that are in zip mode and children to current element
      if (node.isZipper && closeOpenZippers && !node.isZipMode && node.parent_id === parent_id ||
        //Change zip mode for all zippers and toBeZipped nodes that are children of selected element (including the zipper itself)
        ((node.isZipper && !closeOpenZippers || node.isToBeZipped) && node.parent_id === parent_id && toBeChangedIds.includes(node.id))) {
        node.isZipMode = !node.isZipMode;
        if (node.isToBeZipped) {
          children.push(node);
        }
      }
    });
    d3TopologyData.links.forEach(link => {
      if ((link.type === SingleLinkType.To_Be_Zipped || link.type === SingleLinkType.Zipper)) {
        let linkOtherNodeId: ('startDeviceId' | 'endDeviceId');
        if (link.startDeviceId === parent_id) {
          linkOtherNodeId = 'endDeviceId';
        }
        if (link.endDeviceId === parent_id) {
          linkOtherNodeId = 'startDeviceId';
        }
        if (linkOtherNodeId !== undefined) {
          const parentNode = d3TopologyData.nodes.find(node => node.id === parent_id);

          if (parentNode !== undefined && parentNode.parent_id !== link[linkOtherNodeId]) {
            link.isZippedMode = !link.isZippedMode;
          }
        }

      }
    });
    children.forEach(child => {
      this.updateZipMode(child.id, d3TopologyData);
    });
  }

  /**
   * Return true for the following:
   * 1. true if node is not part of zipping mechanism
   * 2. true if node is zipper and we are in zip mode
   * 3. true if node isToBeZipped and we are not in zip mode
   */
  isDisplayNode(node: GenericDevice<any>, nodes: GenericDevice<any>[]) {
    const parent = nodes.find(n => n.id == node.parent_id);
    return (!parent || !(parent.isToBeZipped && parent.isZipMode)) && ((!node.isToBeZipped && !node.isZipper) || (node.isToBeZipped && !node.isZipMode || node.isZipper && node.isZipMode));
  }

  /**
   * Return true for the following:
   * 1. true if link is not of toBeZipped type
   * 2. true if link is of ToBeZipped type and zipMode is off
   * 3. true if link is a zipper an zip mode it on
   * @param link
   */
  isDisplayLink(link: any) {
    return (link as BasicLink).type !== SingleLinkType.To_Be_Zipped ||
      (link as BasicLink).type === SingleLinkType.To_Be_Zipped && !link.isZippedMode ||
      (link as BasicLink).type === SingleLinkType.Zipper && link.isZippedMode
  }
}
