<app-netop-dialog>
    <app-netop-dialog-title (close)="closeDialog()">
        Clone
    </app-netop-dialog-title>
    <app-netop-dialog-content [cssClass]="'clone-dialog-content'">
        <form [formGroup]="cloneForm">
            <mat-form-field class="mat-form-field-fluid clone-search-input">
                <mat-label>Find backups by serial number</mat-label>
                <input matInput type="text" formControlName="serial">
                <span class="clone-search-icon" [inlineSVG]="'assets/media/netop/search.svg'"></span>
            </mat-form-field>

            <mat-selection-list class="selection-list" (selectionChange)="onSelectionChanges($event)"
                formControlName="backup">
                <ng-container *ngIf="isInput">
                    <mat-list-option *ngFor="let backup of backups" [checkboxPosition]="'before'"
                        [selected]="backup.fileName == selectedBackups" [value]="backup">
                        <div class="align-items-center">
                            <span class="mr-3">{{backup.date}}</span>
                            <span class="file-name">{{backup.fileName}}</span>
                        </div>
                    </mat-list-option>
                </ng-container>
            </mat-selection-list>

            <div class="mb-3 mt-3 clone-radio">
                <mat-radio-group class="m-3" formControlName="deploy">
                    <mat-radio-button value="now" [checked]="isNow">
                        Clone Now
                    </mat-radio-button>
                    <mat-radio-button value="later">
                        Clone Later
                        <mat-icon class="ml-1" mat-list-icon svgIcon="later"></mat-icon>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="date-inputs mb-3" *ngIf="isLater">
                <mat-form-field class="mat-form-field-fluid mr-5">
                    <input matInput type="date" formControlName="deployDate">
                </mat-form-field>
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput type="time" formControlName="deployHour">
                </mat-form-field>
            </div>
        </form>


    </app-netop-dialog-content>

    <app-netop-dialog-actions>
        <!--buttons-->
        <div class="d-flex justify-content-end kt-margin-r-10">
            <mat-dialog-actions>
                <app-buttons (click)="closeDialog()" [btnVariant]="'cancel'" [btnStyle]="'primary'" [btnClass]=""
                    [btnText]="'data.COMMON.CANCEL'">
                </app-buttons>
                <app-buttons class="kt-margin-l-10" (click)="closeDialog()" [btnClass]="" [btnText]="'Clone'">
                </app-buttons>
            </mat-dialog-actions>
        </div>
    </app-netop-dialog-actions>

</app-netop-dialog>