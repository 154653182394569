import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { SyncChangesModalComponent } from 'src/app/shared/modals/sync-changes-modal/sync-changes-modal.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ChangeLog, SingleChangeLog, ChangeType, AggregateLogChangeTypes, AggregatedChangedEntitites } from 'src/app/shared/models/change-log.model';
import { ChangesFlatService } from './changes-flat.service';

@Component({
  selector: 'app-sync-changes-renderer',
  templateUrl: './sync-changes-renderer.component.html',
  styleUrls: ['./sync-changes-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SyncChangesRendererComponent implements ICellRendererAngularComp, OnDestroy {
  changes: AggregateLogChangeTypes;
  changesEntityArray: { key: string, iconName: string }[] = [];
  changesTypeArray: string[] = [];
  changeType: string;
  rowData: ChangeLog;

  constructor(public dialog: MatDialog, private changesFlatService: ChangesFlatService) { }

  agInit(params: ICellRendererParams): void {
    this.changes = (params.data[params.colDef.field] as AggregateLogChangeTypes);
    this.rowData = params.data;
    if (this.changes) {
      this.changeType = params.colDef.field.toLowerCase();
      this.initateChangeEntitiesArray();
      this.changesTypeArray = Object.keys(ChangeType).map(key => key.toLowerCase());
    }
  }

  /**
   * Generate the enrtity array.
   * For each entity assign object with entity name and iconName
   */
  initateChangeEntitiesArray() {
    Object.keys(this.changes).forEach(key => {
      switch (key.toLowerCase()) {
        case AggregatedChangedEntitites.Device.toLowerCase():
          this.changesEntityArray.push({ key: key, iconName: "device" });
          break;
        case AggregatedChangedEntitites.SSID.toLowerCase():
          this.changesEntityArray.push({ key: key, iconName: "access-point" });
          break;
        case AggregatedChangedEntitites.VLAN.toLowerCase():
          this.changesEntityArray.push({ key: key, iconName: "fabric" });
          break;
        case AggregatedChangedEntitites.SwitchStack.toLowerCase():
          this.changesEntityArray.push({ key: key, iconName: "switch-stack" });
          break;
        default:
          break;
      }
    })
  }

  openDetailedChangesDialog(changes: SingleChangeLog[]) {
    let dialogWidth: number = 1200;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = changes.length >= 2 ? '650px' : '550px';
    dialogConfig.width = dialogWidth + 'px';
    dialogConfig.panelClass = "no-dialog-overflow";
    dialogConfig.data = { rowData: this.rowData, changes: this.changesFlatService.flatChangesArray(changes) };
    //dialogConfig.data = { rowData: this.rowData, changes: changes };
    this.dialog.open(SyncChangesModalComponent, dialogConfig);
  }

  get areChanges() {
    if (this.changes)
      return this.changes.Device.length > 0 || this.changes.Ssid.length > 0 || this.changes.Vlan.length > 0 || this.changes.SwitchStack.length > 0;
  }
  refresh(params: any): boolean {
    return false;
  }
  ngOnDestroy(): void {
  }

}
