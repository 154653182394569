import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { UnitUtils } from 'src/app/shared/global-utils/unit';
import { VPNTraffic } from 'src/app/shared/models/vpn.model';

@Component({
  selector: 'app-usage-as-cell-renderer',
  templateUrl: './usage-cell-renderer.component.html',
  styleUrls: ['./usage-cell-renderer.component.scss']
})
export class UsageCellRendererComponent implements ICellRendererAngularComp {
  usage: VPNTraffic;

  constructor() { }

  refresh(params: any): boolean {
    return false;
  }

  agInit(params: UsageCellRendererParams): void {
    this.usage = params?.data.traffic;
  }

  getUnits(value: number): string {
    return UnitUtils.formatBytes(value).toLocaleString();
  }
}

export interface UsageCellRendererParams extends ICellRendererParams {
  usage: (row: any) => VPNTraffic;
}
