import {Component, OnInit, Inject} from '@angular/core';
import {generateNumbersArray, sortArrayByParam} from '../../operators/array.operator';
import {FormControl, FormGroup, FormBuilder} from '@angular/forms';
import {TimeManagerService} from '../../services/time-manager.service';
import {DeviceBackupDTO, DeviceBackupHistoryDTO, RestoreBackupHistory} from '../../models/device-backup.model';
import {DevicesService} from '../../services/rest-services/devices.service';
import {catchError, take} from 'rxjs/operators';
import {of} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Bandwidth} from "../../models/bandwidth.model";
import {CheckboxField, CheckBoxListItem} from "../../components/checkbox-search-list/checkbox-list.model";

@Component({
  selector: 'app-restore',
  templateUrl: './restore.component.html',
  styleUrls: ['./restore.component.scss']
})
export class RestoreComponent implements OnInit {
  restoreForm: FormGroup;
  options: CheckBoxListItem<RestoreBackupHistory>[];
  disaplayedFields: CheckboxField[] = [{fieldData: [{fieldName: 'date'}]}, {fieldData: [{fieldName: 'fileName'}]}];
  optionsListControl: FormControl = new FormControl();
  searchFocus: boolean;
  isLoading: boolean = false;
  backupHisotory: DeviceBackupHistoryDTO[];

  constructor(private matDialogRef: MatDialogRef<RestoreComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData: DeviceBackupDTO,
              private fb: FormBuilder,
              private deviceService: DevicesService,
              private dateConversionService: TimeManagerService) {
  }

  ngOnInit() {
    if (this.dialogData) {
      this.getBackupData();
      this.initateForm();
    }
  }

  getBackupData() {
    this.isLoading = true;
    this.deviceService.getDeviceBackupHistory(this.dialogData.deviceId).pipe(
      take(1),
      catchError(error => {
        this.isLoading = false;
        return of(error);
      })
    ).subscribe(backupHistory => {
      this.isLoading = false;
      if (backupHistory) {
        this.backupHisotory = backupHistory;
        this.initateArray();
      }
    });
  }

  initateForm() {
    this.restoreForm = this.fb.group({
      deploy: new FormControl('now'),
      deployDate: new FormControl(''),
      deployHour: new FormControl(''),
    });
  }

  initateArray() {
    this.options = this.backupHisotory.map(backup => {
      return {
        data: {
          date: this.dateConversionService.dateByFormat(backup.ranAt, 'DD-MMM-YYYY HH:mm'),
          unFormatted: backup.ranAt,
          fileName: `${backup.firmWareVersion}_${backup.ranAt}`
        }
      } as CheckBoxListItem<RestoreBackupHistory>;

    }).sort(sortArrayByParam('unFormatted', 'desc'));
  }

  closeDialog() {
    this.matDialogRef.close();
  }

  get isLater() {
    if (this.restoreForm) {
      return this.restoreForm.controls['deploy'].value == 'later';
    }
  }

  get isNow() {
    if (this.restoreForm) {
      return this.restoreForm.controls['deploy'].value == 'now';
    }
  }

}
