import { Injectable } from '@angular/core';
import { EditAbleFabricKeysValues, EditableFabricProperties } from '../models/editableFabricKeys';
import { BehaviorSubject } from 'rxjs';
import { FormAction } from '../../../modals/models/form-actions.model';
import { ModalTextContent } from '../../../modals/models/modal-content.model';
import { TranslateService } from '@ngx-translate/core';
import { SaveEditedPropertiesComponent } from '../../../modals/save-edited-properties/save-edited-properties.component';
import { EditAbleKeys } from '../models/editableKeys';
import { SourceChanged } from '../models/source-changes';
import { ConfigurationEntitiyType } from '../models/changed-properties';
import { EditStringsService } from '../../../services/strategies/edit-strings.service';
import { EntityWithPolicies } from '../../../models/policies.model';
import {FabricKpiData} from "../../../models/fabrics.model";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";

@Injectable({
  providedIn: 'root'
})
export class EditPropertiesService {
  editAbleFabricKeysValues: EditAbleFabricKeysValues;
  fabricIds: { fabricId: number, ssidId: number, vlanId: number }

  private notifyFormActionStatus: BehaviorSubject<FormAction> = new BehaviorSubject(null);
  notifyFormActionStatusObservable$ = this.notifyFormActionStatus.asObservable();
  private notifyIsEditMode: BehaviorSubject<boolean> = new BehaviorSubject(null);
  notifyIsEditModeObservable$ = this.notifyIsEditMode.asObservable();
  private notifyFormValid: BehaviorSubject<boolean> = new BehaviorSubject(null);
  notifyFormValidObservable$ = this.notifyFormValid.asObservable();

  constructor(
    private editStringsService: EditStringsService,
    public dialog: MatDialog,
    private translate: TranslateService
  ) { }

  isFabricPropertyIsEditable(propKey: string, itemKey: string) {
    let key;
    if (propKey) {
      key = this.editStringsService.getformattedPropKey(propKey).toLowerCase();
    }
    let isEditable = false;
    if (this.editAbleFabricKeysValues) {
      if (itemKey && itemKey.toLowerCase() == EditableFabricProperties.SSIDS || itemKey.toLowerCase() == EditableFabricProperties.VLANS) {
        this.editAbleFabricKeysValues.getPropertyKeys(itemKey).forEach(editValue => {
          if (key == editValue.toLowerCase()) {
            isEditable = true;
          }
        })
      }
    }
    return isEditable;

  }

  /**
   * @method setFabricIdsForEditing Initiate the ids for edited properties object
   */
  setFabricIdsForEditing(fabric: EntityWithPolicies<FabricKpiData>) {
    if (fabric.entityData.fabric.id && (fabric.entityData.fabric.ssids.length > 0 || fabric.entityData.fabric.vlans.length > 0))
      this.fabricIds = {
        fabricId: fabric.entityData.fabric.id,
        ssidId: fabric.entityData.fabric.ssids[0] ? fabric.entityData.fabric.ssids[0]["id"] : null,
        vlanId: fabric.entityData.fabric.vlans[0] ? fabric.entityData.fabric.vlans[0]["id"] : null
      }
  }

  /**
   * @method saveEditedPropertiesStatus Save the user selection on the save edited properties modal.
   * The properties content component will use this observable in order to control the datat that being displayed
   */
  saveFormActionStatus(formAction: FormAction) {
    this.notifyFormActionStatus.next(formAction);
  }

  /**
   * @method isEditMode Inilitze the 'notifyIsEditMode' with the current edit mode.
   * @param isEditMode Current edit mode
   */
  isEditMode(isEditMode: boolean) {
    if (isEditMode)
      this.notifyIsEditMode.next(true);
    if (!isEditMode)
      this.notifyIsEditMode.next(false);
  }

  /**
   * @method isFormvalid Inilitze the 'notifyIsEditMode' with the current edit mode.
   * @param isFormValid Current form validation mode
   */
  isFormValid(isFormValid: boolean) {
    if (isFormValid)
      this.notifyFormValid.next(true);
    if (!isFormValid)
      this.notifyFormValid.next(false);
  }

  /**
   * @method openLeaveFormDialog Return opened dialog ref when the user click on element that will cause the deletion of form changes
   */
  openLeaveFormDialog() {
    let dialogConfig = new MatDialogConfig();
    let type = FormAction.LEAVE;
    let modalText: ModalTextContent = {
      title: this.translate.instant('data.PROPERTIES.LEAVING_FORM_TITLE'),
      message: this.translate.instant('data.PROPERTIES.LEAVING_FORM_MESSAGE')
    }
    dialogConfig.height = '357';
    dialogConfig.width = '648px';
    dialogConfig.data = {
      type: type,
      data: modalText
    }
    return this.dialog.open(SaveEditedPropertiesComponent, dialogConfig)
  }

  /**
   * @method prepareChangePropertiesForServer Return array of changed properties.
   * @param event The current event with the source VS changes objects
   * @param editAbleKeysValues The current editable object with keys and values
   */
  prepareChangePropertiesForServer(event: SourceChanged, editAbleKeysValues: EditAbleKeys) {
    let changedProperties = [];
    editAbleKeysValues.getPropertiesList().forEach(prop => {
      let changedValues = {};
      editAbleKeysValues.getPropertyKeys(prop).forEach(propKey => {
        for (let [key, value] of Object.entries(event.changed)) {
          if (prop + propKey == key && event.source[key] != value) {
            changedValues[propKey] = value;
          }
        }
      })

      /**
       * The next condition check if changed values has values
       */
      if (Object.entries(changedValues).length > 0)
        changedProperties.push({
          //ssids and vlans arrive with "s" at the from the server but being sent without it
          //Therefor, there is need to remove the last char
          configurationEntityType: ConfigurationEntitiyType[prop.substring(0, prop.length - 1).toUpperCase()],
          configurationEntityId: prop == EditableFabricProperties.SSIDS ? this.fabricIds.ssidId : this.fabricIds.vlanId,
          properties: changedValues
        })
    })
    return changedProperties;
  }
}
