import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormControl} from "@angular/forms";
import {alphaNumericWithSpecialCharsValidator} from "../../form-validators/form-validators";
import {ERRORS_NAME} from "../../form-validators/form-model";

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss']
})
export class InputDialogComponent implements OnInit {
  text: string;
  formControl = new FormControl('', alphaNumericWithSpecialCharsValidator(ERRORS_NAME.FORBIDDEN_CHARS
  ))
  constructor(private matDialogRef: MatDialogRef<InputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { title: string, explain: string, hint: string }) { }

  ngOnInit() {
  }

  onKey(event: any) {
    this.text = event.target.value;
  }

  get dialogTitle() {
    return this.dialogData && this.dialogData.title;
  }
  get dialogExplain() {
    return this.dialogData && this.dialogData.explain;
  }
  get dialogHint() {
    return this.dialogData && this.dialogData.hint;
  }
  closeDialog() {
    this.matDialogRef.close();
  }
  saveInput() {
    this.matDialogRef.close(this.text);
  }

}
