import {ChangeDetectorRef, Component, Inject} from '@angular/core';
import {
  ActionAdditionalData,
  ActionStatus,
  EntityAction,
  InsightSummary,
  VpnActionAdditionalData
} from '../../models/actions.model';
import {LegacySeverity} from '../../models/severity.model';
import {KpiGroupedBy, KpiTrend, KpiTrendDisplay, KpiType, StackedKpiData, TrafficUnits} from '../../models/kpi.model';
import {dialogType} from '../../components/single-entities/models/single-entity-dialog';
import {catchError, share, take} from 'rxjs/operators';
import {MultiKpiChartsService} from '../../components/chartjs-components/services/multi-kpi-charts.service';
import {GraphTrendMarker} from '../../components/chartjs-components/models/chart-js-events.model';
import {BandwidthService} from '../../services/rest-services/bandwidth.service';
import {FULL_COLORS_LIST} from '../../global-utils/colors-utils';
import {ChartJsAnnotation} from '../../components/chartjs-components/models/annotations.model';
import {ChartjsKpiAnnotationGeneratorService} from '../../components/chartjs-components/services/chartjs-kpi-annotation-generator.service';
import {TrafficService} from '../../services/rest-services/traffic.service';
import {BaseExpendedKpiDisplay} from '../../components/kpi-display-components/base-expended-kpi-display';
import {ActionKpiDialogData} from './action-kpi-dialog.model';
import {TimeManagerService} from '../../services/time-manager.service';
import {EntityType} from '../../models/entity-type.enum';
import {RestKpiGeneratorService} from '../../components/kpi-display-components/services/rest-kpi-generator.service';
import {KpiDisplayDataGeneratorService} from '../../components/kpi-display-components/services/kpi-display-data-generator.service';
import {DeviceIssuesGridService} from '../../components/dynamic-dashboard/components/insights-anomalies/anomalies-grids/components/device-issues-grid/device-issues-grid.service';
import {AnomalyCategory} from '../../models/anomalies.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatButtonToggleChange} from '@angular/material/button-toggle';
import {VpnConnectionsService} from '../../../venues/venues-single-components/venue-vpns/components/vpn-connectivity/vpn-connections.service';
import {HorizontalTimelineMarker} from '../../components/horizontal-timeline/models/horizontal-time-span.model';
import {ActionKpiAnalyzeService} from './action-kpi-analyze.service';
import {InsightSummaryDisplay} from '../../ag-grid/cell-renderers/analyze-pop-over/models/analyze-pop-over.model';
import {CamelToUpperPipe} from '../../pipes/camel-to-upper.pipe';
import {
  InsightResolutionActions,
  InsightResolutionActionsMode
} from '../../components/kpi-display-components/insight-resolutions-display/insight-resolution-display.model';
import {forkJoin, Observable, of} from 'rxjs';
import {VerticalTrend} from '../kpis-trends/single-kpi-display/single-kpi-display.component';
import {
  TrendOptionsDto
} from '../../components/kpi-display-components/multi-kpi-action-graph-trend/multi-kpi-action-graph-trend.model';
import {InsightsService} from "../../services/rest-services/insights.service";
import {Entity} from "../../models/entity.model";
import {TimeRange, TimeRangeUnit, TimeUnit} from "../../models/time.model";
import {HORIZONTAL_CHART_JS_ANNOTATION} from "../../components/chartjs-components/models/chart-js-injection-token";

@Component({
  selector: 'app-action-kpi-dialog',
  templateUrl: './action-kpi-dialog.component.html',
  styleUrls: ['./action-kpi-dialog.component.scss']
})
export class ActionKpiDialogComponent extends BaseExpendedKpiDisplay {
  public action: EntityAction;
  public severity = LegacySeverity;
  severityText: string;
  category: string;
  ActionStatus = ActionStatus;
  selectedActionData: { dialogType?: dialogType, actionId: number, step: string };
  KpiType = KpiType;
  graphTrendHeight: number;
  graphTrendWidth: number;
  severityAsNumber: number;
  VerticalTrend = VerticalTrend;
  minX: number;
  maxX: number;
  groupedBy = KpiGroupedBy.Application;
  timeRange = TimeRange;
  selectedRange: TimeRange = TimeRange.XNarrow;
  timeSpans = [];
  sparkline = {datasets: [], labels: []};
  visualizationTrafficUnit: { unit: TrafficUnits } = {unit: TrafficUnits.KB};
  connectionFailureTrend$: Observable<KpiTrend | StackedKpiData[]>;

  private markerData: HorizontalTimelineMarker;
  summary: InsightSummary[];
  rootCauses: string [] = [];
  solutions: string[] = []
  insightSummary: InsightSummaryDisplay[] = [];
  icons: InsightResolutionActionsMode[] = [{action: InsightResolutionActions.FIX, isDisabled: true}];

  private actionAdditionalData: ActionAdditionalData;

  constructor(private matDialogRef: MatDialogRef<ActionKpiDialogComponent>,
              private multiKpiChartsService: MultiKpiChartsService,
              private deviceIssuesGridService: DeviceIssuesGridService,
              private actionKpiAnalyzeService: ActionKpiAnalyzeService,
              private camelToUpperPipe: CamelToUpperPipe,
              protected trafficService: TrafficService,
              protected bandwidthService: BandwidthService,
              protected insightsService: InsightsService,
              protected annotationGenerator: ChartjsKpiAnnotationGeneratorService,
              protected timeManagerService: TimeManagerService,
              protected restKpiGeneratorService: RestKpiGeneratorService,
              protected kpiDisplayDataGeneratorService: KpiDisplayDataGeneratorService,
              private vpnConnectionsService: VpnConnectionsService,
              protected cdr: ChangeDetectorRef,
              @Inject(FULL_COLORS_LIST) readonly fullColorsList: string[],
              @Inject(HORIZONTAL_CHART_JS_ANNOTATION) readonly defaultAnnotation: ChartJsAnnotation,
              @Inject(MAT_DIALOG_DATA) public dialogData: ActionKpiDialogData) {
    super(trafficService, bandwidthService, annotationGenerator, timeManagerService, restKpiGeneratorService, kpiDisplayDataGeneratorService, insightsService, cdr, fullColorsList, defaultAnnotation)
    if (this.dialogData.action) {
      this.severityAsNumber = typeof (this.dialogData.action.severity) === 'number' ? +this.dialogData.action.severity :
        +LegacySeverity[this.dialogData.action.severity];
      this.graphTrendHeight = this.dialogData.graphTrendHeight;
      this.graphTrendWidth = this.dialogData.graphTrendWidth;
      this.action = this.dialogData.action;
      this.summary = this.action.summary;
      this.rootCauses = this.action.possibleRootCauses;
      this.solutions = this.action.manualSolutions;
      this.generateSummaryDisplay();
      this.action.severity = this.severityAsNumber;
      this.severityText = LegacySeverity[this.severityAsNumber] + ' Risk';
      this.category = this.action.category.toLowerCase();
      this.actionAdditionalData = this.action.additionalData;
      this.currentEntity = {
        id: this.action.venueId,
        name: '',
        type: EntityType.VENUE
      }
      this.initializeCurrentDateByActionAndRange();
    }
  }

  ngOnInit(): void {
    this.loadData();
  }

  /**
   * Load data for action kpi dialog display
   * @private
   */
  private loadData() {
    this.loadTopDataForVisualization();
    this.getKpiTrendData();
    this.getDataTimeVisualizations();
  }

  /**
   * Update 3 things
   * 1. Annotation for Traffic chart
   * 2. The kpi Trend Array
   * 3. MaxX and minX values
   */
  getKpiTrendData() {
    if (this.action) {
      this.isLoading = true;
      const entity: Entity = {
        type: EntityType.TENANT,
        id: this.action.tenantId,
        name: ''
      }
      const kpiWithThresholds = forkJoin({
        kpiData: this.restKpiGeneratorService.getKpiTrendsByIds(this.action.entityType, this.action.entityId, this.action.tenantId, this.fromDate, this.currentDateIsoFormat.end, this.groupedBy, EntityType.VENUE, this.action.venueId, this.selectedTimeUnit),
        bandwidthData: this.bandwidthService.getTenantVenuesBandwidthById(this.action.tenantId),
        latencySettings: this.insightsService.getTenantAnomalySettings(entity, AnomalyCategory.Latency),
        lossSettings: this.insightsService.getTenantAnomalySettings(entity, AnomalyCategory.PacketLoss)
      });
      kpiWithThresholds.pipe(
        take(1),
        catchError(error => {
          this.isLoading = false;
          return of(null);
        })
      ).subscribe({
        next: kpiAndThreshold => {
          this.isLoading = false;
          if (kpiAndThreshold) {
            const [latencyTrend, lossTrend, usersTrend, trafficSplitTrend, throughputSplitTrend, applicationTraffic, wiredTraffic] = kpiAndThreshold.kpiData;
            this.bandwidth = this.annotationGenerator.findVenueBandwidth(kpiAndThreshold.bandwidthData, this.action.venueId);
            this.unit = trafficSplitTrend[0].unit;
            this.lossSettings = kpiAndThreshold.lossSettings;
            this.latencySettings = kpiAndThreshold.latencySettings;
            this.generateAnnotations();
            let trafficOptionsTrend: TrendOptionsDto = {
              'throughput': {
                option: 'throughput',
                index: 0,
                data: throughputSplitTrend,
                type: KpiType.Throughput,
                unit: throughputSplitTrend[0].unit,
                graphTitle: this.multiKpiChartsService.setKpiTitleWithUnits(KpiType.Throughput, throughputSplitTrend[0].unit),
                marker: this.getTrendMarker(throughputSplitTrend)
              },
              'traffic': {
                option: 'traffic',
                index: 0,
                data: trafficSplitTrend,
                type: KpiType.Traffic,
                unit: trafficSplitTrend[0].unit,
                graphTitle: this.multiKpiChartsService.setKpiTitleWithUnits(KpiType.Traffic, trafficSplitTrend[0].unit),
                marker: this.getTrendMarker(trafficSplitTrend)
              }
            };
            let currentTrafficOption = this.multiKpiChartsService.getCurrentOptionTrend(trafficOptionsTrend);
            let trafficTrendDisplay = new KpiTrendDisplay(currentTrafficOption.type, currentTrafficOption.data,
              currentTrafficOption.marker, applicationTraffic, wiredTraffic, currentTrafficOption.unit,
              trafficOptionsTrend, currentTrafficOption.option);

            this.kpiTrends = [
              trafficTrendDisplay,
              new KpiTrendDisplay(KpiType.Clients, usersTrend, this.getTrendMarker(usersTrend)),
              new KpiTrendDisplay(KpiType.Latency, latencyTrend, this.getTrendMarker(latencyTrend)),
              new KpiTrendDisplay(KpiType.Loss, lossTrend, this.getTrendMarker(lossTrend)),
            ];
          }
        }
      })
    }
  }

  /**
   * Return Different Button variant for Ignore Type
   * @param step The current step
   */
  getActionButtonVariant(step: ActionStatus) {
    if (step == ActionStatus.Ignore) {
      return 'cancel';
    }
    return 'raised';
  }

  /**
   * Initiate marker for each trend. find the closet point to the action updated at date and set it as marker
   */
  getTrendMarker(trend: any): GraphTrendMarker {
    if (this.action) {
      return this.multiKpiChartsService.generateMarker(trend, this.action.updatedAt);
    }
  }

  /**
   * 1. Assign selectedRange
   * 2. Assign currentDate
   * 3. Reload data
   */
  onTimeToggleChanged(event: MatButtonToggleChange) {
    this.selectedRange = event.value;
    this.initializeCurrentDateByActionAndRange();
    this.loadData();
  }

  /**
   * Return True if the current action status is "Complete", i.g., user does not need to decide what to do next
   */
  get isStatusComplete() {
    return this.action && this.action.status == ActionStatus.Complete;
  }

  onAction(step: ActionStatus) {
    this.selectedActionData = {dialogType: dialogType.actionKpiCharts, actionId: this.action.id, step: step};
    this.closeDialog();
  }

  closeDialog() {
    this.matDialogRef.close(this.selectedActionData ? this.selectedActionData : null);
  }

  private generateSummaryDisplay() {
    if (this.summary) {
      this.summary.forEach(insight => {
        this.insightSummary.push(
          new InsightSummaryDisplay(`severity-${insight.severity.toString().toLowerCase()}`, this.camelToUpperPipe.transform(insight.category), insight.shortDesc)
        )
      })
    }
  }

  get actionsClass() {
    return this.action && this.action.nextPossibleStates && this.action.nextPossibleStates.length > 0 ?
      'single-action-button-container single-action-button-container-with-action' :
      'single-action-button-container'
  }

  /**
   * Return number of dayes for each range
   */
  get daysByRange() {
    switch (this.selectedRange) {
      case TimeRange.Wide:
        return 3;
      case TimeRange.Narrow:
        return 1;
      case TimeRange.XNarrow:
        return 1;
      default:
        return 1;
    }
  }

  get selectedTimeUnit(): TimeUnit {
    switch (this.selectedRange) {
      case TimeRange.Wide:
        return TimeUnit.DAYS;
      case TimeRange.Narrow:
        return TimeUnit.DAYS;
      case TimeRange.XNarrow:
        return TimeUnit.HOURS;
      default:
        return TimeUnit.HOURS;
    }
  }

  /**
   * Return the days number from the current date end date
   */
  get fromDate(): number {
    if (this.action)
      return this.kpiDisplayDataGeneratorService.calculateFromDateByActionRange(new Date(this.action.updatedAt), new Date(this.currentDateIsoFormat.end), this.daysByRange, this.selectedTimeUnit);
  }

  private initializeCurrentDateByActionAndRange() {
    this.currentDateIsoFormat = this.timeManagerService.getStartEndDatesByRange(this.daysByRange, new Date(this.action.updatedAt),
      this.selectedTimeUnit, true);
    this.currentDate = this.timeManagerService.getStartEndDatesByRange(this.daysByRange, new Date(this.action.updatedAt),
      this.selectedTimeUnit, false);
  }

  private getDataTimeVisualizations() {
    this.timeSpans = [];
    this.sparkline = {datasets: [], labels: []};
    if (this.isDataVisualizationDisplay) {
      switch (this.action.anomalyCategory) {
        case AnomalyCategory.VpnStatus:
          // we need here the vpn connection id instead of entityId
          this.vpnConnectionsService.getConnectionStatusTrend((<VpnActionAdditionalData>this.action.additionalData).vpnConnectionId, this.timeSpans, this.cdr, this.action.tenantId, this.currentDateIsoFormat.end, this.fromDate);
          break;
        case AnomalyCategory.VpnTraffic:
          // we need here the vpn connection id instead of entityId
          this.vpnConnectionsService.getConnectionStatusTraffic((<VpnActionAdditionalData>this.action.additionalData).vpnConnectionId, this.sparkline, this.cdr, this.action.tenantId, this.currentDateIsoFormat.end, this.fromDate, this.visualizationTrafficUnit);
          break;
        case AnomalyCategory.Devices:
          this.actionKpiAnalyzeService.generateDeviceTrafficTimeSpan(this.action.entityId, this.timeSpans, this.cdr, this.currentDateIsoFormat.end, this.fromDate);
          break;
        case AnomalyCategory.ConnectionFailures:
          this.connectionFailureTrend$ = this.actionKpiAnalyzeService.generateConnectionFailureTrend(this.action.entityId, this.action.entityType, this.action.tenantId, KpiGroupedBy.Failure_Step, this.currentDateIsoFormat.end, this.fromDate, this.cdr).pipe(share());
          break;
        default:
          break;
      }
    }
  }

  get dataTimePrefix() {
    if (this.timeSpans && this.timeSpans.length > 0 || this.sparkline && this.sparkline.datasets.length > 0) {
      return this.actionKpiAnalyzeService.generatePrefix(this.action, this.visualizationTrafficUnit);
    }
  }

  get isDataVisualizationDisplay() {
    return this.action && this.actionKpiAnalyzeService.getVisualizationView(this.action.anomalyCategory);
  }

  get isSummary() {
    return this.summary && this.summary.length > 0;
  }

  get isConnectionFailureDisplay() {
    return this.action && this.action.anomalyCategory === AnomalyCategory.ConnectionFailures;
  }

}
