import { Component, OnInit, OnDestroy } from '@angular/core';
import { INoRowsOverlayAngularComp, ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { LoggerService, Logger } from 'src/app/shared/services/logger.service';

@Component({
  selector: 'app-issues-category-renderer',
  templateUrl: './issues-category-renderer.component.html',
  styleUrls: ['./issues-category-renderer.component.scss']
})
export class IssuesCategoryRendererComponent implements ICellRendererAngularComp, OnDestroy {
  categoryName: string;
  category: number;
  private logger: Logger;

  constructor(private loggerFactory: LoggerService) {
    this.logger = this.loggerFactory.getLogger("IssuesCategoryRendererComponent");
  }

  agInit(params: ICellRendererParams): void {
    if (params && params.data) {
      this.categoryName = params.data.categoryName;
      this.category = params.data.category.toLowerCase();
    }
    this.logger.debug(`category ${this.category} categoryName ${this.categoryName}`)
    //console.log("params in category renderer %o", params)
  }
  refresh(params: any): boolean {
    return false;
  }
  ngOnDestroy(): void {
  }
}
