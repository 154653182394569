import {Component, ViewChild} from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {MatDialog} from "@angular/material/dialog";
import {BaseAnalyzeCellRenderer} from "./base-analyze-cell-renderer";
import {InsightSummaryDisplay} from "./models/analyze-pop-over.model";
import {MatMenuTrigger} from "@angular/material/menu";
import {InsightSummary} from "../../../models/actions.model";
import {CamelToUpperPipe} from "../../../pipes/camel-to-upper.pipe";
import {
  InsightResolutionActions,
  InsightResolutionActionsMode
} from "../../../components/kpi-display-components/insight-resolutions-display/insight-resolution-display.model";
import {AnomalyCategory} from "../../../models/anomalies.model";
import {splitString} from "../../../operators/string-opertators";

@Component({
  selector: 'app-analyze-pop-over',
  templateUrl: './analyze-pop-over.component.html',
  styleUrls: ['./analyze-pop-over.component.scss']
})
export class AnalyzePopOverComponent extends BaseAnalyzeCellRenderer implements ICellRendererAngularComp {
  content: string;
  title: string;
  summary: InsightSummary[];
  rootCauses: string [] = [];
  solutions: string[] = []
  insightSummary: InsightSummaryDisplay[] = [];
  markerTooltipData: string;
  mainData: string;
  icons: InsightResolutionActionsMode[] = InsightResolutionActions.toArray();
  readonly SHORT_DESC_FOR_INCOMPLETE_FABRIC: string = 'Incomplete Configuration detected';
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(public dialog: MatDialog, private camelToUpperPipe: CamelToUpperPipe) {
    super(dialog)
  }

  ngOnInit(): void {
  }

  agInit(params: ICellRendererParams): void {
    if (params && params.data) {
      this.params = params;
      this.content = params.getValue();
      this.action = params.data.hasOwnProperty('action') ? params.data.action : params.data;
      if (this.action.anomalyCategory === AnomalyCategory.FabricAnomaly) {
        this.icons.forEach(icon => icon.isDisabled = true);
      }
      this.rootCauses = this.action.possibleRootCauses;
      this.solutions = this.action.manualSolutions;
      this.summary = this.action.summary;
      if (this.action.anomalyCategory === AnomalyCategory.FabricAnomaly) {
        this.summary[0].shortDesc = this.SHORT_DESC_FOR_INCOMPLETE_FABRIC;
      }

      this.generateSummaryDisplay();
      this.title = `Insights For ${this.action.name}`;
      this.loadTooltipAsCellRendererParams(params);
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  onActionSelected(action: InsightResolutionActions) {
    if (action === InsightResolutionActions.ANALYZE) {
      this.closeMenu();
      this.openDialog();
    }
  }

  private generateSummaryDisplay() {
    if (this.summary) {
      this.summary.forEach(insight => {
        this.insightSummary.push(
          new InsightSummaryDisplay(`severity-${insight.severity.toString().toLowerCase()}`, this.camelToUpperPipe.transform(insight.category), insight.shortDesc)
        )
      })
    }
  }

  closeMenu() {
    this.trigger.closeMenu();
  }

  /**
   * Imry - 13.4.21: This method usage is for Demo Only.
   * Is sole purpose is to display TooltipAsCellRendererComponent view for a specific and predefined venue ID
   * After it complete its mission this code and all its related params and templates should be deleted
   * @private
   */
  private loadTooltipAsCellRendererParams(params: ICellRendererParams) {
    if (params) {
      this.markerTooltipData = params.value;
      this.mainData = params.value;
    }
  }

  get isHasInsights() {
    return this.summary && this.summary.length > 0 || this.rootCauses && this.rootCauses.length > 0 || this.solutions && this.solutions.length > 0;
  }

  get delimitedValues(): string[] {
    return splitString(this.content);
  }
}
