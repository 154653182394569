<div *ngIf="options && options.length > 0" class="time-period-picker netop-select-list">
  <mat-form-field appearance="legacy">
    <mat-select [(value)]="currentSelectionIndex" (selectionChange)="onTimePeriodSelected()">
      <mat-select-trigger *ngIf="selectPrefix">{{selectPrefix}}: {{currentSelection?.label}}</mat-select-trigger>
      <ng-container *ngFor="let option of options; index as i">
        <mat-option *ngIf="!option.hidden" [value]="i">
          {{option.label}}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
</div>
