import {GenericDevice} from 'src/app/shared/models/topology';
import {BasicLink} from 'src/app/shared/models/single-link.model';
import {Logger} from 'src/app/shared/services/logger.service';
import {SingleDeviceType} from 'src/app/shared/models/single-device.model';
import * as arrayToTree from 'array-to-tree';
import {TopologyNodeType} from '../models/TopologyTypes.model';
import {TopologyConfiguration} from "../models/topology-configuration";
import {WidthHeight} from "../../../models/utils-classes/sizes.model";


export class BaseTreeCreation {
  wwwNode: GenericDevice<any>;
  nodes: GenericDevice<any>[];
  links: BasicLink[];
  protected logger: Logger;
  svgHeight: number;
  svgWidth: number;

  public readonly MAX_NODES_PER_LEVEL_FOR_NORMAL_DISPLAY = 7;
  public readonly NODE_NUM_PER_PAGE = this.MAX_NODES_PER_LEVEL_FOR_NORMAL_DISPLAY + 2.5;

  constructor() {
  }

  protected findNodeChildren(currentNode: GenericDevice<any>): GenericDevice<any>[] {
    if (currentNode) {
      const children: GenericDevice<any>[] = [];
      this.links.forEach(link => {
        let linkIdDirection: ('endDeviceId' | 'startDeviceId');
        if (link.startDeviceId == currentNode.id) {
          linkIdDirection = 'endDeviceId';
        }
        if (link.endDeviceId == currentNode.id) {
          linkIdDirection = 'startDeviceId';
        }
        if (linkIdDirection) {
          const potentialChild = this.nodes.find(node => node.id == link[linkIdDirection]);
          if (!currentNode.parent_id || currentNode.parent_id !== potentialChild.id) {
            children.push(potentialChild);
          }
        }
      });
      return children;
    }
    return [];
  }

  protected activateSwiper(nodes: GenericDevice<any>[], links: BasicLink[]) {
    nodes.forEach(node => {
      if (!node.parent_id && node.type !== SingleDeviceType.WWW) {
        for (let index = 0; index < links.length; index++) {
          const link = links[index];
          let linkIdDirection: ('endDeviceId' | 'startDeviceId');
          if (link.startDeviceId == node.id) {
            linkIdDirection = 'endDeviceId';
          }
          if (link.endDeviceId == node.id) {
            linkIdDirection = 'startDeviceId';
          }
          if (linkIdDirection) {
            const potentialParent = this.nodes.find(node => node.id == link[linkIdDirection]);
            if (potentialParent && TopologyNodeType.isHigherType(node.type, potentialParent.type)) {
              node.parent_id = potentialParent.id;
              break;
            }
          }
        }
      }
    });
  }

  protected connectOrphanParentsToFakeRoot(nodes: GenericDevice<any>[]) {
    nodes.forEach(node => {
      if (!node.parent_id && node.type !== SingleDeviceType.WWW) {
        node.parent_id = 666666;
      }
    });
  }

  protected nodeHasNoChildren(nodes: GenericDevice<any>[], currentNode: GenericDevice<any>) {
    return nodes.find(node => node.parent_id == currentNode.id) == undefined;
  }

  protected createFakeRootNode(rootNodeArray: arrayToTree.Tree<GenericDevice<any>>[], nodes: GenericDevice<any>[], links: BasicLink[]) {
    const fakeNode: GenericDevice<any> = new GenericDevice<any>(666666, SingleDeviceType.FakeRoot, null, null);
    fakeNode['id'] = fakeNode.id;
    rootNodeArray.forEach(node => node.parent_id = fakeNode.id);
    rootNodeArray.push(fakeNode);
    return arrayToTree(rootNodeArray);
  }

  /**
   * @method sortRootNodeArray Return the fake rootNodeArray with sorted children array.
   * The sorting is done by following Hierachy
   * 1. WWW
   * 2. SDWAN
   * 3. SWITCH
   * 4. Fire Wall
   * 5. Others
   * @param rootNodeArray The fake root node
   */
  protected sortRootNodeArray(rootNodeArray: arrayToTree.Tree<GenericDevice<any>>[]) {
    const newRootNodeChildrenArray: arrayToTree.Tree<GenericDevice<any>>[] = [];
    const rootNodeType: TopologyNodeType[] = TopologyNodeType.getTopologyNodesTypes();
    rootNodeType.forEach(type => {
      rootNodeArray[0].children.forEach(child => {
        if (child.type == type) {
          newRootNodeChildrenArray.push(child);
        }
      });
    });
    rootNodeArray[0].children = newRootNodeChildrenArray;
    return rootNodeArray;
  }

  /**
   * @method findBestTopology return the best root node that received by the create tree service.
   * Currently the best root node id determined by it's type, by the following hirarchy:
   * 1. WWW
   * 2. SDWAN
   * 3. SWITCH
   * 4. Others
   * @param nodes The Root nodes array
   */
  protected findBestTopology(nodes: GenericDevice<any>[]) {
    let bestNode: GenericDevice<any>;
    nodes.forEach(node => {
      if (node.type == SingleDeviceType.WWW) {
        bestNode = node;
      }
      if (!bestNode && node.type == SingleDeviceType.Firewall) {
        bestNode = node;
      }
      if (!bestNode && node.type == SingleDeviceType.SDWAN) {
        bestNode = node;
      }
      if (!bestNode && node.type == SingleDeviceType.Switch) {
        bestNode = node;
      }
      if (!bestNode) {
        bestNode = node;
      }
    });
    return bestNode;
  }

  /**
   * Return the highest nodes number per level
   */
  calculateMaxChildrenPerDepth(root: any, nodesPerDepth = {}) {
    const nodesPerLevel = this.calculateEachLevelTotalNodes(root, nodesPerDepth);
    let heightTotalNodesPerLevel = 0;
    Object.values(nodesPerLevel).forEach(totalNodes => {
      if (totalNodes > heightTotalNodesPerLevel) {
        heightTotalNodesPerLevel = totalNodes as number;
      }
    });
    return heightTotalNodesPerLevel;
  }

  /**
   * Build and reaturn hash object with level and total nodes number
   */
  calculateEachLevelTotalNodes(root: any, nodesPerDepth: {}) {
    if (nodesPerDepth[root.depth]) {
      nodesPerDepth[root.depth]++;
    } else {
      nodesPerDepth[root.depth] = 1;
    }
    if (root.children && root.children.length > 0) {
      root.children.forEach(child => {
        this.calculateMaxChildrenPerDepth(child, nodesPerDepth);
      })
    }
    return nodesPerDepth;
  }

  /**
   * Return the sizes fot the d3 tree
   * The topology at single device screen is meant to display the selected node and its
   * neighbours. Is should not fit into its container height.
   * So, in order to get pretty big devices, we take the container height and
   * multiple it by 4
   *
   * Subtract the svg height and width sizes so the tree will enter
   * inside the svg element and not on it border
   * In short, The svg element should be larger then the tree sizes
   * Example: https://bl.ocks.org/d3noob/8326869
   */

  getTreeComputedSizes(treeSizes: WidthHeight, topologyConfiguration: TopologyConfiguration, maxChildrenNumber: number, isSqueezable = false) {
    const sizes = {...treeSizes};
    const width = sizes.width - 150;
    let height = sizes.height - 50;
    if (topologyConfiguration.isDevice) {
      height = sizes.height * 2.5 - 50;
    }
    if (maxChildrenNumber >= this.MAX_NODES_PER_LEVEL_FOR_NORMAL_DISPLAY && !isSqueezable) {
      if (topologyConfiguration.isDevice) {
        height = sizes.height * (maxChildrenNumber / this.MAX_NODES_PER_LEVEL_FOR_NORMAL_DISPLAY * 2.5);
      } else {
        height = sizes.height * (maxChildrenNumber / this.MAX_NODES_PER_LEVEL_FOR_NORMAL_DISPLAY);
      }
    }
    return {width: width - 50, height: height - 50}
  }
}
