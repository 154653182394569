import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Logger } from "loglevel";
import { BehaviorSubject } from "rxjs";
import { MarkerLocation } from "src/app/shared/models/map.model";
import { OrganizationDetails } from "src/app/shared/models/organizations.model";
import { TenantId } from "src/app/shared/models/tenants.model";
import { LoggerService } from "src/app/shared/services/logger.service";
import { OrganizationsService } from "src/app/shared/services/rest-services/organizations.service";
import { HierarchyMapService } from "./hierarchy-map.service";
@Component({
  selector: 'app-hierarchy-map',
  templateUrl: './hierarchy-map.component.html',
  styleUrls: ['./hierarchy-map.component.scss']
})
export class HierarchyMapComponent implements OnInit {
  @Input() organizationId: number;
  @Input() tenantId: number;
  mapTitle: string;
  organizations: OrganizationDetails[];
  markerLocations: MarkerLocation[] = [];

  private logger: Logger;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private hierarchyMapService: HierarchyMapService,
    private loggerFactory: LoggerService,
    private organizationsService: OrganizationsService,
  ) {
    this.mapTitle = this.translate.instant('data.ORGANIZATIONS.ORG_MAP');
    this.logger = this.loggerFactory.getLogger('HierarchyMapComponent');
  }

  ngOnInit() {
    this.generateOrgVenuesList();
  }

  generateOrgVenuesList() {
    let tenantsRequest;

    if(this.tenantId) {
      const tenants = [new TenantId(this.tenantId)],
            subject = new BehaviorSubject<TenantId[]>(tenants),
            tenants$ = subject.asObservable();

      tenantsRequest = tenants$;
    }
    else tenantsRequest = this.organizationsService.fetchTenantsById(this.organizationId);

    tenantsRequest.subscribe(tenants => {
        const venues = this.hierarchyMapService.getTenantsVenuesWithAddresses(tenants);
        venues.subscribe(venues => {
          this.markerLocations = venues;
        });
    });
  }

  /**
   * When marker is Selected - route to org id. If the org settings is set to Tenant level -
   * Navigate to org
   */
  onMakrerSelected(event: MarkerLocation) {
    const routeDestination = `/tenant/${event.tenantId}/venue/`;
    this.router.navigate([`/${routeDestination}`, event.markerEntityId]).catch();
  }
}
