<div class="d-flex flex-column mt-2">
  <h6>
    {{title}}
  </h6>
  <p class="kt-label-font-color-2 pt-1" style="line-height: 1.2em;">
    {{details}}
  </p>
</div>

<!-- <mat-form-field>
    <mat-hint>You can pick up your favorite car here</mat-hint>
</mat-form-field> -->
