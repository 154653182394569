import { Component, OnInit, Inject } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { generateNumbersArray } from '../../operators/array.operator';
import { DeviceBackupDTO, ChronoUnit, DeviceBackupPeriodicDTO } from '../../models/device-backup.model';
import { DevicesService } from '../../services/rest-services/devices.service';
import { Logger, LoggerService } from '../../services/logger.service';
import { DisplaySuccessService } from '../../services/display-success.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-periodic-backup',
  templateUrl: './periodic-backup.component.html',
  styleUrls: ['./periodic-backup.component.scss']
})
export class PeriodicBackupComponent implements OnInit {
  scheduleBackupForm: FormGroup;
  numbersArray: number[] = generateNumbersArray(31, 1);
  periodArray = Object.entries(ChronoUnit.getChronoUnitASArray());
  defaultCron: string = '0 00 12 1/1 * ?';
  defaultEnableBackup: boolean = true;
  defaultKeepFor: { times: number, unit: ChronoUnit } = { times: 1, unit: ChronoUnit.MONTHS }
  isMulti: boolean = false;
  isSubmitDisabled: boolean = false;
  private logger: Logger;

  constructor(private fb: FormBuilder,
    private deviceService: DevicesService,
    private loggerFactory: LoggerService,
    private successService: DisplaySuccessService,
    private translate: TranslateService,
    private matDialogRef: MatDialogRef<PeriodicBackupComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DeviceBackupDTO[]) {
    this.logger = this.loggerFactory.getLogger("PeriodicBackupComponent")
  }

  ngOnInit() {
    this.loadDefaultValues();
    this.initateForm();
    this.loadDefaultCron();
  }

  loadDefaultValues() {
    if (this.dialogData.length > 0) {
      let deviceBackupData = this.dialogData[0];
      this.dialogData.forEach(backup => {
        if (backup && backup.keepFor && (backup.keepFor !== deviceBackupData.keepFor || backup.keepForChronoUnit !== deviceBackupData.keepForChronoUnit)) {
          this.isMulti = true;
        }
      })
      if (!this.isMulti) {
        this.defaultKeepFor.times = deviceBackupData.keepFor;
        this.defaultKeepFor.unit = deviceBackupData.keepForChronoUnit;
        this.defaultEnableBackup = deviceBackupData.enabled;
      }
    }
  }

  loadDefaultCron() {
    if (this.dialogData.length > 0) {
      let deviceBackupData = this.dialogData[0]
      this.dialogData.forEach(backup => {
        if (backup && backup.cronExpression && backup.cronExpression !== deviceBackupData.cronExpression) {
          this.isMulti = true;
        }
      })
      if (!this.isMulti) {
        this.defaultCron = deviceBackupData.cronExpression;
      }
    }
  }

  initateForm() {
    this.scheduleBackupForm = this.fb.group({
      backupEnabled: new FormControl(this.defaultEnableBackup, [Validators.required]),
      keepFor: new FormControl(this.defaultKeepFor.times, [Validators.required]),
      period: new FormControl(this.defaultKeepFor.unit, [Validators.required]),
      cron: new FormControl('', [Validators.required])
    })
    this.scheduleBackupForm.controls['backupEnabled'].valueChanges.subscribe(changes => {
      this.changeFormStatus();
    })
  }

  onCronSelected(cron: string) {
    this.scheduleBackupForm.controls['cron'].setValue(cron);
  }

  submitSchedule() {
    if (this.isFormValid) {
      let deviceBackupPeriodicDTO: DeviceBackupPeriodicDTO = {
        enabled: this.isBackupEnabled,
        deviceIds: this.dialogData.map(backup => backup.deviceId),
        cronExpression: this.scheduleBackupForm.controls["cron"].value,
        keepFor: this.scheduleBackupForm.controls["keepFor"].value,
        keepForChronoUnit: this.scheduleBackupForm.controls["period"].value
      }
      this.logger.debug("deviceBackupPeriodicDTO", deviceBackupPeriodicDTO);
      this.deviceService.setDeviceBackupPeriodic(deviceBackupPeriodicDTO).subscribe(response => {
        this.matDialogRef.close();
        this.closeDialog(true);
        let message = this.translate.instant('data.MAINTENANCE.BACKUP_LATER_TOAST');
        this.successService.openMessage(message);
      })
    }
  }

  closeDialog(isSubmit: boolean) {
    this.matDialogRef.close(isSubmit);
  }

  get numberOf() {
    if (this.scheduleBackupForm) {
      return this.scheduleBackupForm.controls['keepFor'].value;
    }
  }

  get buttonColor(): ThemePalette {
    return this.isBackupEnabled ?
      'primary' :
      'warn';
  }

  get cron() {
    return this.scheduleBackupForm && this.scheduleBackupForm.controls["cron"].value;
  }
  get isFormDirty() {
    return this.scheduleBackupForm && this.scheduleBackupForm.dirty;
  }

  get isBackupEnabled() {
    return this.scheduleBackupForm && this.scheduleBackupForm.controls["backupEnabled"].value
  }

  get isFormValid() {
    return this.scheduleBackupForm && this.scheduleBackupForm.valid;
  }

  changeFormStatus() {
    if (this.isBackupEnabled) {
      this.scheduleBackupForm.controls["keepFor"].enable();
      this.scheduleBackupForm.controls["period"].enable();
    }
    else {
      this.scheduleBackupForm.controls["keepFor"].disable();
      this.scheduleBackupForm.controls["period"].disable();
    }
  }
}
