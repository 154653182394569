import { createFeatureSelector, createSelector } from '@ngrx/store';
import { routerStateConfig } from './ngrx-router.module';
import { MergedRouteReducerState } from './merged-route'
import { EntityType } from 'src/app/shared/models/entity-type.enum';

export const getRouterReducerState = createFeatureSelector<MergedRouteReducerState>(routerStateConfig.stateKey);
export const getMergedRoute = createSelector(getRouterReducerState, (routerReducerState) => {
    //debugger;
    return routerReducerState.state
});

export const selectOrgId = createSelector(getMergedRoute, (mergedRoute) => mergedRoute.params.orgId);
export const selectTenantId = createSelector(getMergedRoute, (mergedRoute) => mergedRoute.params.tenantId);
export const selectDeviceId = createSelector(getMergedRoute, (mergedRoute) => mergedRoute.params.deviceId);
export const selectFabricId = createSelector(getMergedRoute, (mergedRoute) => mergedRoute.params.fabricId);
export const selectVenueId = createSelector(getMergedRoute, (mergedRoute) => mergedRoute.params.venueId);
export const selectEntityType = createSelector(getMergedRoute, (mergedRoute) => {
    return mergedRoute && mergedRoute.data ? mergedRoute.data.entity as EntityType : null
});
/**
 * To properly get the current entity and it's hierachy we need to :
 * 1. defined in data object in routing the entity key with entity type
 * 2. define in the routing the :id or :orgId, :tenantId etc... parameter
 * e.g. 
 * path: ':id' // or ':fabricId' 
 * data: {
 *    entity: EntityType.FABRIC
 * }
 */
export const selectEntityId = createSelector(getMergedRoute, (mergedRoute) => {
    if (!mergedRoute || !mergedRoute.data) {
        return null;
    }
    let id = mergedRoute.params.id;
    switch (mergedRoute.data.entity) {
        case EntityType.ORGANIZATION:
            return mergedRoute.params.orgId || id;
        case EntityType.TENANT:
            return mergedRoute.params.tenantId || id;
        case EntityType.DEVICE:
            return mergedRoute.params.deviceId || id;
        case EntityType.FABRIC: {
            console.log(`${id} || ${mergedRoute.params.fabricId}`)
            return mergedRoute.params.fabricId || id;
        }
        case EntityType.VENUE:
            return mergedRoute.params.venueId || id;
        default:
            return null;
    }
});