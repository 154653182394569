import { objectWithValue, notObjectOrArray } from './null-checks';

export function flattenObject(obj) {
    const flattened = {}
    Object.keys(obj).forEach((key, index) => {
        if (objectWithValue(obj[key])) {
            Object.assign(flattened, flattenObject(obj[key]))
        }
        else if (Array.isArray(obj[key])) {
            obj[key].forEach(element => {
                if (element.hasOwnProperty("key") && element.hasOwnProperty("value")) {
                    Object.assign(flattened, flattenObject({ [element.key]: element.value }));
                }
                else {
                    Object.assign(flattened, flattenObject(obj[key]));
                }
            })
        } else
            if (Array.isArray(obj)) {
                obj.forEach((element, index) => {
                    flattened[index + 1] = element ? element : " ";
                })
            }
            else {
                flattened[key] = obj[key] ? obj[key] : " ";
            }
    })
    return flattened;
}
export function openObject(obj) {
    if (obj) {
        const openObject = {};
        Object.keys(obj).forEach((key) => {
            if (objectWithValue(obj[key])) {
                openObject[key] = flattenObject(obj[key]);
            }
            else
                if (Array.isArray(obj[key])) {
                    if (isArrayOfObjects(obj[key])) {
                        openObject[key] = flattenObject(addIndexToObjectInArray(obj[key]));
                    }
                    if (!isArrayOfObjects(obj[key])) {
                        openObject[key] = flattenObject(obj[key]);
                    }
                }
                else {
                    openObject[key] = obj[key] ? obj[key] : " ";
                }
        })
        return openObject;
    }
    return null;
}

export function isArrayOfObjects(array: any[]) {
    return array.find(element => objectWithValue(element)) !== undefined;
}

export function addIndexToObjectInArray(arrayOfObj: any[]) {
    let indexedObj = {};
    arrayOfObj.forEach((obj, index) => {
        for (let [key, value] of Object.entries(obj)) {
            indexedObj[`${index + 1}.${key}`] = value;
        }
    });
    return indexedObj;
}
export function reorderObjFirstGenProps(obj) {
    let reOrderedObj = { generalData: null };
    if (obj) {
        for (let [key, value] of Object.entries(obj)) {
            if (notObjectOrArray(value)) {
                if (reOrderedObj.generalData)
                    reOrderedObj.generalData[key] = value;
                else {
                    reOrderedObj.generalData = { [key]: value };
                }
            }
            else {
                if (value == [] || !value) {
                    reOrderedObj[key] = "";
                }
                else {
                    reOrderedObj[key] = value;
                }
            }
        }
        if (reOrderedObj.generalData) {
            return reOrderedObj;
        }
        else {
            delete reOrderedObj.generalData;
            return reOrderedObj;
        }
    }
    return null;
}

export function removeAllIdFields(obj: any) {
    let objWithouIds = {};
    for (let [key, value] of Object.entries(obj)) {
        if (objectWithValue(value)) {
            objWithouIds[key] = removeAllIdFields(value);
        }
        else if (!key.toLowerCase().includes("id")) {
            objWithouIds[key] = value;
        }
    }
    return objWithouIds;
}

export function objectToString(obj: any) {
    let objAsString: string = '';
    for (let [key, value] of Object.entries(obj)) {
        objAsString += `${key}:  ${value},\n`
    }
    let lastCommaIndex = objAsString.lastIndexOf(',');
    return objAsString.slice(0, lastCommaIndex);
}