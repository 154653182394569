import {SharedModule} from './shared/shared.module';
// Angular Modules
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {ErrorHandler, NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {StoreModule} from "@ngrx/store";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {NgrxModule} from './store/ngrx.module'


// Modules
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {InlineSVGModule} from "ng-inline-svg";
import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {TokenInterceptorService} from "./auth/services/token-interceptor.service";
import {HttpSettingInterceptorService} from "./shared/services/http-setting-interceptor.service";
import {environment} from 'src/environments/environment';
import {HttpClientInMemoryWebApiModule} from 'angular-in-memory-web-api';
import {InMemoryDataService} from './shared/services/in-memory/in-memory-data.service';
import {LoggerService} from './shared/services/logger.service';
import * as LOG_CONFIG from './config/logging-config';
import {DeviceDetectorModule, DeviceDetectorService} from 'ngx-device-detector';

import {LayoutModule} from '@angular/cdk/layout';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {AngularFireModule} from '@angular/fire';
import {
  AngularFireAnalyticsModule,
  DEBUG_MODE,
  ScreenTrackingService,
  UserTrackingService
} from '@angular/fire/analytics';
import {GlobalErrorHandlerService} from './core/services/global-error-handler.service';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireFunctionsModule, USE_EMULATOR as USE_FUNCTIONS_EMULATOR} from '@angular/fire/functions';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {KeycloakAngularModule} from "keycloak-angular";
import {AuthMethodGenerator} from "./auth/auth-method-generator";
import {EnvironmentType} from "../environments/environment.model";
import * as PROD_CONFIG from "./config/logging-config.prod";
import {NgxHotjarModule, NgxHotjarRouterModule} from "ngx-hotjar";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    StoreModule.forRoot({
      // navigationDetails: navigationReducer,
      // "current-entity": fromCurrentEntity.reducer,
      // "hierarchy": fromHierarchy.reducer
    }),
    NgrxModule,
    StoreDevtoolsModule.instrument({logOnly: environment.production}),
    HttpClientModule,
    environment.disableInMemory ?
      [] : HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, {
        apiBase: environment.apiBaseUrl,
        passThruUnknownUrl: true
      }),
    InlineSVGModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    DeviceDetectorModule.forRoot(),
    AppRoutingModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    !environment.disableFirebase ? [
      AngularFireModule.initializeApp(environment.firebase),
      AngularFireAnalyticsModule,
      AngularFireAuthModule,
      AngularFireFunctionsModule,
      AngularFireDatabaseModule,
    ] : [],
    KeycloakAngularModule,
    environment.enableHotjar ? NgxHotjarModule.forRoot(environment.hotjar?.id) : [],
    environment.enableHotjar ? NgxHotjarRouterModule : [],
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: Window,
      useValue: window
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpSettingInterceptorService,
      multi: true
    },
    {
      provide: LoggerService,
      useFactory: (deviceService: DeviceDetectorService) => {
        const loggerConfig = EnvironmentType.isLoggingAllowed(environment.environmentType) ? LOG_CONFIG.logConfig : PROD_CONFIG.logConfig;
        return new LoggerService(loggerConfig, deviceService)
      },
      deps: [DeviceDetectorService]
    },
    // firebase debug mode
    // TODO: this should be set only in none production mode
    {provide: DEBUG_MODE, useValue: !environment.production},
    // firebasd emulator - enable only on dev
    {
      provide: USE_FUNCTIONS_EMULATOR,
      useValue: environment.firebase && environment.firebase.localInvocation ? 'http://localhost:5000' : ''
    },
    // skip authentication in dev mode
    AuthMethodGenerator.getAuthMethodProvider(environment.authMethod)
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
