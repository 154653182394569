import {AngularFireDatabase, AngularFireObject, DatabaseSnapshot, AngularFireAction} from '@angular/fire/database';
import {ClientStorageEntities, ClientsPreferences, HierarchyWithSnapshot} from '../../../models/client-storage.model';
import {LoggerService, Logger} from '../../logger.service';
import {take} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {Hierarchy, EntityTreeNode, OrganizationHierarchyTree} from '../../../models/hierarchy.model';
import {HierarchyService} from '../../rest-services/hierarchy.service';
import {OrganizationDetails} from '../../../models/organizations.model';
import {TenantDetails} from '../../../models/tenants.model';
import {UpdateEntitySiblingsPreferencesService} from './update-entity-siblings-preferences.service';
import {SetEntityPreferencesByParentService} from './set-entity-preferences-by-parent.service';
import {EntityType} from '../../../models/entity-type.enum';
import {isFirebaseObjectExist} from '../../../operators/firebase.operators';
import {IAppState} from 'src/app/store/state/app.state';
import {selectHierarchy} from 'src/app/store/selectors/hierarchy.selector';
import {Store} from '@ngrx/store';
import {environment} from '../../../../../environments/environment';
import {IBaseFirebaseByOrgService} from './Ibase-firebase-by-org-service';
import {LoadClientStorageService} from "../load-client-storage.service";

export class BaseFirebaseByOrganizations implements IBaseFirebaseByOrgService {
  currentStorageEntity: ClientStorageEntities;
  /**
   * Contain Branding selection
   * Default value: netopDefaultUISelection
   */
  firebaseSelection: ClientsPreferences<any>;
  /**
   * Angular firebase database instance
   */
  firebaseDB: AngularFireDatabase;
  /**
   * Current Org entity as stored on Ngrex
   */
  currentOrgHierarchy: Hierarchy;
  /**
   * Curren Firebase Org Object
   */
  currentOrg: AngularFireObject<ClientsPreferences<any>>;
  currentOrgValue: any;
  storageObject = {};

  firebaseLocationString: string;

  hierarchyWithSnapshot: HierarchyWithSnapshot[] = [];
  entireOrgTree: OrganizationHierarchyTree;


  protected logger: Logger;

  constructor(
    protected store: Store<IAppState>,
    protected hierarchyService: HierarchyService,
    protected updateEntitySiblingsPreferencesService: UpdateEntitySiblingsPreferencesService,
    protected setEntityPreferencesByParentService: SetEntityPreferencesByParentService,
    protected loggerFactory: LoggerService,
    protected loadClientStorageService: LoadClientStorageService
  ) {
  }

  setDefaultServiceValues(firebaseEntity: ClientStorageEntities, serviceName: string, defaultValue: ClientsPreferences<any>, db: AngularFireDatabase) {
    this.currentStorageEntity = firebaseEntity;
    this.logger = this.loggerFactory.getLogger(serviceName);
    this.firebaseDB = db;
    this.firebaseSelection = defaultValue;
    this.firebaseLocationString = `preferences/${this.currentStorageEntity}/${environment["environmentType"]}`;
  }

  getHighLevelOrgPreferences() {
    this.logger.debug("inside getHighLevelOrgPrefernces");
    this.store.select(selectHierarchy).subscribe(hierarchy => {
      this.logger.debug("hierarchy", hierarchy);
      if (hierarchy.length > 0) {
        if (!this.currentOrgHierarchy || this.currentOrgHierarchy && hierarchy[0].id !== this.currentOrgHierarchy[0].id) {
          if (this.firebaseSelection.preferences.data.hasOwnProperty("orgBranding")) {
            this.firebaseSelection.preferences.data.companyName = hierarchy[0].name;
          }
          this.currentOrgHierarchy = hierarchy;
          //We don't want to check Hierarchy for tenants, for the Tree Api will throw an error
          if (this.currentOrgHierarchy[0].type == EntityType.ORGANIZATION)
            this.buildOrgHierarchyWithSnapshot();
          this.currentOrg = this.firebaseDB.object(this.firebaseLocationString + `/${this.currentOrgHierarchy[0].id}-${this.currentOrgHierarchy[0].type}`);
          this.actByOrgValue(this.currentOrg);
        }
      }
    })
  }

  actByOrgValue(org: AngularFireObject<ClientsPreferences<any>>) {
    org.snapshotChanges().pipe(take(1)).subscribe(snapshot => {
      if (isFirebaseObjectExist(snapshot))
        this.applyFirebaseObjectPrefs(snapshot);
      else
        this.addFirebaseObject(org);
    })
  }

  addFirebaseObject(org: AngularFireObject<ClientsPreferences<any>>) {
    org.set({
      preferences: this.firebaseSelection.preferences
    });
    this.applyDefaultAndSelectedPreferences(this.firebaseSelection.preferences)
  }


  applyFirebaseObjectPrefs(snapshot: AngularFireAction<DatabaseSnapshot<any>>) {
    this.currentOrgValue = snapshot.payload.val().preferences;
    if (this.currentOrgValue && this.currentOrgValue.data) {
      this.firebaseSelection.preferences = {
        isOwnPreferences: this.currentOrgValue.isOwnBRanding,
        data: {}
      }
      for (let [key, value] of Object.entries(this.currentOrgValue.data)) {
        if (this.currentOrgValue.data[key])
          this.firebaseSelection.preferences.data[key] = this.currentOrgValue.data[key];
      }
      this.applyDefaultAndSelectedPreferences(this.currentOrgValue)
    }
  }

  applyDefaultAndSelectedPreferences(preferences: any) {
  }

  preparePreferencesForFirebase(data: any) {
  }

  updateOrgPreferencesSelection() {
    this.updateCurrentOrg(this.currentOrg);
    this.updateEntitySiblingsPreferencesService.updateCurrentOrgSibling(this.firebaseDB, this.firebaseLocationString, this.currentOrgHierarchy, this.firebaseSelection, this.logger, this.hierarchyWithSnapshot, this.entireOrgTree, this.currentStorageEntity);
    this.rebuildOrgHierachyWithSnapshot(false);
    this.loadClientStorageService.storeOrgBrandingLoaded(this.firebaseSelection);
  }

  updateCurrentOrg(org: AngularFireObject<ClientsPreferences<any>>) {
    if (org) {
      org.update(this.firebaseSelection);
    }
  }


  addFirebaseToHierarchyObject(entity: EntityTreeNode): void {
    let entityFirebaseObj: AngularFireObject<ClientsPreferences<any>> = this.firebaseDB.object(this.firebaseLocationString + `/${entity.value.id}-${entity.value.type}`);
    entityFirebaseObj.snapshotChanges().pipe(take(1)).subscribe(snapshot => {
      if (isFirebaseObjectExist(snapshot)) {
        this.hierarchyWithSnapshot.push({
          entityTreeNode: entity,
          firebaseSnapshot: snapshot as AngularFireAction<DatabaseSnapshot<ClientsPreferences<any>>>
        });
        if (entity.children && entity.children.length > 0)
          entity.children.forEach(child => this.addFirebaseToHierarchyObject(child));
      } else {
        this.hierarchyWithSnapshot.push({
          entityTreeNode: entity,
          firebaseSnapshot: snapshot as AngularFireAction<DatabaseSnapshot<ClientsPreferences<any>>>
        });
      }
    });
  }

  rebuildOrgHierachyWithSnapshot(withNewTree: boolean = true) {
    this.hierarchyWithSnapshot = [];
    this.buildOrgHierarchyWithSnapshot(withNewTree);
  }


  /**
   * build array that combine entities and firebase branding data
   */
  buildOrgHierarchyWithSnapshot(withNewTree: boolean = true) {
    if (withNewTree || !this.entireOrgTree)
      this.hierarchyService.fetchOrganizationHierarchyTree().pipe(take(1)).subscribe(tree => {
        this.logger.debug("tree", tree);
        this.entireOrgTree = tree;
        tree.forEach(entity => this.addFirebaseToHierarchyObject(entity));
      })
    else
      this.entireOrgTree.forEach(entity => this.addFirebaseToHierarchyObject(entity));
  }

  applyFirebasePreferencesForNewEntity(entity: (OrganizationDetails | TenantDetails)) {
    this.setEntityPreferencesByParentService.findNewEntityAncestry(entity, this.hierarchyService, this.firebaseDB, this.firebaseLocationString, this.logger, this.hierarchyWithSnapshot, this.currentStorageEntity);
    this.rebuildOrgHierachyWithSnapshot();
  }
}
