import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {MatListModule} from "@angular/material/list";
import {ListViewComponent} from "./list-view.component";
import {MatIconModule} from "@angular/material/icon";
import {SharedPipesModule} from "../../shared-pipes.module";

@NgModule({
  declarations: [ListViewComponent],
    imports: [
        CommonModule,
        MatListModule,
        MatIconModule,
        SharedPipesModule
    ],
  exports: [ListViewComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ListViewModule {
}
