import { Injectable } from '@angular/core';
import { OrganizationHierarchyTree, EntityTreeNode } from '../../../models/hierarchy.model';
import { Tenant, TenantDetails } from '../../../models/tenants.model';
import { Organization, OrganizationDetails } from '../../../models/organizations.model';
import { AngularFireDatabase, AngularFireObject, AngularFireAction, DatabaseSnapshot } from '@angular/fire/database';
import { Logger } from '../../logger.service';
import { take } from 'rxjs/operators';
import { HierarchyService } from '../../rest-services/hierarchy.service';
import { isEntityChildOfParent, getNewEntityType, getNewEntityId } from '../../../operators/firebase.operators';
import { HierarchyWithSnapshot, ClientsPreferences, ClientStorageEntities } from '../../../models/client-storage.model';
import { CrudFireBase } from './crud-firebase';

@Injectable({
  providedIn: 'root'
})
export class SetEntityPreferencesByParentService extends CrudFireBase {
  bottomToTopTreeArray: Array<EntityTreeNode> = [];

  constructor() {
    super()
  }
  /**
   * Set preferences to new created entity.
   * Check for closet parent with its own preferences and apply its styling
   * @param entity New created entity
   * @param hierarchyService HierarchyService
   * @param firebaseDB FirebaseDB
   * @param firebaseLocationString The base path to firebase object
   * @param logger Logger
   * @param hierarchyWithBranding Array of hierarchy objects combined with firebase preferences data
   */
  findNewEntityAncestry(entity: (OrganizationDetails | TenantDetails), hierarchyService: HierarchyService, firebaseDB: AngularFireDatabase, firebaseLocationString: string, logger: Logger, hierarchyWithSnapshot: HierarchyWithSnapshot[], currentFirebaseEntity: ClientStorageEntities) {
    hierarchyService.fetchOrganizationHierarchyTree().pipe(take(1)).subscribe(tree => {
      this.hierarchyWithSnapshot = hierarchyWithSnapshot;
      this.entireOrgTree = tree;
      this.currentFirebaseEntity = currentFirebaseEntity;
      if (entity) {
        this.bottomToTopTreeArray = [];
        let entityAsFirebase = firebaseDB.object(firebaseLocationString + `/${entity.id}-${entity.type}`);
        let ancestores: EntityTreeNode[] = this.getOrgAncestryAsSortedArray(entity, logger);
        let ancestoresAsFirebaseObjList: AngularFireAction<DatabaseSnapshot<ClientsPreferences<any>>>[] = this.getParentAsFirebaseObj(ancestores);
        if (ancestoresAsFirebaseObjList && ancestoresAsFirebaseObjList.length > 0) {
          this.applyPreferencesByParent(ancestoresAsFirebaseObjList, entityAsFirebase, entity, logger);
        }
      }
    })
  }
  /**
   * Find closet parent with its own preferences, and create new firebase object for it.
   */
  applyPreferencesByParent(ancestoresAsFirebaseObjList: AngularFireAction<DatabaseSnapshot<ClientsPreferences<any>>>[], entityAsFirebase: AngularFireObject<unknown>, entity: OrganizationDetails | TenantDetails, logger: import("loglevel").Logger) {
    let isPreferencesSet: boolean = false;
    for (let i = 0; i < ancestoresAsFirebaseObjList.length; i++) {
      const firebaseAncestor = ancestoresAsFirebaseObjList[i];
      if (firebaseAncestor && firebaseAncestor.payload.val()) {
        let firebaseAncestorValue: ClientsPreferences<any> = firebaseAncestor.payload.val();
        if (firebaseAncestorValue.preferences.isOwnPreferences) {
          firebaseAncestorValue.preferences.isOwnPreferences = false;
          logger.debug("entityAsFirebase added", firebaseAncestorValue.preferences);
          entityAsFirebase.set(this.getFinalPreferencesVersion(firebaseAncestorValue, entity.name))
          isPreferencesSet = true;
        }
      }
      if (isPreferencesSet)
        break;
    }

  }
  /**
   * Retreive the entire firebase data as array
   */
  getFireBaseOrgsList(orgTreeAsArray: (OrganizationDetails | Tenant)[], firebaseDB: AngularFireDatabase, firebaseLocationString: string): AngularFireObject<ClientsPreferences<any>>[] {
    let firebaseOrgList: AngularFireObject<ClientsPreferences<any>>[] = [];
    orgTreeAsArray.forEach(org => {
      firebaseOrgList.push(firebaseDB.object(firebaseLocationString + `/${org.id}-${org.type}`));
    })
    if (firebaseOrgList.length > 0)
      return firebaseOrgList;
  }
  /**
   * Find all new created entity ancestores
   */
  getOrgAncestryAsSortedArray(newEntity: (OrganizationDetails | Tenant), logger?: Logger): EntityTreeNode[] {
    return this.findEntityClosetParent(this.entireOrgTree, newEntity, logger);
  }
  /**
   * Recursive method for finding entity Ancestry
   */
  findEntityClosetParent(tree: OrganizationHierarchyTree, newEntity: (EntityTreeNode | OrganizationDetails | Tenant), logger: Logger, bottomToTopTreeArray: Array<EntityTreeNode> = []): EntityTreeNode[] {
    tree.forEach(entity => {
      if (entity.children.find(child => isEntityChildOfParent(child, getNewEntityId(newEntity), getNewEntityType(newEntity), tree))) {
        bottomToTopTreeArray.push(entity);
        if (entity.value["parentOrg"])
          this.findEntityClosetParent(this.entireOrgTree, entity, logger, bottomToTopTreeArray);
      }
      else {
        tree[0].children.forEach(entity => this.findEntityClosetParent([entity], newEntity, logger, bottomToTopTreeArray));
      }
    })
    return bottomToTopTreeArray;
  }

  /**
   * Return the snapshot for each parent object
   */
  getParentAsFirebaseObj(parents: EntityTreeNode[]): AngularFireAction<DatabaseSnapshot<ClientsPreferences<any>>>[] {
    let firebaseOrgList: AngularFireAction<DatabaseSnapshot<ClientsPreferences<any>>>[] = [];
    parents.forEach(parent => {
      let parentWithFirebaserData = this.hierarchyWithSnapshot.find(entity => entity.entityTreeNode.value.id == parent.value.id);
      if (parentWithFirebaserData)
        firebaseOrgList.push(parentWithFirebaserData.firebaseSnapshot);
    })
    if (firebaseOrgList.length > 0)
      return firebaseOrgList;
  }
}
