import {Injectable} from '@angular/core';
import {SingleDevice, SingleDeviceType} from '../../../models/single-device.model';
import {
  SingleLink,
  SingleLinkType,
  SingleLinkStatus,
  LinkVendorStatus,
  BasicLink
} from '../../../models/single-link.model';
import {Topology, GenericDevice} from '../../../models/topology';
import * as uuid from 'uuid';
import {TreeConfiguration} from '../models/topology-configuration';
import {EditStringsService} from '../../../services/strategies/edit-strings.service';
import {Subject} from 'rxjs';
import {TopologyBuilderService} from './topology-builder.service';
import {ConnectedClientType} from "../../../models/clients.model";
import {isGenericIsClient, isGenericIsMultiClient} from "../operators/topology-operators";
import {LinkType} from "../models/link-status";

@Injectable({
  providedIn: 'root'
})

export class GenericTopologyService {
  newTopologyEnumArray: any;
  private notifyNewTopology: Subject<Topology<GenericDevice<SingleDevice>, SingleLink>> = new Subject();
  notifyNewTopologyAsObservable$ = this.notifyNewTopology.asObservable();

  constructor(
    private topologyBuilder: TopologyBuilderService,
    private editStringsService: EditStringsService,
  ) {
  }

  /**
   * @method addAnotherTopology Concat the devices topology with the new topology.
   * It return new topology that gets "any" as type
   * It also initlized the new topology type's enum array
   * @param topology The current network Topology
   * @param newTopology The Topology to be added to the current topology
   * @param newTopologyEnumArray The enum that contain the enum array of the newTopology
   */
  addAnotherTopology(topology: Topology<GenericDevice<any>, any>, newTopology: Topology<GenericDevice<any>, any>, newTopologyEnumArray: any): Topology<any, any> {
    this.newTopologyEnumArray = newTopologyEnumArray;
    let newGroupedTopology: Topology<GenericDevice<any>, any> = newTopology;
    let newTopologyWithParents: Topology<GenericDevice<any>, any>;
    newTopologyWithParents = {...this.createNewTopologyWithParents(newGroupedTopology, topology)};
    newGroupedTopology = this.createGroupedTopology({...newTopologyWithParents});
    return this.createCombinedTopology(newGroupedTopology, topology);

  }

  /**
   * @method createCombinedTopology Create combined topology out of the current topology and the new grouped one
   * @param newGroupedTopology The new grouped topology
   * @param topology The current topology
   */
  private createCombinedTopology(newGroupedTopology: Topology<GenericDevice<any>, any>, topology: Topology<GenericDevice<any>, any>) {
    let combinedTopology: Topology<any, any> = {...topology};
    combinedTopology.nodes = combinedTopology.nodes.concat(newGroupedTopology.nodes as any);
    combinedTopology.links = combinedTopology.links.concat(newGroupedTopology.links as any);
    combinedTopology = this.connectClientsToZipperMechanism(combinedTopology);
    return combinedTopology;
  }

  private connectClientsToZipperMechanism(combinedTopology: Topology<any, any>) {
    combinedTopology.nodes.filter(node => isGenericIsMultiClient(node) || isGenericIsClient(node)).forEach(client => {
      const parent = combinedTopology.nodes.find(node => node.id === client.parent_id);
      if (parent !== undefined && parent.isToBeZipped) {
        client.isZippedMode = parent.isZipMode;
      }
    });
    return combinedTopology;
  }

  /**
   * @method removeParentsIdFromNodes Delete the parent_id from the new combine topology
   * The topology get its parent_id from the create tree service.
   * It needed to be deleted in order to use create tree service again for the new topology
   * @param combinedTopology
   */
  private removeParentsIdFromNodes(topology: Topology<any, any>): Topology<any, any> {
    topology.nodes.forEach(node => {
      delete node.parent_id;
    });
    return topology;
  }

  /**
   * @method groupMultiDevices Return the devices array, with grouped devices
   * @param genericTopology The current new Topology, as received from the server
   * @param topology The network devices Topology
   */
  private createNewTopologyWithParents(genericTopology: Topology<GenericDevice<any>, any>, topology: Topology<GenericDevice<any>, any>) {
    const parentsIds: any[] = [];
    let genericDevicesWithParents: Topology<GenericDevice<any>, any>;
    /**
     * The following loops initlized the parent_ids array
     */
    genericTopology.nodes.forEach(node => {
      genericTopology.links.forEach(link => {
        if (link.startDeviceId == node.id) {
          parentsIds.push(link.endDeviceId);
        }
        if (link.endDeviceId == node.id) {
          parentsIds.push(link.startDeviceId);
        }
      });
    });
    /**
     * Make the parent Id array distinct
     */
    const distinctParentsId = [...new Set(parentsIds)];

    if (distinctParentsId[0]) {
      genericDevicesWithParents = {nodes: [...genericTopology.nodes], links: [...genericTopology.links]};
      /**
       * For each parent id, genericDevicesWithParents array add the parent node
       */
      distinctParentsId.forEach(parentId => {
        topology.nodes.forEach(node => {
          if (node.id == parentId) {
            genericDevicesWithParents.nodes.push(node);
          }
        });
      });
      return genericDevicesWithParents;
    }
  }

  /**
   * @method createGroupedTopology Create grouped topology of the new Generic devices
   * In addition and in order to maintain the parentId param (which being deleted by the generateTree), we restore this param in order to allow
   * functionality in the zipper mechanism
   * @param genericDevicesWithParents The new topology array with their parents
   */
  private createGroupedTopology(genericDevicesWithParentsTopology: Topology<GenericDevice<any>, any>) {
    let groupedGenericTopology: Topology<GenericDevice<any>, any>;
    groupedGenericTopology = {nodes: [], links: []};
    const parentsIdStorage: { deviceId: number | string, parentId: number | string }[] = genericDevicesWithParentsTopology.nodes.map(node => {
      return {
        deviceId: node.id,
        parentId: node.parent_id
      }
    })
    /**
     * Converting the genericDevicesWithParents Topology to tree, in order to use the array to tree library abilities,
     * to determine which nodes should be grouped
     */
    this.removeParentsIdFromNodes(genericDevicesWithParentsTopology);
    console.log("removeParentsIdFromNodes");
    const root: any = this.topologyBuilder.generateTree(genericDevicesWithParentsTopology.nodes, genericDevicesWithParentsTopology.links);
    genericDevicesWithParentsTopology.nodes.forEach(node => {
      if (node.parent_id === undefined) {
        const nodeInStorage = parentsIdStorage.find(nodeInStorage => nodeInStorage.deviceId == node.id);
        if (nodeInStorage !== undefined) {
          node.parent_id = nodeInStorage.parentId;
        }
      }
    })
    if (root && root.children && root.children.length > 0) {
      /**
       * The array to tree library creates tree with fake root node.
       * Each of this root node children is a single device type which children of his own devices.
       */
      if (root.type == SingleDeviceType.FakeRoot && root.children) {
        root.children.forEach(child => {
          let grandSons;
          grandSons = child.children;
          groupedGenericTopology = this.groupGenericTopology(child, grandSons, groupedGenericTopology, genericDevicesWithParentsTopology);
        });
      } else {
        if (root.children) {
          let rootChildren;
          rootChildren = root.children;
          groupedGenericTopology = this.groupGenericTopology(root, rootChildren, groupedGenericTopology, genericDevicesWithParentsTopology);
        }
      }
    }
    return groupedGenericTopology;
  }

  private groupGenericTopology(rootChild: GenericDevice<any>, grandSons: GenericDevice<any>[], groupedGenericTopology: Topology<GenericDevice<any>, any>, genericDevicesWithParentsTopology: Topology<GenericDevice<any>, any>): Topology<GenericDevice<any>, any> {
    if (grandSons.length >= 4) {
      let nodeTobeRemoved: GenericDevice<any>[] = [];
      const Types = this.newTopologyEnumArray;
      grandSons.sort((a, b) => Types[a.type.toUpperCase()] > Types[b.type.toUpperCase()] ? 1 : -1);
      for (let i = 0; i < grandSons.length; i++) {
        if (grandSons[i + 1] && grandSons[i].type == grandSons[i + 1].type) {
          nodeTobeRemoved.push(grandSons[i]);
        } else {
          let type: any;
          if (i > 0 && grandSons[i].type == grandSons[i - 1].type) {
            nodeTobeRemoved.push(grandSons[i]);
            type = grandSons[i].type;
          }
          /**
           * If the root child has more than 4 children, and all of them are
           * of the same type, They should be removed and replaced with one node
           * For the first time, the groupedGenericTopology gets the result of replaceMultiInSingle method.
           * After the first group was initlizes, it concat all grouped nodes
           */
          if (nodeTobeRemoved.length >= 4) {
            if (!groupedGenericTopology.nodes[0]) {
              groupedGenericTopology = this.removeGroupedNodeAndParents(genericDevicesWithParentsTopology, nodeTobeRemoved);
            } else {
              const groupedTopology = this.removeGroupedNodeAndParents(genericDevicesWithParentsTopology, nodeTobeRemoved);
              groupedGenericTopology.nodes = groupedTopology.nodes;
              groupedGenericTopology.links = groupedTopology.links;
            }
            const groupedNode = this.addGroupedNode(type, nodeTobeRemoved, rootChild);
            groupedGenericTopology.nodes.push(groupedNode);
            groupedGenericTopology.links.push(this.addNewLink(groupedNode, rootChild));
            nodeTobeRemoved = [];
          } else {
            nodeTobeRemoved = [];
            groupedGenericTopology = this.removeGroupedNodeAndParents(genericDevicesWithParentsTopology, nodeTobeRemoved);
          }
        }
      }
    } else {
      groupedGenericTopology = this.removeGroupedNodeAndParents(genericDevicesWithParentsTopology);
    }
    return groupedGenericTopology;
  }


  /**
   * @method addGroupedNode Return new node that represend all the grouped node of the same type with the same parent
   * @param type The common Type of all the nodes
   * @param nodeTobeRemoved All the nodes
   */
  private addGroupedNode(type: any, nodeTobeRemoved: GenericDevice<any>[], child: any) {
    const ploralType = GenericDevice.convertTypeToGrouped(type, this.newTopologyEnumArray);
    const groupedNode: GenericDevice<any[]> = new GenericDevice<any[]>(
      uuid(),
      ploralType,
      this.editStringsService.convertUppercasetoCamelCaseWord(GenericDevice.InitlitzeName(ploralType)),
      nodeTobeRemoved
    );
    groupedNode.parent_id = child.id;
    return groupedNode;
  }

  /**
   * @method addGroupedLink Return new link that connect the parent node and all the generic devices of the same type
   * That are connected to him
   * @param groupedNode The new created node that represent all the grouped node
   * @param child The parent of all the nodes
   */
  private addNewLink(childNode: GenericDevice<any>, parentNode: any): SingleLink {
    const newLink: SingleLink = {
      id: uuid(),
      type: this.findLinkType(childNode),
      logicalConnections: null,
      status: SingleLinkStatus.OK,
      origin: LinkVendorStatus.VENDOR,
      description: 'Grouped Link',
      startDeviceId: parentNode.id,
      startPort: null,
      startPortDetails: null,
      endDeviceId: childNode.id,
      endPort: null,
      endPortDetails: null,
      isOutsideBasicDataSource: true
    };
    return newLink;
  }

  private findLinkType(node: GenericDevice<any>) {
    return node.type === ConnectedClientType.LAPTOP || node.type === ConnectedClientType.MultiLAPTOP ?
      SingleLinkType.Wired : SingleLinkType.Wireless;
  }

  /**
   * @method replaceMultiInSingle Removes groups of nodes (conditions by product demands), and add grouped nodes and link instead
   * @param nodeTobeRemoved The nodes that should be removed
   * @param genericDevicesWithParents The generic array with the parents nodes
   */
  private removeGroupedNodeAndParents(genericDevicesWithParents: Topology<any, any>, childrenTobeRemoved: GenericDevice<any>[] = []) {
    const groupedGenericTopology = {...genericDevicesWithParents};
    const parentsToBeRmoved = [];
    let totalToBeRemoved = [];
    const nodeIndexToRemoved = [];
    let linkIndexToRemoved = [];
    /**
     * Add to the nodesTobeRemoved  array all the nodes that are not part of the original topology type (SingleDeviceType)
     */
    groupedGenericTopology.nodes.forEach(node => {
      if (Object.values(SingleDeviceType).includes(node.type)) {
        parentsToBeRmoved.push(node);
      }
    });

    totalToBeRemoved = childrenTobeRemoved.concat(parentsToBeRmoved);
    /**
     * Add all the totalToBeRemoved nodes to the nodeIndexToRemoved array
     */
    totalToBeRemoved.forEach(removedNode => {
      groupedGenericTopology.nodes.forEach((node, index) => {
        if (node.id == removedNode.id) {
          nodeIndexToRemoved.push(index);
        }
      });
    });

    childrenTobeRemoved.forEach(removedNode => {
      /**
       * Add only the links that are connected to the grouped nodes (and to the parents) to the linkIndexToRemoved array
       */
      groupedGenericTopology.links.forEach((link, index) => {
        if (link.startDeviceId == removedNode.id || link.endDeviceId == removedNode.id) {
          if (!linkIndexToRemoved.includes(index)) {
            linkIndexToRemoved.push(index);
          }
        }
      });
    });

    /**
     * Remove all nodes that connected the grouped node to their parent
     */
    if (nodeIndexToRemoved[0]) {
      nodeIndexToRemoved.sort((a, b) => a > b ? 1 : -1);
      let subtractValue = 0;
      nodeIndexToRemoved.forEach(index => {
        groupedGenericTopology.nodes.splice(index - subtractValue, 1);
        subtractValue++;
      });
    }
    /**
     * Remove all links that connected the grouped node to their parent
     */
    if (linkIndexToRemoved[0]) {
      linkIndexToRemoved = linkIndexToRemoved.sort((a, b) => a > b ? 1 : -1);
      let subtractValue = 0;
      linkIndexToRemoved.forEach(index => {
        groupedGenericTopology.links.splice(index - subtractValue, 1);
        subtractValue++;
      });
    }
    return groupedGenericTopology;
  }

  saveNewTopologyData(topology: Topology<GenericDevice<SingleDevice>, SingleLink>) {
    this.notifyNewTopology.next(topology);
  }

  deleteNewTopologyData() {
    this.notifyNewTopology.next(null);
  }

  /**
   * Return the topology without the new nodes and links, i.e., the one from the clients
   * @param d3Topologydata The original single device Topology
   * @param treeData The current configuration data object
   */
  removeNewTopologyFromOriginalTopology(d3Topologydata: Topology<GenericDevice<any>, any>) {
    let cleanD3Topologydata: Topology<GenericDevice<any>, any>;
    cleanD3Topologydata = {
      nodes: d3Topologydata.nodes.filter(node => !isGenericIsClient(node) && !isGenericIsMultiClient(node)),
      links: d3Topologydata.links.filter(link => !(link as BasicLink).isOutsideBasicDataSource),
    }
    return cleanD3Topologydata;
  }

}
