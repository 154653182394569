import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ERRORS_NAME} from "./form-model";

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageGeneratorService {
  defaultErrors:{[key in ERRORS_NAME]: (error) => any};

  constructor(private translate: TranslateService) {
    this.defaultErrors = {
      [ERRORS_NAME.REQUIRED]: (error) => this.translate.instant('data.VALIDATORS.ERRORS.FIELD_REQUIRED'),
      [ERRORS_NAME.TO_SHORT]: (error) => this.translate.instant('data.VALIDATORS.ERRORS.TO_SHORT_GENERAL'),
      [ERRORS_NAME.EMAIL]: (error) => this.translate.instant('data.VALIDATORS.ERRORS.EMAIL_NOT_VALID'),
      [ERRORS_NAME.FORBIDDEN_CHARS]: (error) => this.translate.instant('data.VALIDATORS.ERRORS.ALPHA_NUMERIC_WITH_SPECIAL_CHARS'),
      //[ERRORS_NAME.FORBIDDEN_CHARS]: (error) => this.translate.instant('data.VALIDATORS.ERRORS.ALPHA_NUMERIC_WITH_SPECIAL_CHARS'),
      [ERRORS_NAME.FORBIDDEN_CHARS]: ({actualValue}) => `${actualValue} ${this.translate.instant('data.VALIDATORS.ERRORS.WRONG_PATTERN')}`,
      [ERRORS_NAME.WRONG_PHONE_NUMBER]: (error) => this.translate.instant('data.VALIDATORS.ERRORS.PHONE_NUMBER'),
      [ERRORS_NAME.ONLY_NUMBER]: (error) => this.translate.instant('data.VALIDATORS.ERRORS.ONLY_NUMBERS'),
      [ERRORS_NAME.ONLY_STRING]: (error) => this.translate.instant('data.VALIDATORS.ERRORS.ONLY_LETTERS'),
      [ERRORS_NAME.MIN_LENGTH]: ({ requiredLength, actualLength }) => this.translate.instant('data.VALIDATORS.ERRORS.MIN_LENGTH', {requiredLength: requiredLength}),
      [ERRORS_NAME.MAX_LENGTH]: ({ requiredLength, actualLength }) => this.translate.instant('data.VALIDATORS.ERRORS.MAX_LENGTH', {requiredLength: requiredLength}),
      [ERRORS_NAME.NOT_IMAGE_TYPE]: (error) => this.translate.instant('data.VALIDATORS.ERRORS.ONLY_IMAGE'),
      [ERRORS_NAME.IPV4]: (error) => this.translate.instant('data.VALIDATORS.ERRORS.MUST_BE_IPV4'),
      [ERRORS_NAME.MAC_ADDRESS]: (error) => this.translate.instant('data.VALIDATORS.ERRORS.DEVICE_ADDRESS_NOT_VALID'),
      [ERRORS_NAME.CIDR]: (error) => this.translate.instant('data.VALIDATORS.ERRORS.SUBNET_NOT_VALID'),
      [ERRORS_NAME.PORT]: (error) => `${this.translate.instant('data.VALIDATORS.ERRORS.MUST_BE_PORT_PATTERN')}`,
      [ERRORS_NAME.PATTERN]: ({ requiredPattern, actualValue }) => `${actualValue} ${this.translate.instant('data.VALIDATORS.ERRORS.WRONG_PATTERN')}`,
      [ERRORS_NAME.COMMA_SEPARATED_URLS]: ({ requiredPattern, actualValue }) => `${this.translate.instant('data.VALIDATORS.ERRORS.COMMA_SEPARATED_URLS')}`,
    }
  }

}
