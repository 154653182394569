import {Component, OnInit, Inject} from '@angular/core';
import {ScheduleBackupService} from '../../services/rest-services/schedule-backup.service';
import {ScheduleModel, SchedulingState} from "../../models/journal.model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-tenant-periodic-backup',
  templateUrl: './tenant-periodic-backup.component.html',
  styleUrls: ['./tenant-periodic-backup.component.scss']
})
export class TenantPeriodicBackupComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: SchedulingState, private dialogRef: MatDialogRef<TenantPeriodicBackupComponent>,
              private scheduleBackupService: ScheduleBackupService) {
  }

  ngOnInit() {
  }

  submitSchedule(schedule: ScheduleModel) {
    if (schedule.scheduleEnabled !== this.dialogData)
      this.scheduleBackupService.changeBackupState(schedule.scheduleEnabled).subscribe(() => {
      });
    if (schedule.cron)
      this.scheduleBackupService.postScheduleExpression(schedule.cron).subscribe(() => {
        this.dialogRef.close();
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
