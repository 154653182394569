import {Component, OnInit, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import {ActiveElement, Chart, ChartEvent} from 'chart.js';
import {StackedBarConfig, StackedBarThemeConfig} from '../models/stacked-bar.config';
import {LoggerService, Logger} from '../../../services/logger.service';
import {StackedBarTooltip} from '../chartjs-tooltips/stacked-bar-sooltip';
import {onClickPlugin} from '../plugins/stacked-bar.plugins';
import 'chartjs-adapter-moment';
import {da} from "cronstrue/dist/i18n/locales/da";
import {NgChanges} from "../../../extend-angular-classes/on-changes";


@Component({
    selector: 'app-stacked-bar',
    templateUrl: './stacked-bar.component.html',
    styleUrls: ['./stacked-bar.component.scss']
})
export class StackedBarComponent implements OnInit {
    @Input() stackedBarConfig: StackedBarConfig;
    @Input() height: number;
    @Input() width: number;
    /**
     * Emiter for the selected dataset
     */
    @Output() clickedDataset: EventEmitter<any> = new EventEmitter();
    @ViewChild('stackedBar', {static: true}) stackedBarElm: any;
    /**
     * The Chart object
     */
    stackedBar: any;
    /**
     * The donut tooltip instance that will be used for genereting a custom tooltip
     */
    stackedBarTooltip: StackedBarTooltip = new StackedBarTooltip();
    private logger: Logger;

    constructor(private loggerFactory: LoggerService) {
        this.logger = this.loggerFactory.getLogger("StackedBarComponent");
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.logger.debug("stackedBarConfig", this.stackedBarConfig);
        if (this.stackedBarConfig && this.height && this.width && this.stackedBarElm) {
            this.drawGraph();
        }
    }

    drawGraph() {
        if (this.stackedBar)
            this.stackedBar.destroy();

        let data = this.stackedBarConfig;
        data.labels = data.datasets.map(dataset => dataset.label);
        if (data.datasets && data.datasets.length > 0) {
            this.logger.debug("Final data", data);
            const thisClass = this;
            this.stackedBar = new Chart(this.stackedBarElm.nativeElement, {
                type: 'bar',
                data: data,
                options: {
                    indexAxis: 'y',
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            mode: 'point',
                            enabled: false,
                            position: 'nearest',
                            external: this.stackedBarTooltip.customStackedTooltips,
                            callbacks: {
                                /** Fill the squers color (AKA: labelColor) with the same color of the border*/
                                labelColor: (context) =>
                                    this.stackedBarTooltip.fillLabelColors(context, "defaultColor"),
                                /** Set the tooltip title
                                 * Add percentage for each dataset
                                 */
                                title: function (context) {
                                    return data.datasets[context[0].datasetIndex].label + " (" + data.datasets[0].percentage.toFixed(2) + "%" + ")";
                                },
                                /** Set the tooltip content */
                                label: function (context) {
                                    return context.dataset.data[context.dataIndex].toString();
                                }
                            }
                        },
                    },
                    responsive: thisClass.stackedBarConfig.responsive,
                    maintainAspectRatio: true,
                    aspectRatio: 1,
                    hover: {
                        mode: 'point',
                        intersect: false,
                        //onHover: function (event: MouseEvent, element) { }
                    },
                    /**
                     * Handle the click event:
                     * 1. Change The current dataset element color
                     * 2. Emit the current dataset
                     * 3. Update The chart
                     * @param event Current mouse click event
                     * @param element The element that was clicked
                     */
                    onClick: function (event: ChartEvent, elements: ActiveElement[], chart: Chart<any>) {
                        onClickPlugin(event, elements, this, thisClass);
                    },
                    scales: {
                        x: {
                            max: thisClass.stackedBarConfig.total,
                            display: false,
                            stacked: true,
                            ticks: {
                                maxTicksLimit: 1,
                                maxRotation: 0
                            },
                            beginAtZero: true,
                        },
                        y: {
                            display: false,
                            stacked: true,
                            beginAtZero: true
                        }
                    },
                }
            })
            this.stackedBarTooltip.stackedBar = this.stackedBar;
            this.logger.debug('stackedBar', this.stackedBar);
        }
    }
}
