import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Rect } from './rect.class';
import { IAppState } from 'src/app/store/state/app.state';
import { Store, select } from '@ngrx/store';
import { selectNavigationState } from 'src/app/store/selectors/navigation.selector';
import ResizeObserver from 'resize-observer-polyfill';

/**
 * Local to portal service that should be injected into portals
 * Used by the portals to query for portal size e.g. for full screen functionality.
 */
@Injectable({
  providedIn: 'root'
})
export class PortalSizeService {
  private el: HTMLElement;
  private portalResizeSubject: Subject<any> = new Subject();
  portalResize$: Observable<any> = this.portalResizeSubject.asObservable();
  private resizeObserver: ResizeObserver;
  constructor() {
  }
  getPortalSize(): Rect {
    let rect = this.el.getBoundingClientRect();
    if (!rect) {
      return null;
    }
    return new Rect(rect.left, rect.top, rect.width, rect.height)
  }
  setPortalElement(el: HTMLElement): void {
    this.el = el;
    this.resizeObserver = new ResizeObserver((entries, observer) => {
      this.portalResizeSubject.next();
    });
    this.resizeObserver.observe(this.el);

  }
  disconnect() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }
}
