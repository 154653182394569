import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {ActionStatus} from 'src/app/shared/models/actions.model';
import {MatDialog} from "@angular/material/dialog";
import {BaseAnalyzeCellRenderer} from "../analyze-pop-over/base-analyze-cell-renderer";
import {AnomalyCategory} from "../../../models/anomalies.model";
import {EntityType} from "../../../models/entity-type.enum";

@Component({
  selector: 'app-analyze-cell-renderer',
  templateUrl: './analyze-cell-renderer.component.html',
  styleUrls: ['./analyze-cell-renderer.component.scss']
})
export class AnalyzeCellRendererComponent extends BaseAnalyzeCellRenderer implements ICellRendererAngularComp {
  nextPossibleSteps: Array<ActionStatus>;
  entityType: EntityType;
  entityId: number;
  actionId: number;

  constructor(public dialog: MatDialog) {
    super(dialog)
  }

  agInit(params: ICellRendererParams): void {
    if (params && params.data && (params.data.hasOwnProperty('action') || params.data.hasOwnProperty('name'))) {
      this.action = params.data.hasOwnProperty('action') ? params.data.action : params.data;
      this.params = params;
      if (this.params) {
        this.entityType = (this.params as any).entityType;
        if ((this.params as any).entityId && typeof (this.params as any).entityId == 'function') {
          this.entityId = (this.params as any).entityId(this.params.data);
        }
        if ((this.params as any).actionId && typeof (this.params as any).actionId == 'function') {
          this.actionId = (this.params as any).actionId(this.params.data);
        }
      }
      this.nextPossibleSteps = [];
      if (this.action && this.action.nextPossibleStates && this.action.nextPossibleStates.length > 0) {
        if (this.action.anomalyCategory !== AnomalyCategory.FabricAnomaly) {
          this.nextPossibleSteps = [].concat(this.action.nextPossibleStates);
        } else {
          this.nextPossibleSteps = this.action.nextPossibleStates;
        }
      }
    }
  }

  onAction(nextAction: ActionStatus) {
    if (nextAction == ActionStatus.Analyze) {
      this.openDialog();
      return;
    }
    if (!this.params.context ||
      !this.params.context.componentParent ||
      !this.params.context.componentParent.onActionPerformed) {
    }
    this.params.context?.componentParent?.onActionPerformed(this.actionId ?? this.params.data?.id, nextAction, this.entityType, this.entityId);
  }

  afterGuiAttached?(params?: import("ag-grid-community").IAfterGuiAttachedParams): void {
  }

  refresh(params: any): boolean {
    return;
  }
}
