import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FromToDates, TimePeriodOption, TimePeriodPayload, TimePeriodType} from "../../../models/time.model";
import {DEFAULT_TIME_PERIOD_OPTIONS} from "../time-range-picker.model";
import {TimeUtils} from "../../../global-utils/time-utils";
import {NgChanges} from "../../../extend-angular-classes/on-changes";

@Component({
  selector: 'app-time-period-picker',
  templateUrl: './time-period-picker.component.html',
  styleUrls: ['./time-period-picker.component.scss']
})
export class TimePeriodPickerComponent implements OnInit, OnChanges {
  @Input() options: TimePeriodOption[] = DEFAULT_TIME_PERIOD_OPTIONS;
  @Input() date: Date = new Date();
  @Input() currentSelectionIndex: number = 0;
  @Input() periodType: TimePeriodType = TimePeriodType.TimeBack;
  @Input() numIntervals: number = 48;
  @Input() selectPrefix: string;
  @Output() initialized = new EventEmitter<TimePeriodPayload>();
  @Output() optionSelected = new EventEmitter<TimePeriodPayload>();

  @Input() refresh: boolean = false;
  @Output() refreshChange = new EventEmitter<boolean>();

  currentPayload: TimePeriodPayload;

  constructor() {
  }

  ngOnInit(): void {
    this.setCurrent();
    this.initialized.emit(this.currentPayload);
  }

  ngOnChanges(changes: NgChanges<TimePeriodPickerComponent>) {
    if ((changes.refresh && this.refresh) || changes.currentSelectionIndex) {
      this.setCurrent();
      this.optionSelected.emit(this.currentPayload);
      this.refresh = false;
      this.refreshChange.emit(false);
    }
    else {
      this.refresh = false;
    }
  }

  onTimePeriodSelected() {
    this.setCurrent();
    this.optionSelected.emit(this.currentPayload);
  }

  createPayload(): TimePeriodPayload {
    const convertedStep = this.periodType == TimePeriodType.TimeBack
      ? TimeUtils.toGranularity(this.currentSelection.interval, this.currentSelection.timeUnit, this.numIntervals)
      : TimeUtils.toTimeBack(this.currentSelection.interval, this.currentSelection.timeUnit, this.numIntervals);

    return {
      timeBack: this.periodType == TimePeriodType.TimeBack
        ? this.currentSelection.interval
        : convertedStep.interval,
      timeBackUnit: this.periodType == TimePeriodType.TimeBack
        ? this.currentSelection.timeUnit
        : convertedStep.timeUnit,
      granularity: this.periodType == TimePeriodType.TimeBack
        ? convertedStep.interval
        : this.currentSelection.interval,
      granularityUnit: this.periodType == TimePeriodType.TimeBack
        ? convertedStep.timeUnit
        : this.currentSelection.timeUnit,
      option: this.currentSelection,
      dates: this.currentDates
    };
  }

  setCurrent() {
    this.currentPayload = this.createPayload();
  }

  get currentSelection(): TimePeriodOption {
    return this.options[this.currentSelectionIndex];
  }

  get currentDates(): FromToDates {
    if (this.currentSelection && this.date) {
      return TimeUtils.getDates(this.currentSelection.interval, this.currentSelection.timeUnit, this.date);
    }
  }
}
